const usersByOrgs = (list) => {
  const shapedListMap = new Map();
  list.forEach((element) => {
    if (!shapedListMap.get(element.organization_id)) {
      shapedListMap.set(element.organization_id, [{
        organization_name: element.organization_name,
        organization_guid: element.organization_guid,
        organization_id: element.organization_id,
        id: element.id,
        first_name: element.first_name,
        last_name: element.last_name,
        thumbnail: element.thumbnail
      }]);
    } else {
      shapedListMap.get(element.organization_id).push({
        organization_name: element.organization_name,
        organization_guid: element.organization_guid,
        organization_id: element.organization_id,
        id: element.id,
        first_name: element.first_name,
        last_name: element.last_name,
        thumbnail: element.thumbnail
      });
    }
  });

  return shapedListMap;
};

export default usersByOrgs;
