import React from 'react';

const PrintIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <clipPath id="b"><rect width="24" height="24" /></clipPath>
    </defs>
    <g id="a" clipPath="url(#b)">
      <rect width="24" height="24" fill="#fff" />
      <g transform="translate(-1112 -288)">
        <rect width="24" height="24" transform="translate(1112 288)" fill="none" />
        <g transform="translate(0 1)">
          <g transform="translate(-20658 4483)" fill="none">
            <path d="M21778-4179h-3a1,1,0,0,1-1-1v-6a1,1,0,0,1,1-1h14a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1h-3v-3h-8v3Z" stroke="none" />
            <path d="M 21777.001953125 -4179.9990234375 L 21777.001953125 -4182.001953125 L 21777.001953125 -4183.001953125 L 21778.001953125 -4183.001953125 L 21785.998046875 -4183.001953125 L 21786.998046875 -4183.001953125 L 21786.998046875 -4182.001953125 L 21786.998046875 -4180 L 21789 -4180.00048828125 L 21789 -4186 L 21775 -4185.99951171875 L 21775 -4179.99951171875 L 21777.001953125 -4179.9990234375 M 21778.001953125 -4178.99853515625 L 21778 -4178.99853515625 L 21775 -4178.99951171875 C 21774.44921875 -4178.99951171875 21774 -4179.44873046875 21774 -4180.00048828125 L 21774 -4185.99951171875 C 21774 -4186.55126953125 21774.44921875 -4187 21775 -4187 L 21789 -4187 C 21789.55078125 -4187 21790 -4186.55126953125 21790 -4185.99951171875 L 21790 -4180.00048828125 C 21790 -4179.44873046875 21789.55078125 -4178.99951171875 21789 -4178.99951171875 L 21785.998046875 -4178.99951171875 L 21785.998046875 -4182.001953125 L 21778.001953125 -4182.001953125 L 21778.001953125 -4178.99951171875 L 21778.001953125 -4178.99853515625 Z" stroke="none" fill="gray" />
          </g>
          <g transform="translate(1120 293)" fill="none" stroke="gray" strokeWidth="1">
            <rect width="8" height="4" stroke="none" />
            <rect x="0.5" y="0.5" width="7" height="3" fill="none" />
          </g>
          <g transform="translate(1119 300)" fill="none" stroke="gray" strokeWidth="1">
            <rect width="10" height="6" stroke="none" />
            <rect x="0.5" y="0.5" width="9" height="5" fill="none" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PrintIcon;
