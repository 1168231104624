import { Event, EventLogger } from './types';

import { getCsrfToken } from '@smartsheet/api.utils';

export class BiEventLogger implements EventLogger {
  private static INSTANCES: { [endpoint: string]: EventLogger } = {};
  private readonly SEND_INTERVAL_MS: number = 5000; // push events every 5s
  private endpoint: string;
  private events: [Event?];
  private scheduleId!: number;

  static getInstance(endpoint?: string): EventLogger {
    const url = endpoint || `${window.APP_ENDPOINT}/user-events`;
    if (!BiEventLogger.INSTANCES[url]) {
      BiEventLogger.INSTANCES[url] = new BiEventLogger(url);
    }
    return BiEventLogger.INSTANCES[url];
  }

  private constructor(endpoint: string) {
    this.endpoint = endpoint;
    this.events = [];
    this.setup();
  }

  private setup(): void {
    this.scheduleId = window.setInterval(() => {
      void this.send();
    }, this.SEND_INTERVAL_MS);

    addEventListener(
      'beforeunload',
      () => {
        void this.send();
        window.clearInterval(this.scheduleId);
      },
      { once: true }
    );
  }

  push(event: Event): void {
    const eventWithOwner = {
      eventOwnerId: Number(window.whoami.id),
      eventOwnerType: 'user',
      ...event,
    };
    this.events.push(eventWithOwner);
  }

  async send(): Promise<Response | void> {
    if (this.events.length) {
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      };
      if (window.IS_EMBEDDED_SCHEDULE || window.isIframeView) {
        headers['x-smar-xsrf'] = window.clientState?.sessionKey as string;
      }
      return fetch(this.endpoint, {
        method: 'POST',
        body: JSON.stringify({
          events: this.events.splice(0, this.events.length),
        }),
        headers: headers,
      });
    }
    return Promise.resolve();
  }
}
