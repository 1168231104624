import React from 'react';
import style from './style.scss';

export default () => (
  <svg className={style.icon}>
    <rect x="2" y="2" width="12" height="1" />
    <rect x="2" y="3" width="1" height="3" />
    <rect x="13" y="3" width="1" height="3" />
    <rect x="2" y="5" width="12" height="1" />
    <rect x="3" y="6" width="1" height="8" />
    <rect x="12" y="6" width="1" height="8" />
    <rect x="3" y="13" width="10" height="1" />
  </svg>
);
