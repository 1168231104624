import React from 'react';


const style = { fill: 'gray' };

export const PhaseIcon = () => (
  <svg viewBox="0 0 24 24">
    <polygon points="10 6 6 6 9 12 6 18 10 18 13 12 10 6" />
    <polygon points="14.67 8 12 8 14 12 12 16 14.67 16 16.67 12 14.67 8" />
    <polygon points="18.67 8 16 8 18 12 16 16 18.67 16 20.67 12 18.67 8" />
    <polygon points="5.67 8 3 8 5 12 3 16 5.67 16 7.67 12 5.67 8" style={style} />
  </svg>
);

export default PhaseIcon;
