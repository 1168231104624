import React from 'react';
import PropTypes from 'prop-types';

const ApproverViewIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={iconStyle}>
    <g>
      <g>
        <polygon points="6.75 11.99 3.43 8.77 4.82 7.33 6.72 9.18 11.17 4.71 12.58 6.12 6.75 11.99" />
        <path d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.5A6.5,6.5,0,1,1,14.5,8,6.51,6.51,0,0,1,8,14.5Z" />
      </g>
    </g>
  </svg>
);

ApproverViewIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default ApproverViewIcon;
