import React from 'react';

const DeleteIcon = () => (
  <svg viewBox="0 0 24 24">
    <path d="M16,17H9V10h7Zm-6-1h5V11H10Z" />
    <path d="M17,11H8V8h9ZM9,10h7V9H9Z" />
    <rect x="13" y="11" width="1" height="5" />
    <rect x="11" y="11" width="1" height="5" />
    <rect x="11" y="7" width="3" height="1" />
  </svg>
);

export default DeleteIcon;
