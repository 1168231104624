import React from 'react';
import PropTypes from 'prop-types';

const ShortcutIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <polygon points="17 17 7 17 7 7 9.5 7 9.5 8 8 8 8 16 16 16 16 14.5 17 14.5 17 17" />
    <polygon points="17 7 12 7 13.79 8.79 10.17 12.41 11.59 13.83 15.21 10.21 17 12 17 7" />
  </svg>
);

ShortcutIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default ShortcutIcon;
