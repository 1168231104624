import React from 'react';

export const AddLargeIcon = () => (
  <svg viewBox="0 0 24 24">
    <rect x="10" y="5" width="4" height="14" />
    <rect x="10" y="5" width="4" height="14" transform="translate(24) rotate(90)" />
  </svg>
);

export default AddLargeIcon;
