import React from 'react';
// import PropTypes from 'prop-types';

const style = { fill: 'gray' };

const BackIcon = () => (
  <svg height="14px" width="14px" viewBox="0 0 14 14">
    <path
      style={style}
      d="M10,14a1,1,0,0,1-.71-.29l-6-6a1,1,0,0,1,0-1.42l6-6a1,1,0,1,1,1.42,1.42L5.41,7l5.3,5.29a1,1,0,0,1,0,1.42A1,1,0,0,1,10,14Z" // eslint-disable-line max-len
    />
  </svg>
);

// BackIcon.propTypes = {};

export default BackIcon;
