{
  "err_activating_sso_auth": "SSO 認証のアクティブ化エラー",
  "err_company_name_length": "組織/団体名は 255 文字未満でなければなりません。",
  "err_duplicate_value": "その値は既に存在しています",
  "err_email_length": "電子メール アドレスは 255 文字未満でなければなりません。",
  "err_failed_retrieve_initial_state_from_local_storage": "localStorage から初期状態を取得できませんでした",
  "err_fetching_filters": "これらのフィルター オプションの取得時に問題が発生しました。",
  "err_first_name_contains_url": "名に URL を含めることはできません。",
  "err_first_name_length": "名は 255 文字未満でなければなりません。",
  "err_global_modules_invalid_input": "無効な入力です。  モジュール名が定義済みであり、少なくとも 1 つのアクションがチェックされていることを確認してください。",
  "err_invalid_email": "有効な電子メール アドレスを入力してください",
  "err_last_name_contains_url": "姓に URL を含めることはできません。",
  "err_last_name_length": "姓は 255 文字未満でなければなりません。",
  "err_no_status_for_status_panel": "チームがステータス オプションを定義していません。ステータスの使用を開始したい場合は、管理者が [アカウント設定] でステータス オプションを追加できます。",
  "err_password_length_40_chars": "8 ～ 40 文字の範囲で設定してください。",
  "err_required": "必須",
  "err_unable_to_load_filter_list": "フィルター リストを読み込めません",
  "err_unknown_error": "不明なエラー",
  "err_updating_smartsheet_auth": "Smartsheet 認証の更新エラー",
  "err_whoops_something_did_not_work_as_expected": "申し訳ありません。問題が発生しました。",
  "lbl_about_fifteen_seconds": "約 15 秒",
  "lbl_about_thirty_seconds": "約 30 秒",
  "lbl_accept": "承諾",
  "lbl_account_switcher": "アカウント切り替えボタン",
  "lbl_activate": "アクティブ化",
  "lbl_activated_auth_status": "アクティブ化済み (電子メールのみで作成されたアカウント)",
  "lbl_active": "アクティブ",
  "lbl_activity": "アクティビティ",
  "lbl_actual_hours": "実際の時間",
  "lbl_add": "追加",
  "lbl_add_group_label_filter": "{{groupLabel}} フィルターの追加",
  "lbl_assign_to_search_user": "'{{searchInput}}' に割り当て",
  "lbl_add_lc_members": "メンバーの追加",
  "lbl_add_members": "メンバーの追加",
  "lbl_add_person": "ユーザーを追加する",
  "lbl_add_tasklist": "タスクリストを追加する",
  "lbl_add_team_member": "チーム メンバーを追加する",
  "lbl_add_update_people": "ユーザーを追加/更新する",
  "lbl_add_work_item": "作業アイテムを追加する",
  "lbl_added": "追加",
  "lbl_all": "すべて",
  "lbl_all_project_types": "すべてのプロジェクト タイプ",
  "lbl_allocation_heatmap": "割り当てのヒートマップ",
  "lbl_allocation_percent": "割り当て率",
  "lbl_amounts": "金額",
  "lbl_amounts_and_days": "金額 & 日数",
  "lbl_amounts_and_days_filter_display_name": "時間と料金: 日数と金額",
  "lbl_amounts_and_hours": "金額 & 時間",
  "lbl_apply_changes": "変更を適用",
  "lbl_approval_access_id": "承認アクセス ID",
  "lbl_approval_status": "承認ステータス",
  "lbl_approvals": "承認",
  "lbl_approvals_card_header_project_phase_client": "プロジェクト: フェーズ - クライアント",
  "lbl_approve_and_lock": "承認してロックする",
  "lbl_approved": "承認済み",
  "lbl_approved_and_locked": "承認/ロック済み",
  "lbl_approved_by": "承認者",
  "lbl_approver": "承認者",
  "lbl_approver_view": "承認者ビュー",
  "lbl_approvers": "承認者",
  "lbl_april": "4 月",
  "lbl_archive": "アーカイブ",
  "lbl_archive_projects": "プロジェクトをアーカイブする",
  "lbl_archived": "アーカイブ済み",
  "lbl_archived_label": "アーカイブ済みラベル",
  "lbl_archived_on_date": "{{archiveDate}} にアーカイブ済み",
  "lbl_archiving": "アーカイブ",
  "lbl_assign": "割り当てる",
  "lbl_assign_group_label": "{{groupLabel}} の割り当て",
  "lbl_assign_team_member": "チーム メンバーを割り当てる",
  "lbl_assign_to": "割り当て先",
  "lbl_assignment": "割り当て",
  "lbl_assignment_dates_colon": "割り当ての日付:",
  "lbl_assignment_details": "割り当ての詳細",
  "lbl_assignment_name": "割り当て名",
  "lbl_assignment_status": "割り当てのステータス",
  "lbl_audit_log": "監査ログ",
  "lbl_august": "8 月",
  "lbl_authentication": "認証",
  "lbl_author": "作成者",
  "lbl_automatic": "自動",
  "lbl_automatic_capitalize": "自動",
  "lbl_availability": "可用性",
  "lbl_availability_sort_options": "可用性の並べ替えオプション",
  "lbl_available_hours": "作業可能時間",
  "lbl_back": "戻る",
  "lbl_back_to_account_settings": "アカウント設定に戻る",
  "lbl_back_to_list_of_requests": "リクエストのリストに戻る",
  "lbl_based_on": "基準",
  "lbl_bill_rate": "請求レート",
  "lbl_billable": "請求可能",
  "lbl_bracket_lc_none": "[なし]",
  "lbl_bracket_leave": "[終了]",
  "lbl_bracket_no_approver_assigned": "[承認者が割り当てられていない]",
  "lbl_bracket_non_phase_specific": "[フェーズ詳細なし]",
  "lbl_bracket_none": "[なし]",
  "lbl_brief": "概要",
  "lbl_budget": "予算",
  "lbl_budget_amounts_filter_display_name": "予算: 金額",
  "lbl_budget_days_filter_display_name": "予算: 日数",
  "lbl_budget_hours_filter_display_name": "予算: 時間",
  "lbl_budget_report": "予算レポート",
  "lbl_budgets": "予算",
  "lbl_calculating": "計算",
  "lbl_cancel": "キャンセル",
  "lbl_cancelled": "キャンセル済み",
  "lbl_capacity": "キャパシティ",
  "lbl_category": "カテゴリ",
  "lbl_cell_phone": "携帯番号",
  "lbl_certificate_details": "証明書の詳細",
  "lbl_change_to_unmanaged_confirm": "このユーザーは時間と経費の追跡や承認ができなくなるため、キャパシティ ビューとレポートにも表示されなくなります。",
  "lbl_change_to_managed": "マネージドに変更する",
  "lbl_change_to_unmanaged": "アンマネージドへの変更",
  "lbl_choose_file": "ファイルを選択する",
  "lbl_client": "クライアント",
  "lbl_client_name": "クライアント名",
  "lbl_close": "閉じる",
  "lbl_company_name": "組織/団体名",
  "lbl_complete": "完了",
  "lbl_confirm": "確定",
  "lbl_confirmed": "確認済み",
  "lbl_contact_info": "連絡先情報",
  "lbl_contact_support": "サポートへの連絡",
  "lbl_contractor": "請負業者",
  "lbl_copy": "コピー",
  "lbl_count_of_total": "{{total}} 中 {{count}} 件",
  "lbl_create": "作成",
  "lbl_create_new_request": "* 新しいリクエストを作成する",
  "lbl_current": "現在",
  "lbl_current_work": "現在の作業",
  "lbl_custom_date_range": "カスタム日付範囲",
  "lbl_custom_field": "カスタム フィールド",
  "lbl_date": "日付",
  "lbl_date_string_at_time_string": "{{date}} {{time}}",
  "lbl_date_string": "日付文字列",
  "lbl_dates": "日付",
  "lbl_dates_for_submitted_time": "送信時間の日付",
  "lbl_day_friday": "金曜日",
  "lbl_day_monday": "月曜日",
  "lbl_day_saturday": "土曜日",
  "lbl_day_sunday": "日曜日",
  "lbl_day_thursday": "木曜日",
  "lbl_day_tuesday": "火曜日",
  "lbl_day_wednesday": "水曜日",
  "lbl_days": "日",
  "lbl_deactivated": "非アクティブ化された",
  "lbl_december": "12 月",
  "lbl_delete": "削除",
  "lbl_delete_adv_report_modal": "レポートを削除しますか?",
  "lbl_delete_saved_report_modal": "保存済みレポートを削除しますか?",
  "lbl_description": "説明",
  "lbl_difference": "差異",
  "lbl_difference_from_past_scheduled": "過去のスケジュールとの差異",
  "lbl_disabled": "無効",
  "lbl_discipline": "分野",
  "lbl_display_colon": "表示:",
  "lbl_done": "終了",
  "lbl_duration": "期間",
  "lbl_duration_working_days": "期間 (稼働日)",
  "lbl_edit_profile": "プロファイルの編集",
  "lbl_edit_project_information": "プロジェクト情報を編集する",
  "lbl_edit_projects": "プロジェクトの編集",
  "lbl_email": "メール アドレス",
  "lbl_email_must_be_less_than_255_characters.": "電子メール アドレスは 255 文字未満でなければなりません。",
  "lbl_employee_number": "社員番号",
  "lbl_enabled": "有効",
  "lbl_end": "終了",
  "lbl_end_date": "終了日",
  "lbl_end_lc_date": "終了日",
  "lbl_ends_at": "終了日",
  "lbl_enter_a_name": "名前を入力",
  "lbl_enter_rejection_reason": "却下理由を入力する",
  "lbl_entry_type": "エントリ タイプ",
  "lbl_everyone": "全員",
  "lbl_everything": "すべて",
  "lbl_expense": "経費",
  "lbl_expenses": "経費",
  "lbl_export": "エクスポート",
  "lbl_export_people_list": "ユーザー リストをエクスポートする",
  "lbl_export_report_to_csv": "このレポートを CSV にエクスポートする",
  "lbl_export_time_entries_for_quickBooks": "QuickBooks 用に時間エントリをエクスポートする",
  "lbl_export_underlying_data_as_csv": "元のデータを CSV でエクスポートする",
  "lbl_failed": "失敗",
  "lbl_february": "2 月",
  "lbl_fees": "料金",
  "lbl_filter": "フィルター",
  "lbl_filtered": "フィルター済み",
  "lbl_filters": "フィルター",
  "lbl_first_day_of_work": "初稼働日",
  "lbl_first_group_by": "最初のグループ化の基準",
  "lbl_first_name": "名",
  "lbl_first_name_lc": "名",
  "lbl_friday_abbr_uc": "金",
  "lbl_future": "今後",
  "lbl_future_scheduled": "スケジュール済み",
  "lbl_global_modules": "グローバル モジュール",
  "lbl_grid_view": "グリッド ビュー",
  "lbl_hours": "時間",
  "lbl_hours_filter_display_name": "時間と料金: 時間",
  "lbl_hours_per_day": "時間/日",
  "lbl_idp_metadata_url": "IdP メタデータ URL",
  "lbl_iframe_view_content_require": "このコンテンツを表示するには、Resource Management by Smartsheet ライセンスが必要です。",
  "lbl_in_the_office": "社内",
  "lbl_incurred": "発生した",
  "lbl_internal": "内部",
  "lbl_invited": "招待済み",
  "lbl_january": "1 月",
  "lbl_july": "7 月",
  "lbl_june": "6 月",
  "lbl_just_now": "たった今",
  "lbl_last_and_next_ninety_days": "過去 90 日間と今後 90 日間",
  "lbl_last_day_of_work": "最終稼働日",
  "lbl_last_month": "先月",
  "lbl_last_name": "姓",
  "lbl_last_name_lc": "姓",
  "lbl_last_quarter": "前四半期",
  "lbl_last_thirty_days": "過去 30 日間",
  "lbl_last_week": "先週",
  "lbl_learn_lc_more": "詳細情報",
  "lbl_learn_more": "詳細情報",
  "lbl_leave": "終了",
  "lbl_leave_type": "不在タイプ",
  "lbl_licensed": "ライセンスあり",
  "lbl_licensed_users": "ライセンス ユーザー",
  "lbl_linked_smartsheet_project": "Smartsheet のプロジェクトにリンク済み",
  "lbl_loading": "読み込み中...",
  "lbl_loading_ellipsis": "読み込み中...",
  "lbl_loading_project_ellipsis": "プロジェクトを読み込み中...",
  "lbl_location": "位置情報",
  "lbl_log_requested": "リクエストされたログ",
  "lbl_make_active": "アクティブにする",
  "lbl_make_licensed_user": "ライセンス ユーザーにする",
  "lbl_manage_members": "メンバーの管理",
  "lbl_managed_resource": "マネージド リソース",
  "lbl_manual": "手動",
  "lbl_manual_capitalize": "手動",
  "lbl_march": "3 月",
  "lbl_matching_people": "一致するユーザー",
  "lbl_matching_person": "一致するユーザー",
  "lbl_matching_placeholders": "一致するプレースホルダー",
  "lbl_matching_project": "一致するプロジェクト",
  "lbl_matching_projects": "一致するプロジェクト",
  "lbl_may": "5 月",
  "lbl_medium": "中",
  "lbl_members": "メンバー",
  "lbl_mobile": "モバイル",
  "lbl_module": "モジュール:",
  "lbl_module_name": "モジュール名",
  "lbl_monday_abbr_uc": "月",
  "lbl_month": "月",
  "lbl_more_assignments": "その他の割り当て",
  "lbl_must_be_between_8–40_characters.": "8 ～ 40 文字の範囲で設定してください。",
  "lbl_my_expenses": "経費",
  "lbl_my_projects": "マイ プロジェクト",
  "lbl_my_time": "時間",
  "lbl_my_time_and_expenses": "時間と経費",
  "lbl_my_views": "マイ ビュー",
  "lbl_new_project": "新しいプロジェクト",
  "lbl_next_ninety_days": "今後 90 日間",
  "lbl_next_sixty_days": "今後 60 日間",
  "lbl_next_thirty_days": "今後 30 日間",
  "lbl_next_week": "来週",
  "lbl_no_assignments": "割り当てなし",
  "lbl_no_description_provided": "説明はありません。",
  "lbl_no_imports_found": "インポートが見つかりません",
  "lbl_non_billable": "請求可能なものはありません",
  "lbl_none": "なし",
  "lbl_non_matching": "一致なし",
  "lbl_not_using_smar": "Smartsheet 認証を使用しない",
  "lbl_not_using_sso": "SSO を使用しない",
  "lbl_not_yet_invited": "まだ招待されていません",
  "lbl_notes": "備考",
  "lbl_november": "11 月",
  "lbl_october": "10 月",
  "lbl_office": "オフィス",
  "lbl_office_phone": "勤務先電話番号",
  "lbl_office_status": "オフィスのステータス",
  "lbl_on_the_road": "外勤中",
  "lbl_or_select_a_specific_phase": "または特定のフェーズを選択する",
  "lbl_or_select_phases": "またはフェーズを選択する",
  "lbl_or_select_specific_phases": "または特定のフェーズを選択する",
  "lbl_other_projects": "その他のプロジェクト",
  "lbl_out_of_office": "休暇",
  "lbl_out_sick": "病欠",
  "lbl_past": "過去",
  "lbl_pending": "保留中",
  "lbl_pending_approval": "承認の保留中",
  "lbl_people": "ユーザー",
  "lbl_people_filters": "ユーザー フィルター",
  "lbl_people_scheduler": "ユーザー スケジューラー",
  "lbl_people_tags": "ユーザー タグ",
  "lbl_percentage": "パーセント",
  "lbl_persistent_navigation": "一貫したナビゲーション",
  "lbl_person": "ユーザー",
  "lbl_phase": "フェーズ",
  "lbl_phase_dates": "フェーズの日付",
  "lbl_phase_dates_colon": "フェーズの日付:",
  "lbl_phase_four": "フェーズ 4",
  "lbl_phase_lc_name": "フェーズ名",
  "lbl_phase_name": "フェーズ名",
  "lbl_phase_name_long_enough_to_wrap": "折り返される長さのフェーズ名です",
  "lbl_phase_one": "フェーズ 1",
  "lbl_phase_status": "フェーズのステータス",
  "lbl_phase_three": "フェーズ 3",
  "lbl_phase_two": "フェーズ 2",
  "lbl_placeholder": "プレースホルダー",
  "lbl_placeholders": "プレースホルダー",
  "lbl_please_sign_in": "続行するには、サインインしてください",
  "lbl_plus_allocation": "割り当ての追加",
  "lbl_portfolio_editor": "ポートフォリオ編集者",
  "lbl_portfolio_reporter": "ポートフォリオのレポート作成者",
  "lbl_portfolio_viewer": "ポートフォリオ閲覧者",
  "lbl_post": "投稿",
  "lbl_project": "プロジェクト",
  "lbl_project_access": "プロジェクトのアクセス",
  "lbl_project_code": "プロジェクト コード",
  "lbl_project_dates": "プロジェクトの日付",
  "lbl_project_dates_colon": "プロジェクトの日付:",
  "lbl_project_description": "プロジェクトの説明",
  "lbl_project_duration": "プロジェクト期間",
  "lbl_project_editor": "プロジェクト編集者",
  "lbl_project_filters": "プロジェクトのフィルター",
  "lbl_project_members": "プロジェクトのメンバー",
  "lbl_project_name": "プロジェクト名",
  "lbl_project_owner": "プロジェクト所有者",
  "lbl_project_owners": "プロジェクト所有者",
  "lbl_project_portfolio": "プロジェクト ポートフォリオ",
  "lbl_project_properties": "プロジェクトのプロパティ",
  "lbl_project_settings": "プロジェクト設定",
  "lbl_project_state": "プロジェクトの状態",
  "lbl_project_tags": "プロジェクト タグ",
  "lbl_project_title": "プロジェクト タイトル",
  "lbl_project_type": "プロジェクト タイプ",
  "lbl_projects": "プロジェクト",
  "lbl_purchase_more": "さらに購入する",
  "lbl_recents": "最近",
  "lbl_recent_requests": "最近のリクエスト",
  "lbl_refresh_status": "更新ステータス",
  "lbl_refresh_type": "更新タイプ",
  "lbl_refreshing_data": "データを最新の情報に更新中...",
  "lbl_reject": "却下",
  "lbl_rejected": "却下済み",
  "lbl_remaining": "残り",
  "lbl_remove": "削除",
  "lbl_report_data_refresh_status_error": "データを最新の情報に更新できませんでした: {{date}} {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "詳細レポートの作成中にエラーが発生しました。もう一度やり直してください。この問題が解決しない場合は、サポートまでご連絡ください。",
  "lbl_report_data_refresh_status_loading": "詳細レポートを更新中...",
  "lbl_report_data_refresh_status_success": "最終更新日時: {{date}} {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "変更は次のデータ更新で同期されます",
  "lbl_report_data_refresh_status_warning_manual": "詳細レポートを最新の情報に更新して変更を同期する",
  "lbl_report_name_colon": "レポート名:",
  "lbl_report_title_by_criteria": "{{report}}: {{criteria}} 別",
  "lbl_reports": "レポート",
  "lbl_request_an_audit_log": "監査ログをリクエストする",
  "lbl_request_audit_log": "監査ログをリクエストする",
  "lbl_requester": "リクエスト者",
  "lbl_resend_invitation": "招待の再送信",
  "lbl_reset": "リセット",
  "lbl_reset_filters": "フィルターのリセット",
  "lbl_reset_password": "パスワードのリセット",
  "lbl_reset_password_for": "のパスワードをリセットする",
  "lbl_reset_report": "レポートのリセット",
  "lbl_resource-request_test_page": "リソース リクエストのテスト ページ",
  "lbl_resource_management_by_smartsheet": "Resource Management by Smartsheet",
  "lbl_resourcing_admin": "リソース管理者",
  "lbl_retry": "再試行",
  "lbl_role": "役割",
  "lbl_saturday_abbr_uc": "土",
  "lbl_save": "保存",
  "lbl_save_changes": "変更の保存",
  "lbl_save_current_view": "現在のビューを保存する",
  "lbl_save_this_report_to_your_personal_page": "このレポートを個人のページに保存する",
  "lbl_saving": "保存中...",
  "lbl_schedule": "スケジュール",
  "lbl_scheduled": "スケジュール済み",
  "lbl_search": "検索",
  "lbl_search_ellipsis": "検索...",
  "lbl_select_date_range_to_see_entries": "日付範囲を選択して、対象の時間枠に含まれるすべてのエントリを表示する",
  "lbl_select_people_to_add_to_project": "このプロジェクトに追加するユーザーを選択します。",
  "lbl_select_phases": "フェーズの選択",
  "lbl_select_project": "プロジェクトの選択",
  "lbl_select_project_or_phase": "プロジェクトまたはフェーズの選択",
  "lbl_select_project_owner": "プロジェクト所有者の選択",
  "lbl_select_team_member": "チーム メンバーの選択:",
  "lbl_select_the_entire_project": "プロジェクト全体を選択する",
  "lbl_send": "送信",
  "lbl_send_invitation": "招待の送信",
  "lbl_september": "9 月",
  "lbl_set_a_status": "ステータスの設定",
  "lbl_set_allocation": "割り当ての設定:",
  "lbl_set_to_today": "今日に設定する",
  "lbl_share": "共有",
  "lbl_shared_views": "共有ビュー",
  "lbl_show": "表示",
  "lbl_show_no_phases": "表示: フェーズなし",
  "lbl_sign_out_slo_target_url": "サインアウト (SLO) 対象 URL",
  "lbl_signin_sso_target_url": "サインイン (SSO) 対象 URL",
  "lbl_signup_password": "パスワード (8 文字以上)",
  "lbl_skipped": "スキップ済み",
  "lbl_skipped_colon": "スキップ済み: {{number}}",
  "lbl_small": "小",
  "lbl_something_went_wrong_ellipsis": "問題が発生しました...",
  "lbl_sort_by": "並べ替え",
  "lbl_sso_for_resource_management": "リソース管理用 SSO",
  "lbl_start": "開始",
  "lbl_start_date": "開始日",
  "lbl_start_lc_date": "開始日",
  "lbl_starts_at": "開始時間",
  "lbl_status": "ステータス",
  "lbl_status_cancelled": "キャンセル済み",
  "lbl_status_failed": "失敗",
  "lbl_status_message": "ステータス メッセージ",
  "lbl_status_queued": "キュー済み",
  "lbl_status_running": "実行中",
  "lbl_status_succeeded": "成功",
  "lbl_submitted_time_for_approval": "承認の時間を送信しました",
  "lbl_sunday_abbr_uc": "日",
  "lbl_switch_to_another_account": "別のアカウントへの切り替え:",
  "lbl_tag": "タグ",
  "lbl_tags": "タグ",
  "lbl_target_utilization": "目標稼働率",
  "lbl_tasks": "タスク",
  "lbl_team_member": "チーム メンバー",
  "lbl_team_member_type": "チーム メンバー タイプ",
  "lbl_tentative": "暫定",
  "lbl_terms": "利用規約",
  "lbl_the_selected_project_was": "選択済みのプロジェクト: ",
  "lbl_then_group_by": "次のグループ化の基準:",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "これらの結果には閲覧許可を持つプロジェクトのみが含まれます。",
  "lbl_this_month": "今月",
  "lbl_this_quarter": "今四半期",
  "lbl_this_week": "今週",
  "lbl_this_year": "今年",
  "lbl_thursday_abbr_uc": "木",
  "lbl_time": "時間",
  "lbl_time_and_expenses": "時間と経費",
  "lbl_time_and_fee_categories": "時間と料金カテゴリ",
  "lbl_time_and_fees": "時間と料金",
  "lbl_time_and_fees_report": "時間と料金のレポート",
  "lbl_time_approval_dashboard": "時間承認ダッシュボード",
  "lbl_time_budget_unit_of_measurement": "測定値の時間予算単位",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "時間と料金: 時間と金額",
  "lbl_time_fees_amounts_display_name": "時間と料金: 金額",
  "lbl_time_fees_days_filter_display_name": "時間と料金: 日数",
  "lbl_time_frame": "時間枠",
  "lbl_time_tracking": "時間追跡",
  "lbl_today": "今日",
  "lbl_tomorrow": "明日",
  "lbl_total": "合計",
  "lbl_tuesday_abbr_uc": "火",
  "lbl_type": "タイプ",
  "lbl_unarchive": "アーカイブ解除する",
  "lbl_unarchive_project": "プロジェクトをアーカイブ解除する",
  "lbl_unassigned": "未割り当て",
  "lbl_unconfirmed": "未確定",
  "lbl_unmanaged": "アンマネージド",
  "lbl_unmanaged_resource": "アンマネージド リソース",
  "lbl_unmanaged_resources": "アンマネージド リソース",
  "lbl_unlock": "ロック解除",
  "lbl_upcoming": "予定あり",
  "lbl_update": "更新",
  "lbl_update_status": "ステータスの更新: ",
  "lbl_updated": "更新された",
  "lbl_updating": "更新中...",
  "lbl_utilization": "稼働率",
  "lbl_utilization_target": "稼働率目標",
  "lbl_vacation": "休暇",
  "lbl_values": "値",
  "lbl_view": "表示",
  "lbl_view_resourcing_schedule": "リソース スケジュールの表示",
  "lbl_wednesday_abbr_uc": "水",
  "lbl_week": "週",
  "lbl_work": "作業",
  "lbl_work_email": "仕事用の電子メール アドレス",
  "lbl_work_item": "作業アイテム",
  "lbl_work_status": "稼働ステータス",
  "lbl_working_from_home": "在宅勤務",
  "lbl_worklist": "作業リスト",
  "lbl_your_projects_includes_the_following_properties": "プロジェクトには次のプロパティが含まれています",
  "msg_account_owner_change_attempt": "このユーザーをアーカイブする前に、アカウント設定でアカウント所有者を変更してください。",
  "msg_activated_smartsheet_auth": "Smartsheet 認証がアクティブ化されます。この認証方法は直ちに有効になり、非アクティブ化できません",
  "msg_add_assignment_to_project": "プロジェクトに割り当てを追加する",
  "msg_add_notes_placeholder": "メモ、詳細、リンクなどを追加する",
  "msg_add_phase_to_project": "プロジェクトにフェーズを追加する",
  "msg_add_project_description": "プロジェクトの説明を追加する",
  "msg_add_save_current_view": "現在のビューの保存を追加する",
  "msg_add_team_member_to_project": "プロジェクトにチーム メンバーを追加する",
  "msg_app_project_members_disabled_tooltip": "これらのプロジェクトを所有しているか、これらのプロジェクトが割り当てられています。",
  "msg_app_project_members_enabled_tooltip": "これらのプロジェクトを所有しているか、メンバーの一員であるか、これらのプロジェクトが割り当てられています。",
  "msg_audit_log_info": "重要: 監査ログは、監査をリクエストした時点の正確なスナップショットを提供するものです。リクエストしたログの生成後に時間または経費のエントリが変更された場合、その監査ログは、過去の内容を反映したものとなります。最新の情報を反映するには、上記の新しいログをリクエストしてください。",
  "msg_auto_provision_checkbox": "自動プロビジョニングを有効にすると、ライセンスと選択した許可レベルがユーザーに自動で割り当てられます。これにより、ユーザーは電子メール招待プロセスをスキップすることもできます。",
  "msg_bracket_search_to_add_filters": "[検索してフィルターを追加する]",
  "msg_cannot_archive_account_owner": "アカウント所有者をアーカイブすることはできません",
  "msg_cert_requirement": "PEM エンコードされたファイル (ファイル拡張子が CER、CRT、または PEM) を使用してください。",
  "msg_cert_upload_complete": "証明書のアップロードが完了しました",
  "msg_checkbox_list_aria": "チェックをオン/オフにして複数選択するか、選択ボタンをクリックして 1 つだけを選択してください",
  "msg_choose_permission_type": "ユーザーの許可タイプを選択する",
  "msg_choose_property_to_sort_by": "並べ替えのプロパティを選択する",
  "msg_close": "閉じる",
  "msg_close_and_redirect_to_profile_page": "閉じて、プロファイル ページにリダイレクトする",
  "msg_count_matches_found": "{{count}} 件一致しました",
  "msg_copy_url_to_share_report": "下記の URL をコピーしてこのレポートを共有する。",
  "msg_customize_report_prompt": "左にあるオプションでレポートをカスタマイズしてください。準備ができ次第、以下のオプションを選択してください。",
  "msg_delete_adv_report_modal": "このアクションによりレポート データの更新が停止しますが、元のレポートおよび対象ワークスペース内のシートは削除されません。",
  "msg_delete_assignment": "割り当ての削除",
  "msg_delete_saved_report": "保存済みレポートの削除",
  "msg_delete_saved_report_modal": "このアクションは元に戻せませんが、同じオプションで新しいレポートを作成することは可能です。",
  "msg_delete_view": "ビューの削除",
  "msg_delete_work_item_warning": "この作業アイテムを完全に削除してもよろしいですか? 関連タスクと割り当てのすべてが削除されます。このアクションは元に戻すことができません。",
  "msg_edit_allocation": "割り当ての編集",
  "msg_edit_end_date": "終了日の編集",
  "msg_edit_start_date": "開始日の編集",
  "msg_edit_status": "ステータスの編集",
  "msg_edit_team_member": "チーム メンバーの編集",
  "msg_edit_work_item": "作業アイテムの編集",
  "msg_enable_bulk_editing_requirements": "一括編集を有効にするには、必ず [プロジェクト所有者] のフィルターを自分自身に設定し、[プロジェクトの状態] のフィルターを [アクティブ] に設定してください。",
  "msg_enable_module_for_existing_organizations": "既存の組織/団体に関するモジュールを有効にする",
  "msg_enable_module_for_new_organizations": "新しい組織/団体に関するモジュールを有効にする",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "このフィルターは、レポート作成の許可があるプロジェクトにロックされています。",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "このフィルターは、所有しているか、割り当てられているプロジェクトにロックされています。",
  "msg_grid_view_aria": "グリッド ビュー: アクティブ化してグリッド ビューを選択してください。",
  "msg_hold_on_loading_ellipsis": "読み込み中のため、お待ちください...",
  "msg_infinite_loader_aria_label": "チェックをオン/オフにして複数選択するか、選択ボタンをクリックして 1 つだけを選択してください",
  "msg_invalid_cert": "選択した証明書は有効なファイル形式ではありません。",
  "msg_list_view_and_project_field_selector": "リスト ビューとプロジェクト フィールド セレクター: アクティブ化してリスト ビューを選択し、プロジェクト フィールドが利用できるものを選択してください。",
  "msg_loading_approval_status": "承認ステータスを読み込み中...",
  "msg_loading_page": "ページを読み込み中...",
  "msg_loading_project_timeline_ellipsis": "プロジェクト タイムラインを読み込み中...",
  "msg_loading_project_users_ellipsis": "プロジェクト ユーザーを読み込み中...",
  "msg_make_bulk_project_archive_warning": "これらのプロジェクトをアーカイブすると、進行中の割り当ては終了し、今後の割り当ては削除されます。このアクションは元に戻すことができません。",
  "msg_make_bulk_project_edit_confirmation": "この変更を実行してもよろしいですか?",
  "msg_make_bulk_project_edit_warning": "これには数分かかる場合があり、元に戻すことができません。",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "プロジェクト メンバーシップとプロジェクト アクセス レベルを管理します。アクセス レベルはこのプロジェクトに限定されます。一部のユーザーは<1>アカウントの許可</1>に基づいて、メンバーシップがなくても編集できます。",
  "msg_need_a_minute_ellipsis": "申し訳ありません。しばらくお待ちください...",
  "msg_new_work_item_title": "新しい作業アイテムのタイトル",
  "msg_no_assignments": "割り当てなし",
  "msg_no_matches_found": "一致するものが見つかりませんでした",
  "msg_no_people_assigned_to_project": "このプロジェクトに割り当てられているユーザーはいません。",
  "msg_no_phases_to_display": "表示するフェーズがありません",
  "msg_no_projects_match_your_criteria": "条件に一致するプロジェクトがありません。",
  "msg_no_propertyies_to_display": "表示するプロパティがありません",
  "msg_no_recent_requests": "最近のリクエストはありません",
  "msg_no_results_to_display": "表示する結果がありません",
  "msg_no_work_for_this_project": "このプロジェクトに現在の作業はありません。",
  "msg_not_available": "利用不可",
  "msg_one_match_found": "1 件一致しました",
  "msg_owners_of_projects_permission_to_report_on": "レポート作成の許可があるプロジェクトの所有者。",
  "msg_people_field_selector": "ユーザー フィールド セレクター: アクティブ化してデータ フィールドを利用できるものを選択してください。",
  "msg_people_settings_info": "ライセンス ユーザーは、スケジュールされた割り当ての時間とタスクを追跡できます。また、オプションでログインの招待を受けることができます。マネージド リソースは、プロジェクトにスケジュールすることはできますが、時間を追跡することはできず、ログイン アクセス権もありません。",
  "msg_people_list_licensed_tooltip": "権限に基づいて、ライセンス ユーザーは、時間の追跡と承認、プロジェクトの計画、人員配置の管理、ユーザー プロファイルの編集、レポートの生成などを実行できます。",
  "msg_people_list_managed_tooltip": "マネージド リソースをアップグレードすると、時間追跡機能をご利用いただけます。",
  "msg_people_list_unmanaged_tooltip": "アンマネージド リソースをアップグレードして、キャパシティ ビューとレポートに含めることができます。",
  "msg_people_list_archived_tooltip": "アーカイブされたユーザーの割り当ては、別のユーザーまたはプレースホルダーへの再割り当てのために、プロジェクト ページに残ります。",
  "msg_phase_picker_copy": "プロジェクトまたはフェーズにコピーする",
  "msg_print_brief_page": "概要ページの印刷",
  "msg_profile_banner": "プロファイル バナー",
  "msg_profile_information": "プロファイル情報",
  "msg_profile_navigation": "プロファイル ナビゲーション",
  "msg_profile_navigation_menu": "プロファイル ナビゲーション メニュー",
  "msg_project_members_tooltip": "これらのプロジェクトを所有しているか、メンバーの一員であるか、これらのプロジェクトが割り当てられています。",
  "msg_project_navigation": "プロジェクト ナビゲーション",
  "msg_project_members_description": "プロジェクト メンバーシップは、このプロジェクトに固有のものです。一部のユーザーは、メンバーでなくても、<0>アカウント許可</0>に基づいてこのプロジェクトにアクセスできます。",
  "msg_reassign_search_placeholder": "名前、分野などを検索します。",
  "msg_refine_search_to_see_more_users": "検索条件を絞り込み、より関連度の高いユーザーを見つけてください",
  "msg_rejection_reason_required": "却下するには理由が必要です。",
  "msg_remove_archived_project_state_to_enable_editing": "[プロジェクトの状態] フィルターから [アーカイブ済み] を削除して、編集を有効にしてください。",
  "msg_remove_assignment_confirmation": "この割り当てを削除してもよろしいですか?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "制限の 3,000 セルを超えているため、レポート データを最新の情報に更新できませんでした。左パネルのオプションを調整して、レポートのサイズを縮小してください。<1>詳細情報</1>",
  "msg_report_data_refresh_err_detail_generic": "詳細レポート データを最新の情報に更新中にエラーが発生しました。新しい詳細レポートを作成してください。",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "制限の 300 行を超えているため、レポート データを最新の情報に更新できませんでした。左パネルのオプションを調整して、レポートのサイズを縮小してください。<1>詳細情報</1>",
  "msg_report_data_refresh_err_detail_sheet_access": "詳細レポート データを最新の情報に更新中にエラーが発生しました。シートの権限を更新するか、または新しい詳細レポートを作成してください。",
  "msg_report_is_too_big_to_load": "レポートが大きすぎて読み込めません",
  "msg_save_report_analytics_page": "このレポートを分析ページに保存する",
  "msg_search_contacts": "連絡先を検索",
  "msg_settings_people_manage_info": "アカウントでユーザーの<1>ログイン アクセス</1>と<3>許可</3>を管理してください。<br />サブスクリプションを変更するには、<5>こちらからお問い合わせください</5>。",
  "msg_share_report": "以下のコピーボタンで URL をコピーし、このレポートを共有する。",
  "msg_sheet_collaborators": "リンクされたプロジェクトのシートの共同作業者は、ここには表示されません。シートの共有を更新する、またはシートの共同作業者を追加するには、Smartsheet で管理してください。<0>詳細情報</0>",
  "msg_signup_loading_transition": "無料お試しアカウントの設定中です。",
  "msg_sort_by_last_name": "並べ替え: 名前",
  "msg_specify_budget_in_unit": "{{curUnit}} のこの予算を指定します",
  "msg_sso_configuration_info": "推奨された自動構成は SSO 構成を簡素化し、既定で複数の証明書をサポートします。手動構成では、1 度に 1 つの証明書しかサポートされません。",
  "msg_sso_enabled": "ご利用のアカウントに対して SSO が有効になっています",
  "msg_sso_info": "パスワードの管理とユーザー プロビジョニングにシングル サインオン (SSO) を使用することで、企業はリソース管理へのアクセスをより高度に制御できるようになり、適切なユーザーによるサービスへの安全なログインが保証されます。",
  "msg_sso_login_failure": "Resource Management by Smartsheet にサインインすることができませんでした。",
  "msg_sso_not_configured": "ご利用のアカウントに対して SSO が設定されていません",
  "msg_sso_unconfigurable": "ご自分の組織/団体で Smartsheet 認証が使用されているため、リソース管理で SSO を直接設定することができません。SSO の設定は Smartsheet 管理センターで管理できます",
  "msg_terms": "利用規約",
  "msg_thanks": "よろしくお願いいたします。",
  "msg_there_are_no_approvals_matching_your_current_filters": "現在のフィルターに一致する承認はありません。",
  "msg_to_inactive_project": "非アクティブなプロジェクト",
  "msg_transfer_project_ownership_to_remove_user": "{{member.name}} がプロジェクト所有者です。削除することはできません。[プロジェクト設定] タブで所有権を移行します。",
  "msg_type_and_press_enter_to_add_task": "入力して Enter キーを押し、タスクを追加する",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} はアンマネージド リソースです。編集、またはこのプロジェクトから削除するには、[アカウント設定] でアップグレードしてください。",
  "msg_cant_add_unmanaged_resource": "{{member.name}} はアンマネージド リソースです。このプロジェクトに追加するには、[アカウント設定] でアップグレードしてください。",
  "msg_upload_failed": "アップロード失敗",
  "msg_upload_succeeded": "アップロードに成功しました",
  "msg_user_default_login_tooltip": "このユーザーは、SSO を使用してサインインしていません。切り替えるには、ユーザーにサインアウトをして、SSO を使用してサインインし直すように依頼してください。",
  "msg_user_smar_login_tooltip": "このユーザーは、Smartsheet 認証を使用してサインインしていません。切り替えるには、ユーザーにサインアウトをして、Smartsheet 認証を使用してサインインし直すように依頼してください。",
  "msg_wait_while_load_assignments": "割り当てを読み込むまで、しばらくお待ちください",
  "msg_welcome_to_resource_management_by_smartsheet": "Resource Management by Smartsheet へようこそ",
  "msg_work_item_description_placeholder": "作業、割り当てなどの説明",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "これらのプロジェクトを所有していないか、これらのプロジェクトが割り当てられていません。",
  "msg_saved_form_already_exists": "既に存在します。置換しますか?",
  "msg_you_do_not_have_admin_access": "お探しのワークスペースに対する管理者権限があることを確認してください。<0>Smartsheet</0> でアクセスをリクエストするか、新しい対象ワークスペースを作成することができます。",
  "msg_no_matching_results": "一致する結果がありません",
  "msg_report_filter_list": "これはレポート フィルター リストです。レポートに含めるデータを選択します。",
  "msg_report_filter_show_group": "これはレポート フィルター表示グループです。各カテゴリのオプション表示を選択します。「すべて」または「なし」を選択するか、チェックボックスをオン/オフして複数を選択するか、ラジオ ボタンをアクティブにして 1 つだけ選択します。",
  "lbl_getting_information": "情報を取得中...",
  "lbl_actions": "アクション",
  "lbl_delete_phase": "フェーズを削除する",
  "lbl_chars_left": "左",
  "lbl_set_to": "に設定する",
  "msg_no_ppl_match": "条件に一致するユーザーがいません",
  "lbl_user_info_summary": "ユーザー情報のサマリー",
  "lbl_saved_reports": "保存済みレポート",
  "lbl_no_active_assignments": "アクティブな割り当てがありません。",
  "msg_entries_not_shown": "未所有プロジェクトのエントリは表示されません",
  "msg_provide_note": "この承認に関するメモを入力します。却下するにはこれが必要です",
  "msg_taking_longer_than_expected": "予想以上に時間がかかっています。少しお待ちください",
  "msg_want_to_make_adjustments": "調整しますか、高速化しますか? 引き続きフィルターを使用して、レポートを洗練させたり範囲を絞り込んだりできます。",
  "msg_calculating_time_entries": "およそ {{timeEntriesCount}} 件の時間エントリを計算しています。",
  "lbl_just_a_few_seconds": "数秒",
  "lbl_less_than_a_min": "1 分未満",
  "lbl_about_a_min": "約 1 分",
  "msg_add_users_intro": "このページを使用して、アカウントに新しいユーザーを追加するか既存のユーザーを更新します。",
  "msg_add_projects_intro": "このページを使用して、アカウントに新しいプロジェクトを追加するか既存のプロジェクトを更新します。",
  "msg_more_details_can_be_found": "詳細は<0>ヘルプ トピック</0>をご確認ください。ご質問がある場合は、サポート チームに <1>ご連絡ください</1>。",
  "lbl_step_1_prepare_your_data": "1. データを準備します",
  "lbl_step_1_content_users": "注: ユーザー ID の列を編集しないでください。これは既存のユーザーに対する更新の照合に使用されます。",
  "lbl_step_1_content_projects": "注: プロジェクト ID の列を編集しないでください。これは既存のプロジェクトに対する更新の照合に使用されます。",
  "lbl_step_1_contents_create_users": "このテンプレートを使用して、新しいユーザーをアカウントに追加します。",
  "lbl_step_1_contents_create_projects": "このテンプレートを使用して、新しいプロジェクトをアカウントに追加します。",
  "lbl_step_1_contents_edit_users": "組織のすべてのユーザーのリストをエクスポートして、その属性を更新します。",
  "lbl_step_1_contents_edit_projects": "組織のすべてのプロジェクトのリストをエクスポートして、その属性を更新します。",
  "lbl_people_lc": "ユーザー",
  "lbl_projects_lc": "プロジェクト",
  "lbl_add_update_projects": "プロジェクトを追加/更新する",
  "lbl_download_template": "テンプレートのダウンロード",
  "lbl_update_existing": "既存の内容を更新する",
  "lbl_export_project_list": "プロジェクト リストをエクスポートする",
  "lbl_step_2_upload_data_users": "2. 更新されたユーザー リストをアップロードします",
  "lbl_step_2_upload_data_projects": "2. 更新されたプロジェクト リストをアップロードします",
  "msg_select_csv_file": "{{importType}} を追加または更新するために、更新された CSV ファイルを選択してアップロードします。完了時に確認メールが送信されます。",
  "lbl_send_invitation_new_licensed_users": "新しいライセンス ユーザーに招待を送信します。",
  "lbl_upload": "アップロード",
  "lbl_processing": "処理中...",
  "lbl_add_new": "新しい {{importType}} の追加",
  "msg_import_in_progress": "インポートの進行中...",
  "msg_errors_during_import": "インポート時にエラーが見つかりました",
  "lbl_import_complete": "インポート完了",
  "lbl_added_colon": "追加済み: {{number}}",
  "lbl_updated_colon": "更新済み: {{number}}",
  "lbl_failed_colon": "失敗: {{number}}",
  "lbl_download_error_report_csv": "エラー レポート (CSV) のダウンロード",
  "msg_working_on_import_background": "このインポートはバックグラウンドで実行されています。インポートが完了すると、電子メールが送信されます。このページへのリンクは、<0>{{pageTitle}}</0> ページの下部にあります。",
  "msg_rows_with_errors": "エラーの行は追加または更新されませんでした。CSV レポートをダウンロードし、エラーを修正し、もう一度やり直すことができます。追加のエラーが存在する可能性があります。再アップロードする前にすべてのデータを確認してください。{{importType}} のインポートと更新に関する<0>詳細情報</0>をご確認ください。",
  "msg_completed_processing": "組織に最近追加し、更新した{{importType}}の処理が完了しました。",
  "lbl_imports_last_30_days": "過去 30 日間からインポート",
  "lbl_characters": "文字 ",
  "lbl_character": "文字 ",
  "lbl_remaining_lc": "残り",
  "msg_no_items_match_criteria": "条件に一致するアイテムがありません",
  "lbl_future_scheduled_lc": "スケジュール済み",
  "lbl_loading_details": "詳細を読み込み中...",
  "lbl_try_again": "再試行",
  "err_must_not_be_blank": "入力は必須です",
  "err_cannot_be_more_than_255_char": "255 文字を超えることはできません",
  "lbl_view_name": "ビュー名",
  "lbl_project_timelines": "プロジェクト タイムライン",
  "msg_shorten_time_frame": "時間枠を短くするか、フィルターを使ってユーザーまたはプロジェクトを絞り込み、レポートの再読み込みをお試しください。または、CSV 形式のこのレポートへのリンクを含む電子メールをリクエストしてください。",
  "msg_underlying_data_requested": "元のデータがリクエストされました。ダウンロードの準備ができたら、電子メールが送信されます。この処理には時間がかかる場合があります。",
  "msg_no_report_values": "このレポートに対する値がありません。",
  "msg_report_only_includes_data": "このレポートには、閲覧許可を持つデータのみが含まれます。",
  "msg_we_have_logged_error": "ログに記録されたエラーを調査します。後でもう一度最初からお試しください。",
  "msg_budgets_report_includes_all": "このレポートには、日付が重複しているプロジェクトの期間全体について、発生したデータ、スケジュール済みデータ、予算データがすべて含まれます。",
  "lbl_run_report": "レポートの実行",
  "lbl_request_underlying_data": "元のデータをリクエストする",
  "lbl_selected": "選択済み",
  "lbl_selected_lc": "選択済み",
  "lbl_project_lc": "プロジェクト",
  "msg_change_client_name": "次に対するクライアントが変更されます:",
  "msg_change_project_type": "次に対するプロジェクト タイプが変更されます:",
  "msg_change_project_owner": "次に対するプロジェクト所有者が変更されます:",
  "msg_change_tags": "次に対するタグが変更されます:",
  "msg_change_custom_fields": "次に対するカスタム フィールドが変更されます:",
  "msg_this_will_archive_the": "これで次がアーカイブされます:",
  "msg_this_will_add_selected_tags": "これで選択したタグが追加されます",
  "msg_this_will_remove_selected_tags": "これで選択したタグが削除されます",
  "msg_updating_client": "次に対するクライアントを更新しています:",
  "msg_updating_project_owner": "次に対するプロジェクト所有者を更新しています:",
  "msg_describe_work_item_placeholder": "作業、成果または成果物の説明",
  "msg_show_hide_phase_items": "フェーズ {{phaseName}} の作業アイテムを表示/非表示にします",
  "msg_edit_phase": "フェーズ {{phaseName}} の編集",
  "msg_show_hide_project_items": "プロジェクト {{projectName}} の作業アイテムを表示/非表示にします",
  "msg_edit_project": "プロジェクト {{projectName}} の編集",
  "lbl_hours_lc": "時間",
  "lbl_hours_per_day_abbr": "時間/日",
  "lbl_new": "新規",
  "lbl_add_team_member_to": "{{parentLabel}} にチーム メンバーを追加する",
  "lbl_add_work_item_to": "{{parentLabel}} に作業アイテムを追加する",
  "lbl_available_hours_lc": "作業可能時間",
  "lbl_remaining_or_budget_forecast": "残り ({{budgetForecastPercent}})",
  "lbl_forecasted_over_or_under_budget": "推定{{overUnder}}予算 ({{budgetForecastPercent}})",
  "lbl_under_lc": "残余",
  "lbl_over_lc": "超過",
  "lbl_expense_budget_lc": "経費予算",
  "lbl_project_budget_lc": "プロジェクト予算",
  "lbl_expensed_lc": "支出済み",
  "lbl_incurred_lc": "発生した",
  "lbl_add_description": "説明を追加",
  "lbl_automatic_configuration": "自動構成",
  "lbl_recommended_paren": "(おすすめ)",
  "lbl_manual_configuration": "手動構成",
  "lbl_manage_sheet_sharing": "シートの共有を管理",
  "lbl_cert_must_be_PEM": "証明書は、PEM エンコードされたもの (.pem、.cert、.crt、.cer ファイル) でなければなりません",
  "msg_auto_provision_auth_users": "アカウントに登録されていない自動プロビジョニングで認証されたユーザー (電子メールのみで作成)",
  "lbl_selected_phases": "選択したフェーズを使用する",
  "lbl_no_display_props": "表示するプロパティがありません",
  "msg_approval_audit_error": "予期しない問題が発生しました。問題が解決しない場合は、サポートまでご連絡ください。 ",
  "msg_download_error": "次のファイルのダウンロード試行中にエラーが発生しました: ",
  "lbl_audit_log_req": "リクエストされた監査ログ",
  "msg_audit_log_req": "以下の「最近のリクエスト」テーブルにダウンロード リンクが表示されます。これには数分かかる場合があります。",
  "lbl_audit_log_expense": "経費監査ログ",
  "lbl_download": "ダウンロード",
  "lbl_proj_singular": "プロジェクト:",
  "lbl_proj_plural": "プロジェクト:",
  "lbl_zero_proj": "0 件のプロジェクト",
  "lbl_success_lc": "正常に完了しました",
  "lbl_important": "<1>重要:</1>",
  "lbl_edit_properties": "プロパティの編集",
  "lbl_error": "エラー",
  "lbl_change": "変更",
  "lbl_select_new": "新規の選択",
  "lbl_approver_project": "承認者: ",
  "lbl_successfully": " 正常に完了しました。",
  "msg_update": "更新完了",
  "lbl_of": "/",
  "msg_update_failed": "更新に失敗しました。",
  "msg_update_lc": "正常に更新されました。",
  "lbl_edit_desc": "説明の編集",
  "lbl_no_select": "選択されていません",
  "lbl_current_filter_select": " : 現在の設定: ",
  "msg_getting_ready": "準備中...",
  "msg_retry_update": "[再試行] をクリックして、失敗した更新を修正します。",
  "msg_error_support": "このエラーが繰り返し表示される場合は、サポートまでご連絡ください。 ",
  "lbl_curr_dropdown_val": "現在のドロップダウン リスト値: ",
  "msg_cancel_view": "キャンセルしてビュー リストに戻ります",
  "msg_updating_tags": "のタグを更新中...",
  "msg_updatings_proj_type": "のプロジェクト タイプを更新中...",
  "msg_start_your_trial_for_free": "{{trialDuration}} 日間の無料お試しを開始する",
  "msg_create_free_account": "無料アカウントを作成する",
  "msg_I_agree_to_terms_and_conditions": "私は<0>利用規約</0>を承諾し、私の個人データが<1>プライバシー ポリシー</1>に従って Resource Management by Smartsheet によって処理されることに同意します",
  "msg_something_went_wrong_signup": "申し訳ありません。サインアップで問題が発生しました。<0>サポート ページ</0>からお問い合わせください",
  "msg_edit_phase_settings": "フェーズ設定の編集",
  "msg_edit_project_settings": "プロジェクト設定の編集",
  "msg_shift_phase_dates": "フェーズの日付をシフトする",
  "msg_shift_project_dates": "プロジェクトの日付をシフトする",
  "lbl_utilization_lc": "稼働率",
  "lbl_target_utilization_lc": "目標稼働率",
  "msg_organization_using_smar_auth_modifification_info": "お客様の組織は、リソース管理へのログインに、現在 Smartsheet 認証を使用しています。この設定を変更するには、<1>サポートまでお問合せ</1>ください",
  "lbl_user_auto_provisioning": "自動プロビジョニング",
  "lbl_edit": "編集",
  "lbl_permission_level": "許可レベル",
  "msg_activate_smar_auth_info": "アクティブ化すると、ユーザーが独自の Smartsheet アカウントでログインできるようになります。<1>詳細情報</1>",
  "lbl_activate_smar_auth": "Smartsheet 認証をアクティブ化",
  "msg_please_link_rm_account_with_smar": "Smartsheet 認証を許可するには、<1>リソース管理アカウントを Smartsheet アカウントにリンクしてください。</1>",
  "lbl_setup_sso": "SSO を設定する",
  "lbl_smartsheet_authentication": "Smartsheet 認証",
  "lbl_edit_sso": "SSO 設定の編集",
  "msg_disabled_filter_info": "このオプションは、詳細レポートでは使用できません。このオプションにアクセスするには、<0>新しいレポート</0>を作成してください。",
  "msg_people_in_org_have_logged_in_using_sso": "組織のユーザーは既に SSO を使用してログインしています。SSO の設定を間違って編集すると、ユーザーがログインできなくなる場合があります。SSO の設定を安全に更新するには、サポートまで<1>お問い合わせ</1>ください。",
  "err_issue_enabling_sso": "SSO 認証の有効化に問題がありました: {{error}}。この問題が解決しない場合は、サポートまでご連絡ください。",
  "err_activating_smar_auth": "Smartsheet 認証のアクティブ化エラー",
  "err_issue_updating_smar_auth": "Smartsheet 認証の更新に問題がありました: {{error}}。この問題が解決しない場合は、サポートまでご連絡ください。",
  "err_issue_enabling_smar_auth": "Smartsheet 認証の有効化に問題がありました: {{error}}。この問題が解決しない場合は、サポートまでご連絡ください。",
  "msg_report_unmanaged_resources_admin": "このレポートにアンマネージド リソースを含めるには、<0>アカウント設定</0>でリソースをアップグレードしてください。",
  "msg_report_unmanaged_resources": "このレポートにアンマネージド リソースを含めるには、アカウント設定でリソースをアップグレードするよう、管理者に依頼してください。",
  "lbl_adjust_refresh_settings": "データを最新の情報に更新する方法の設定を調整",
  "lbl_view_advanced_report": "詳細レポートの表示",
  "lbl_save_and_refresh_data": "変更を保存してデータを最新の情報に更新",
  "lbl_refresh_data": "データを最新の情報に更新",
  "lbl_delete_report": "レポートの削除",
  "lbl_toggle_reports_control_menu": "レポート コントロール メニューの切り替え",
  "lbl_advanced_report": "詳細レポート",
  "lbl_create_new_advanced_report": "詳細レポートの新規作成",
  "msg_open_the_advanced_report_in_smartsheet": "Smartsheet で詳細レポートを開く",
  "lbl_advanced_report_edit_modal_title": "データを最新の情報に更新する方法の設定",
  "lbl_advanced_report_modal_title": "詳細レポートの作成",
  "msg_advanced_report_modal_description": "データを元のシートと Smartsheet レポートに送信します。分析ページからレポートを更新したり、設定を調整したりできます。",
  "lbl_advanced_report_modal_name_field": "名前",
  "lbl_advanced_report_modal_destination_field": "ターゲット",
  "lbl_advanced_report_modal_data_refresh_field": "データを最新の情報に更新する方法の設定",
  "lbl_advanced_report_modal_data_refresh_auto_name": "自動",
  "lbl_advanced_report_modal_data_refresh_auto_description": "詳細レポートのデータは 24 時間ごとに最新の情報に更新されます",
  "lbl_advanced_report_modal_data_refresh_manual_name": "手動",
  "lbl_advanced_report_modal_data_refresh_manual_description": "データは、分析ページから手動で最新の情報に更新する必要があります",
  "lbl_advanced_report_modal_close_button": "閉じる",
  "lbl_advanced_report_modal_create_button": "作成",
  "msg_advanced_report_modal_need_lws": "この機能を使用するには、リソース管理者に Smartsheet 認証のアクティブ化を依頼してください。",
  "lbl_destination_dropdown_select_workspace": "ワークスペースを選択",
  "lbl_destination_dropdown_search_for_workspace": "ワークスペースの検索",
  "msg_advanced_report_ready_title": "詳細レポートの準備ができました。",
  "msg_advanced_report_ready": "詳細レポートのステータスは、分析ページからいつでもアクセスして表示できます",
  "lbl_advanced_report_view_btn": "詳細レポートの表示",
  "msg_advanced_report_creating_title": "詳細レポートを作成中...",
  "msg_advanced_report_creating": "これには数分かかる場合があります。サンプル データを含むレポートをプレビューできます。実際のデータが利用可能になり次第、同データに置き換えられます。",
  "lbl_advanced_report_preview_btn": "レポートのプレビュー...",
  "msg_advanced_report_creation_footer": "安全に閉じることができます。このレポートは、保存済みレポートまたは選択したワークスペースで後から確認できます。",
  "lbl_advanced_report_creation_footer_link": "詳細レポートに関するヒントをご覧ください。",
  "lbl_create_advanced_report": "詳細レポートの作成",
  "lbl_edit_settings": "レポート設定の編集",
  "err_create_report_failed_generic": "レポートの作成中にエラーが発生しました。もう一度やり直してください。",
  "err_edit_report_failed_generic": "レポートの編集中にエラーが発生しました。もう一度やり直してください。",
  "err_create_report_failed_invalid_name": "レポート名には、特殊文字または絵文字を使用できません",
  "msg_session_expired_title": "セッションが期限切れになりました",
  "msg_session_expired_description": "このウィンドウでのセッションは無効になりました。下のボタンをクリックして、ページを最新の情報に更新してください。再度ログインが必要になる場合があります。",
  "lbl_refresh_page": "ページを最新の情報に更新",
  "msg_refresh_advanced_report": "詳細レポートにフィルターを適用するには、メニューからデータを最新の情報に更新してください。保存されている RM レポートも更新されます。",
  "msg_advanced_report_too_many_cells": "レポートの列または行が多すぎます。左パネルのオプションを調整して、レポートのサイズを縮小してください。<0>詳細情報</0>",
  "msg_advanced_report_too_many_columns": "レポートの列が多すぎます。左パネルのオプションを調整して、レポートのサイズを縮小してください。<0>詳細情報</0>",
  "msg_advanced_report_too_many_rows": "レポートの行が多すぎます。左パネルのオプションを調整して、レポートのサイズを縮小してください。<0>詳細情報</0>"
}