import PropTypes from 'prop-types';
import React from 'react';

const GridIcon = ({ cssClass }) => (
  <svg className={cssClass} width="23px" height="17px" viewBox="0 0 23 17">
    <rect x="0" y="0" width="11" height="8" />
    <rect x="12" y="0" width="11" height="8" />
    <rect x="0" y="9" width="11" height="8" />
    <rect x="12" y="9" width="11" height="8" />
  </svg>
);

GridIcon.propTypes = {
  cssClass: PropTypes.string,
};

export default GridIcon;
