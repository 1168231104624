import React from 'react';

export const ShiftIcon = () => (
  <svg viewBox="0 0 24 24">
    <polygon
      // eslint-disable-next-line max-len
      points="8 5 8 10.5 6 10.5 6 9.17 3.17 12 6 14.83 6 13.5 8 13.5 8 19 11 19 11 13.5 11 10.5 11 5 8 5"
    />
    <polygon
      // eslint-disable-next-line max-len
      points="16.09 5 16.09 10.5 18.09 10.5 18.09 9.17 20.91 12 18.09 14.83 18.09 13.5 16.09 13.5 16.09 19 13.09 19 13.09 13.5 13.09 10.5 13.09 5 16.09 5"
    />
  </svg>
);


export default ShiftIcon;
