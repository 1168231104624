import React from 'react';
import PropTypes from 'prop-types';

export const TasksIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="10 18.12 4.94 13.06 7.06 10.94 10 13.88 16.94 6.94 19.06 9.06 10 18.12" fill="gray" /></svg>
  </svg>
);

TasksIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default TasksIcon;
