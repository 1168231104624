import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { List, AutoSizer } from 'react-virtualized';
import AllOrNone from '../AllOrNone';
import CheckboxWithLabel from '../CheckboxWithLabel';

import style from './style.scss';
import focusElementAfterDelay from '../../utils/focusElementAfterDelay';
import Avatar from '../Avatar';

const rowHeightByType = {
  heading: 30,
  single: 37,
  allnone: 32,
  multi: 35,
  divider: 10,
  spacer: 20,
  custom: 30,
  nonSelectableText: 30,
  smallSpacer: 4,
};

const SelectList = ({
  list,
  listRef,
  onSelect,
  onMultiSelect,
  selectedListItemId,
  onRowsRendered,
  onSelectFocusId,
}) => {
  const { t } = useTranslation();
  const getRowHeightByType = ({ index }) => {
    if (!list[index]) return 0;
    const { type, height } = list[index];
    if (type === 'custom' && height) {
      return height;
    }
    if (type === 'single' && window.IS_EMBEDDED_SCHEDULE) {
      return 52;
    }
    return type ? rowHeightByType[type] : 37;
  };

  // NOTE disabling proptype eslint rule since rowRenderer is internal to SelectList
  // eslint-disable-next-line react/prop-types
  const rowRenderer = ({ key, index, style: positionStyle }) => {
    const listItem = list[index];
    const isActive = listItem.item.id === selectedListItemId;

    return (
      <div key={key} style={positionStyle}>
        {listItem.type === 'custom' && listItem.item && listItem.item}

        {listItem.type === 'heading' && listItem.item && (
          <div className={style.optionLabel}>{listItem.item.name}</div>
        )}

        {listItem.type === 'nonSelectableText' && listItem.item && (
          <div className={style.optionText}>{listItem.item.name}</div>
        )}

        {listItem.type === 'allnone' && (
          <AllOrNone
            onAll={listItem.item.onAll}
            onNone={listItem.item.onNone}
          />
        )}
        {/* dropdown list */}
        {listItem.type === 'single' && (
          <button
            className={isActive ? style.optionActive : style.option}
            onClick={() => {
              onSelect({
                type: 'single',
                uid: listItem.item.id || listItem.item.uid,
                item: listItem.item,
              });
              if (onSelectFocusId)
                focusElementAfterDelay(`#${onSelectFocusId}`);
            }}
            onKeyPress={() =>
              onSelect({
                type: 'single',
                uid: listItem.item.id || listItem.item.uid,
                item: listItem.item,
              })
            }
          >
            {window.IS_EMBEDDED_SCHEDULE ? (
              <div className={style.thumbnailContainer}>
                <Avatar user={listItem.item} size='MediumSmall' round />
                <div className={style.nameContainer}>
                  <span>
                    {listItem.item.display_name ||
                      listItem.item.phase_name ||
                      listItem.item.name}
                  </span>
                  <span className={style.email}>{listItem.item.email}</span>
                </div>
              </div>
            ) : (
              <>
                {listItem.item.display_name ||
                  listItem.item.phase_name ||
                  listItem.item.name}
              </>
            )}
          </button>
        )}

        {listItem.type === 'multi' && (
          <CheckboxWithLabel
            label={
              listItem.item.display_name ||
              listItem.item.phase_name ||
              listItem.item.name
            }
            selected={listItem.item.selected}
            onSelect={() =>
              onMultiSelect({
                type: 'label',
                uid: listItem.item.id || listItem.item.uid,
              })
            }
            onMultiSelect={() =>
              onMultiSelect({
                type: 'checkbox',
                uid: listItem.item.id || listItem.item.uid,
                selected: listItem.item.selected,
              })
            }
          />
        )}

        {listItem.type === 'divider' && <div className={style.optionDivider} />}

        {listItem.type === 'spacer' && <div className={style.optionSpacer} />}

        {listItem.type === 'smallSpacer' && (
          <div className={style.smallSpacer} />
        )}
      </div>
    );
  };

  const noRowRenderer = () => (
    <div className={style.noOption}>{t('msg_no_results_to_display')}</div>
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          rowCount={list.length}
          rowHeight={getRowHeightByType}
          height={height}
          rowRenderer={rowRenderer}
          noRowsRenderer={noRowRenderer}
          onRowsRendered={onRowsRendered}
          ref={listRef}
        />
      )}
    </AutoSizer>
  );
};

SelectList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  listRef: PropTypes.func,
  selectedListItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func.isRequired,
  onMultiSelect: PropTypes.func,
  onRowsRendered: PropTypes.func,
  onSelectFocusId: PropTypes.string,
};

export default SelectList;
