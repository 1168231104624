import PropTypes from 'prop-types';
import React from 'react';

const TrashIcon = ({ className }) => (
  <svg className={className} width="16px" height="16px" viewBox="0 0 16 16">
    <rect x="7" y="3" width="3" height="1" />
    <rect x="4" y="4" width="9" height="1" />
    <rect x="4" y="5" width="1" height="1" />
    <rect x="12" y="5" width="1" height="1" />
    <rect x="4" y="6" width="9" height="1" />
    <rect x="5" y="7" width="1" height="6" />
    <rect x="7" y="7" width="1" height="6" />
    <rect x="9" y="7" width="1" height="6" />
    <rect x="11" y="7" width="1" height="6" />
    <rect x="5" y="12" width="7" height="1" />
  </svg>
);

TrashIcon.propTypes = {
  className: PropTypes.string,
};

export default TrashIcon;
