import React from 'react';

export const DatesIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      // eslint-disable-next-line max-len
      d="M17,5H4V19H19V5ZM14,9v2H12V9Zm0,5H12V12h2Zm1-2h2v2H15ZM9,12h2v2H9ZM8,14H6V12H8Zm3-5v2H9V9ZM8,9v2H6V9ZM6,17V15H8v2Zm3,0V15h2v2Zm3,0V15h2v2Zm3,0V15h2v2Zm2-6H15V9h2Z"
    />
  </svg>
);

export default DatesIcon;
