const focusElementAfterDelay = (selector) => {
  if (!selector) return;
  // NOTE in non-browser (usually test) envs `document` won't be available
  if (typeof selector === 'string' && !document) return;

  const focusInterval = setInterval(() => {
    if (typeof selector === 'string') {
      const element = document.querySelectorAll(selector);
      if (element.length > 0) {
        element[0].focus();
      }
      clearInterval(focusInterval);
    } else {
      selector.current.focus();
      clearInterval(focusInterval);
    }
  }, 100);
  setTimeout(() => clearInterval(focusInterval), 150);
};

export default focusElementAfterDelay;
