// A loose mapping on how to adjust for widths in different languages.
// The multipliers are based on the text size of the languages, compared to English.
// Ref: https://intext.eu/blog/dtp_3.html
const DEFAULT_WIDTH_MULTIPLIER = 1.0;
const WIDTH_MULTIPLIERS: { [language: string]: number } = {
  de: 1.6,
  en: 1.0,
  es: 1.6,
  fr: 1.6,
  it: 1.6,
  ja: 1.6,
  pt: 1.6,
  ru: 1.8,
};

export const getWidthMultiplier = (language?: string) => {
  const lang = (language || window.navigator.language).match(/^(\w+)[-_\w]*/);
  if (lang === null) {
    return DEFAULT_WIDTH_MULTIPLIER;
  }
  return WIDTH_MULTIPLIERS[lang[1].toLowerCase()] || DEFAULT_WIDTH_MULTIPLIER;
};

declare global {
  interface Window {
    rm: {
      l10n: {
        getWidthMultiplier: (language?: string) => number;
      };
    };
  }
}

window.rm = {
  ...window.rm,
  l10n: {
    ...window.rm?.l10n,
    getWidthMultiplier,
  },
};
