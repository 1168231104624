import statusStyle from './style.scss';

const getStyle = (color) => {
  switch (color) {
    case 'blue_bright': {
      return statusStyle.blueBrightIcon;
    }
    case 'blue_dark': {
      return statusStyle.blueDarkIcon;
    }
    case 'green_bright': {
      return statusStyle.greenBrightIcon;
    }
    case 'green_dark': {
      return statusStyle.greenDarkIcon;
    }
    case 'yellow': {
      return statusStyle.yellowIcon;
    }
    case 'orange': {
      return statusStyle.orangeIcon;
    }
    case 'red': {
      return statusStyle.redIcon;
    }
    case 'purple': {
      return statusStyle.purpleIcon;
    }
    case 'dark_grey': {
      return statusStyle.darkGreyIcon;
    }
    case 'gray': {
      return statusStyle.grayIcon;
    }
    case 'internal_purple': {
      return statusStyle.internalPurpleIcon;
    }
    default: {
      return statusStyle.blueBrightIcon;
    }
  }
};

export default getStyle;
