import React from 'react';
import PropTypes from 'prop-types';

export const OnTheRoadIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <rect width="24" height="24" fill="#24a2eb" />
    <path d="M23,11.37a6,6,0,0,0-3.38-4,24.19,24.19,0,0,0-5.79-2.18,4.12,4.12,0,0,1-1.24-.41.78.78,0,0,1-.36-.61c0-.58.9-1.11,1-1.21l9.74,0Z" fill="#fff" />
    <path d="M1,15.68C6.47,14,14.06,10.56,13.85,8.35c-.1-1.08-1.51-1.47-2.65-1.79C10.26,6.29,9.44,6,9.44,5.35A2.58,2.58,0,0,1,11.16,3H1Z" fill="#fff" />
    <polygon points="8.34 21 10.6 19 13.64 19 12.26 21 8.34 21" fill="#fff" />
    <polygon points="12.67 17.42 15.12 17.45 17.64 14 16.08 14 12.67 17.42" fill="#fff" />
    <polygon points="17.04 13 18.3 13 18.82 10.73 18.01 10.64 17.04 13" fill="#fff" />
    <polygon points="17.49 8.7 17.93 9.76 18.6 9.76 18.08 8.6 17.49 8.7" fill="#fff" />
    <polygon points="17.04 8.18 17.49 8.04 16.6 7.31 16.38 7.31 17.04 8.18" fill="#fff" />
    <polygon points="14.9 6.83 15.04 6.91 15.64 7.2 15.86 7.13 14.9 6.83" fill="#fff" />
  </svg>
);

OnTheRoadIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default OnTheRoadIcon;
