import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AddIcon } from '../../../Icon';

import style from './style.scss';

const SizedIcon = () => <div className={style.icon}><AddIcon /></div>;

export const SmallAddButton = props => (
  <div className={style.link}>
    <div className={style.icon}>{props.icon && props.icon()}</div>
    { props.label ? props.label : props.component }
  </div>
);

export const SmallAddLink = ({ to, label, onClick, ariaLabel, ...rest }) => (
  <Link
    to={to}
    className={style.link}
    onClick={onClick || (() => {})}
    aria-label={ariaLabel}
    {...rest}
  >
    <SizedIcon /> {label}
  </Link>
);

SmallAddButton.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.string,
  component: PropTypes.node
};

SmallAddLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string
};

export default SmallAddButton;
