import { authenticatedPing } from './authenticatedPing';
/**
 * 30 seconds
 */
const AUTHENTICATED_PING_MIN_REFRESH_TIME = 1 * 30 * 1000;
/**
 * 20 minutes
 */
const AUTHENTICATED_PING_MAX_REFRESH_TIME = 20 * 60 * 1000;

export const beginAuthenticatedPingLoop = () => {
  // skip for admin dash or developer box because it won't work due to CORS
  if (isOnProductionAdminDashHost() || isOnDeveloperEnv()) return;

  let authenticatedPingRefreshTime = AUTHENTICATED_PING_MIN_REFRESH_TIME;

  const callAuthenticatedPingAndBackoff = async () => {
    authenticatedPingRefreshTime = Math.min(
      AUTHENTICATED_PING_MAX_REFRESH_TIME,
      2 * authenticatedPingRefreshTime
    );
    await authenticatedPing();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(callAuthenticatedPingAndBackoff, authenticatedPingRefreshTime);
  };

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  setTimeout(callAuthenticatedPingAndBackoff, authenticatedPingRefreshTime);
};

const isOnProductionAdminDashHost = () => {
  const hostname = window.location.hostname;
  return hostname.includes('prod') && hostname.includes('.smar.cloud');
};

const isOnDeveloperEnv = () => window.location.hostname.endsWith('smart.ninja');
