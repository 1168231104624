/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languageMapper } from './languageMapper';
import { Resource } from '../types';

// Utility function for initializing a i18n instance
// Accepts a set of language resources, and the i18n object imported from the i18next package
// See the following file for an example on how to use this function: pkg/features/capacity-view/src/components/CapacityApp.tsx
export const initI18Next = (resources: Resource) => {
  // Feature flag for i18n
  // TODO: Remove this after the feature becomes generally available
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const translationsEnabled = window.accountSettings?.modules_enabled.find(
    (m: { name: string; value: string }) =>
      m.name === 'translations' && m.value === '1'
  );

  // If we are in the embedded schedule iframe, we need to get the locale from the URL params.
  // Otherwise, if we are in the RM schedule and the translations module is enabled,
  // we can use the browser's language.
  const getLanguage = () => {
    if (window.IS_EMBEDDED_SCHEDULE) {
      const urlParams = new URLSearchParams(window.location.search);
      const locale = urlParams.get('locale');
      return languageMapper(locale || 'en-US', Object.keys(resources));
    } else if (translationsEnabled) {
      return languageMapper(navigator.language, Object.keys(resources));
    } else {
      return 'en-US';
    }
  };

  // Import i18n into root of a component tree
  if (!i18n.isInitialized) {
    void i18n.use(initReactI18next).init({
      resources,
      fallbackLng: 'en-US',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false,
      },
      lng: getLanguage(),
    });
  } else {
    // If i18n is already initialized, add the new resources to the existing ones
    for (const [key, value] of Object.entries(resources)) {
      i18n.addResourceBundle(key, 'translation', value.translation, true);
    }
  }

  return i18n;
};
