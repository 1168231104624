import { baseURL, buildPayload } from './utils';

const FEDERATED_SESSION_COOKIE_NAMES = ['S3S_F', '__S3S_F'];

export const authenticatedPing = async () => {
  try {
    const federatedLoginToken = getFederatedCookieToken();
    const draftPayload = buildPayload({ method: 'POST' });
    const response = await fetch(
      `${baseURL(
        window.location.hostname
      )}/b/home?formName=webop&formAction=AuthenticatedPing`,
      {
        ...draftPayload,
        headers: {
          'Content-Type': 'application/json',
          'x-smar-alt-xsrf': federatedLoginToken,
        },
        body: '{}',
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data = await response.json();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (data && data.serverStatus !== true) {
      window.trackEvent?.(`rm.sessions.authenticated_ping.auth_failed`);
      await forceReauthenticate();
    }
  } catch (e) {
    window.trackEvent?.(`rm.sessions.authenticated_ping.error`);
    await forceReauthenticate();
  }
};

const getFederatedCookieToken = () => {
  let token = '';
  FEDERATED_SESSION_COOKIE_NAMES.forEach((name) => {
    if (!token) {
      token = getCookie(name);
    }
  });

  return token;
};

export const getCookie = (name: string) =>
  document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

const forceReauthenticate = async () => {
  const signOutUrl = 'api/v1/sessions/signout?authping=true';
  deleteAllCookies();
  window.sessionStorage.clear();
  window.localStorage.clear();
  await fetch(signOutUrl, buildPayload({ method: 'GET' }));
  window.location.replace('/si');
};
