import React, { useEffect } from 'react';
import { validateSession } from '@smartsheet/api.client';
import style from './CheckCSRF.style.scss';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../client/components/Button';

export const CheckCSRF = () => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = React.useState(true);
  useEffect(() => {
    // No more checks when the session is invalid
    if (!isValid) return;

    const handler = () => {
      // handle whenever we switch back to the tab
      if (!document.hidden) {
        validateSession()
          .then(setIsValid)
          .catch(() => setIsValid(false));
      }
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, [isValid]);

  if (isValid) {
    return null;
  }

  return (
    <div className={style.overlay}>
      <div className={style.dialog}>
        <h1>{t('msg_session_expired_title')}</h1>
        <p>{t('msg_session_expired_description')}</p>
        <BaseButton type='success' onClick={() => window.location.reload()}>
          {t('lbl_refresh_page')}
        </BaseButton>
      </div>
    </div>
  );
};
