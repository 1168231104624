{
  "err_activating_sso_auth": "Errore nell'attivazione dell'autenticazione SSO",
  "err_company_name_length": "Il nome della società deve essere inferiore a 255 caratteri.",
  "err_duplicate_value": "Il valore esiste già",
  "err_email_length": "L'e-mail deve essere inferiore a 255 caratteri.",
  "err_failed_retrieve_initial_state_from_local_storage": "Impossibile recuperare lo stato iniziale da localStorage",
  "err_fetching_filters": "Si è verificato un problema nel recupero di queste opzioni di filtro.",
  "err_first_name_contains_url": "Il nome non deve contenere l'URL.",
  "err_first_name_length": "Il nome deve essere inferiore a 255 caratteri.",
  "err_global_modules_invalid_input": "Input non valido.  Assicurati di aver definito il nome del modulo e che almeno un'azione sia selezionata.",
  "err_invalid_email": "Inserisci un indirizzo e-mail valido",
  "err_last_name_contains_url": "Il cognome non deve contenere l'URL.",
  "err_last_name_length": "Il cognome deve essere inferiore a 255 caratteri.",
  "err_no_status_for_status_panel": "Il team non ha definito alcuna opzione di stato. Se desideri iniziare a usare lo stato, un amministratore può aggiungere le opzioni dello stato in Impostazioni account.",
  "err_password_length_40_chars": "Deve essere compresa tra 8 e 40 caratteri.",
  "err_required": "Obbligatorio",
  "err_unable_to_load_filter_list": "Impossibile caricare l'elenco di filtri",
  "err_unknown_error": "Errore sconosciuto",
  "err_updating_smartsheet_auth": "Errore durante l'aggiornamento dell'Autenticazione Smartsheet",
  "err_whoops_something_did_not_work_as_expected": "Oops! Qualcosa non ha funzionato come previsto.",
  "lbl_about_fifteen_seconds": "circa 15 secondi",
  "lbl_about_thirty_seconds": "circa 30 secondi",
  "lbl_accept": "Accetta",
  "lbl_account_switcher": "commutatore di account",
  "lbl_activate": "Attiva",
  "lbl_activated_auth_status": "Attivato (account creato solo con l'e-mail)",
  "lbl_active": "Attivo",
  "lbl_activity": "Attività",
  "lbl_actual_hours": "Ore effettive",
  "lbl_add": "Aggiungi",
  "lbl_add_group_label_filter": "Aggiungi filtro {{groupLabel}}",
  "lbl_assign_to_search_user": "Assegna a '{{searchInput}}'",
  "lbl_add_lc_members": "Aggiungi membri",
  "lbl_add_members": "Aggiungi membri",
  "lbl_add_person": "Aggiungi persona",
  "lbl_add_tasklist": "Aggiungi elenco attività",
  "lbl_add_team_member": "Aggiungi membro del team",
  "lbl_add_update_people": "Aggiungi/aggiorna persone",
  "lbl_add_work_item": "Aggiungi elemento di lavoro",
  "lbl_added": "Aggiunto",
  "lbl_all": "Tutte",
  "lbl_all_project_types": "Tutti i tipi di progetto",
  "lbl_allocation_heatmap": "Mappa di calore delle allocazioni",
  "lbl_allocation_percent": "Percentuale di allocazione",
  "lbl_amounts": "Quantità",
  "lbl_amounts_and_days": "Quantità e giorni",
  "lbl_amounts_and_days_filter_display_name": "Tempo e costi: quantità e giorni",
  "lbl_amounts_and_hours": "Quantità e ore",
  "lbl_apply_changes": "Applica modifiche",
  "lbl_approval_access_id": "ID accesso di approvazione",
  "lbl_approval_status": "Stato di approvazione",
  "lbl_approvals": "Approvazioni",
  "lbl_approvals_card_header_project_phase_client": "Progetto: Fase - Cliente",
  "lbl_approve_and_lock": "Approva e blocca",
  "lbl_approved": "Approvata",
  "lbl_approved_and_locked": "Approvato e bloccato",
  "lbl_approved_by": "Approvata da",
  "lbl_approver": "Addetto all'approvazione",
  "lbl_approver_view": "Vista approvatore",
  "lbl_approvers": "Approvatori",
  "lbl_april": "Aprile",
  "lbl_archive": "Archivia",
  "lbl_archive_projects": "Archivia progetti",
  "lbl_archived": "Archiviato",
  "lbl_archived_label": "archiviato-etichetta",
  "lbl_archived_on_date": "Archiviato il giorno {{archiveDate}}",
  "lbl_archiving": "Archiviazione",
  "lbl_assign": "Assegna",
  "lbl_assign_group_label": "Assegna {{groupLabel}}",
  "lbl_assign_team_member": "Assegna membro del team",
  "lbl_assign_to": "Assegnata a",
  "lbl_assignment": "Assegnazione",
  "lbl_assignment_dates_colon": "Date assegnazione:",
  "lbl_assignment_details": "Dettagli assegnazione",
  "lbl_assignment_name": "Nome dell’assegnazione",
  "lbl_assignment_status": "Stato assegnazione",
  "lbl_audit_log": "Registro di audit",
  "lbl_august": "Agosto",
  "lbl_authentication": "Autenticazione",
  "lbl_author": "Autore",
  "lbl_automatic": "automatico",
  "lbl_automatic_capitalize": "Automatico",
  "lbl_availability": "Disponibilità",
  "lbl_availability_sort_options": "Opzioni di ordinamento disponibilità",
  "lbl_available_hours": "Ore disponibili",
  "lbl_back": "Indietro",
  "lbl_back_to_account_settings": "Torna in Impostazioni account",
  "lbl_back_to_list_of_requests": "Torna all'elenco di richieste",
  "lbl_based_on": "In base a",
  "lbl_bill_rate": "Tariffa di fatturazione",
  "lbl_billable": "Fatturabile",
  "lbl_bracket_lc_none": "[none]",
  "lbl_bracket_leave": "[leave]",
  "lbl_bracket_no_approver_assigned": "[No Approver Assigned]",
  "lbl_bracket_non_phase_specific": "[Non Phase Specific]",
  "lbl_bracket_none": "[None]",
  "lbl_brief": "Riassunto",
  "lbl_budget": "Budget",
  "lbl_budget_amounts_filter_display_name": "Budget: quantità",
  "lbl_budget_days_filter_display_name": "Budget: giorni",
  "lbl_budget_hours_filter_display_name": "Budget: ore",
  "lbl_budget_report": "Report sul budget",
  "lbl_budgets": "Budget",
  "lbl_calculating": "calcolo in corso",
  "lbl_cancel": "Annulla",
  "lbl_cancelled": "annullato",
  "lbl_capacity": "Capacità",
  "lbl_category": "Categoria",
  "lbl_cell_phone": "Telefono cellulare",
  "lbl_certificate_details": "Dettagli certificato",
  "lbl_change_to_unmanaged_confirm": "Questo utente non potrà più monitorare e approvare tempi e spese e non verrà più mostrato nella visualizzazione delle capacità e nei report.",
  "lbl_change_to_managed": "Cambia in Gestito",
  "lbl_change_to_unmanaged": "Passa a Non gestito",
  "lbl_choose_file": "Scegli file",
  "lbl_client": "Cliente",
  "lbl_client_name": "Nome del cliente",
  "lbl_close": "Chiudi",
  "lbl_company_name": "Nome dell'azienda",
  "lbl_complete": "Completo",
  "lbl_confirm": "Conferma",
  "lbl_confirmed": "Confermato",
  "lbl_contact_info": "Informazioni di contatto",
  "lbl_contact_support": "Contatta il team di assistenza",
  "lbl_contractor": "Contractor",
  "lbl_copy": "Copia",
  "lbl_count_of_total": "{{count}} di {{total}}",
  "lbl_create": "Crea",
  "lbl_create_new_request": "* Crea una nuova richiesta",
  "lbl_current": "Attuale",
  "lbl_current_work": "Lavoro attuale",
  "lbl_custom_date_range": "Intervallo di date personalizzato",
  "lbl_custom_field": "Campo personalizzato",
  "lbl_date": "Data",
  "lbl_date_string_at_time_string": "Il giorno {{date}} alle ore {{time}}",
  "lbl_date_string": "Stringa della data",
  "lbl_dates": "Date",
  "lbl_dates_for_submitted_time": "Date relative all'ora dell'invio",
  "lbl_day_friday": "Venerdì ",
  "lbl_day_monday": "Lunedì ",
  "lbl_day_saturday": "Sabato ",
  "lbl_day_sunday": "Domenica ",
  "lbl_day_thursday": "Giovedì ",
  "lbl_day_tuesday": "Martedì ",
  "lbl_day_wednesday": "Mercoledì ",
  "lbl_days": "Giorni",
  "lbl_deactivated": "Disattivato",
  "lbl_december": "Dicembre",
  "lbl_delete": "Elimina",
  "lbl_delete_adv_report_modal": "Eliminare report?",
  "lbl_delete_saved_report_modal": "Eliminare report salvato?",
  "lbl_description": "Descrizione",
  "lbl_difference": "Differenza",
  "lbl_difference_from_past_scheduled": "Differenza rispetto alla pianificazione passata",
  "lbl_disabled": "disattivato",
  "lbl_discipline": "Disciplina",
  "lbl_display_colon": "Mostra:",
  "lbl_done": "Terminato",
  "lbl_duration": "Durata",
  "lbl_duration_working_days": "Durata (giorni lavorativi)",
  "lbl_edit_profile": "Modifica profilo",
  "lbl_edit_project_information": "Modifica informazioni del progetto",
  "lbl_edit_projects": "Modifica progetti",
  "lbl_email": "E-mail",
  "lbl_email_must_be_less_than_255_characters.": "L'e-mail deve essere inferiore a 255 caratteri.",
  "lbl_employee_number": "Numero dipendente",
  "lbl_enabled": "attivato",
  "lbl_end": "Fine",
  "lbl_end_date": "Data di fine",
  "lbl_end_lc_date": "Data di fine",
  "lbl_ends_at": "Termina il",
  "lbl_enter_a_name": "Inserisci un nome",
  "lbl_enter_rejection_reason": "Inserisci motivo del rifiuto",
  "lbl_entry_type": "Tipo di voce",
  "lbl_everyone": "Tutti",
  "lbl_everything": "Tutto",
  "lbl_expense": "Spesa",
  "lbl_expenses": "Spese",
  "lbl_export": "Esporta",
  "lbl_export_people_list": "Esporta elenco di persone",
  "lbl_export_report_to_csv": "Esporta questo report in CSV",
  "lbl_export_time_entries_for_quickBooks": "Esporta voci tempo per QuickBooks",
  "lbl_export_underlying_data_as_csv": "Esporta dati sottostanti come CSV",
  "lbl_failed": "Fallito",
  "lbl_february": "Febbraio",
  "lbl_fees": "Costi",
  "lbl_filter": "Filtro",
  "lbl_filtered": "Filtrato",
  "lbl_filters": "Filtri",
  "lbl_first_day_of_work": "Primo giorno di lavoro",
  "lbl_first_group_by": "Primo gruppo per",
  "lbl_first_name": "Nome",
  "lbl_first_name_lc": "Nome",
  "lbl_friday_abbr_uc": "V",
  "lbl_future": "Futuro",
  "lbl_future_scheduled": "Programmazioni future",
  "lbl_global_modules": "Moduli globali",
  "lbl_grid_view": "Visualizzazione griglia",
  "lbl_hours": "Ore",
  "lbl_hours_filter_display_name": "Tempo e costi: ore",
  "lbl_hours_per_day": "Ore per giorno",
  "lbl_idp_metadata_url": "URL metadati IdP",
  "lbl_iframe_view_content_require": "La visualizzazione di questo contenuto richiede una licenza Resource Management di Smartsheet.",
  "lbl_in_the_office": "In ufficio",
  "lbl_incurred": "Sostenuto",
  "lbl_internal": "Interno",
  "lbl_invited": "Invitato",
  "lbl_january": "Gennaio",
  "lbl_july": "Luglio",
  "lbl_june": "Giugno",
  "lbl_just_now": "Proprio ora",
  "lbl_last_and_next_ninety_days": "Ultimi e prossimi 90 giorni",
  "lbl_last_day_of_work": "Ultimo giorno di lavoro",
  "lbl_last_month": "Mese scorso",
  "lbl_last_name": "Cognome",
  "lbl_last_name_lc": "Cognome",
  "lbl_last_quarter": "Ultimo trimestre",
  "lbl_last_thirty_days": "Ultimi 30 giorni",
  "lbl_last_week": "Settimana scorsa",
  "lbl_learn_lc_more": "Per saperne di più",
  "lbl_learn_more": "Per saperne di più",
  "lbl_leave": "Congedo",
  "lbl_leave_type": "Tipo di congedo",
  "lbl_licensed": "Con licenza",
  "lbl_licensed_users": "Utenti con licenza",
  "lbl_linked_smartsheet_project": "Progetto Smartsheet collegato",
  "lbl_loading": "Caricamento",
  "lbl_loading_ellipsis": "Caricamento in corso...",
  "lbl_loading_project_ellipsis": "Caricamento progetto in corso...",
  "lbl_location": "Posizione",
  "lbl_log_requested": "Registro richiesto",
  "lbl_make_active": "Trasforma in Attivo",
  "lbl_make_licensed_user": "Trasforma in Utente con licenza",
  "lbl_manage_members": "Gestisci membri",
  "lbl_managed_resource": "Risorse gestite",
  "lbl_manual": "manuale",
  "lbl_manual_capitalize": "Manuale",
  "lbl_march": "Marzo",
  "lbl_matching_people": "Persone corrispondenti",
  "lbl_matching_person": "persona corrispondente",
  "lbl_matching_placeholders": "Placeholder corrispondenti",
  "lbl_matching_project": "progetto corrispondente",
  "lbl_matching_projects": "progetti corrispondenti",
  "lbl_may": "Mag",
  "lbl_medium": "Media",
  "lbl_members": "Membri",
  "lbl_mobile": "Dispositivo mobile",
  "lbl_module": "Modulo:",
  "lbl_module_name": "Nome modulo",
  "lbl_monday_abbr_uc": "L",
  "lbl_month": "Mese",
  "lbl_more_assignments": "più assegnazioni",
  "lbl_must_be_between_8–40_characters.": "Deve essere compresa tra 8 e 40 caratteri.",
  "lbl_my_expenses": "Le mie spese",
  "lbl_my_projects": "I miei progetti",
  "lbl_my_time": "Il mio tempo",
  "lbl_my_time_and_expenses": "Il mio tempo e le mie spese",
  "lbl_my_views": "Le mie visualizzazioni",
  "lbl_new_project": "Nuovo progetto",
  "lbl_next_ninety_days": "Prossimi 90 giorni",
  "lbl_next_sixty_days": "Prossimi 60 giorni",
  "lbl_next_thirty_days": "Prossimi 30 giorni",
  "lbl_next_week": "Prossima settimana",
  "lbl_no_assignments": "Nessuna assegnazione",
  "lbl_no_description_provided": "Nessuna descrizione fornita.",
  "lbl_no_imports_found": "Nessuna importazione trovata",
  "lbl_non_billable": "Non fatturabile",
  "lbl_none": "Nessuno",
  "lbl_non_matching": "Non corrispondente",
  "lbl_not_using_smar": "Non utilizza l'Autenticazione Smartsheet",
  "lbl_not_using_sso": "Non utilizza SSO",
  "lbl_not_yet_invited": "Non ancora invitato",
  "lbl_notes": "Note",
  "lbl_november": "Novembre",
  "lbl_october": "Ottobre",
  "lbl_office": "Ufficio",
  "lbl_office_phone": "Telefono ufficio",
  "lbl_office_status": "Stato ufficio",
  "lbl_on_the_road": "In viaggio",
  "lbl_or_select_a_specific_phase": "Oppure seleziona una fase specifica",
  "lbl_or_select_phases": "O seleziona la/e fase/i",
  "lbl_or_select_specific_phases": "Oppure seleziona fasi specifiche",
  "lbl_other_projects": "Altri progetti",
  "lbl_out_of_office": "Fuori ufficio",
  "lbl_out_sick": "In malattia",
  "lbl_past": "Passato",
  "lbl_pending": "In attesa",
  "lbl_pending_approval": "Approvazione in attesa",
  "lbl_people": "Persone",
  "lbl_people_filters": "Filtri persone",
  "lbl_people_scheduler": "Pianificatore persone",
  "lbl_people_tags": "Tag persone",
  "lbl_percentage": "Percentuale",
  "lbl_persistent_navigation": "Navigazione persistente",
  "lbl_person": "Persona",
  "lbl_phase": "Fase",
  "lbl_phase_dates": "Date fase",
  "lbl_phase_dates_colon": "Date fase:",
  "lbl_phase_four": "Fase quattro",
  "lbl_phase_lc_name": "Nome fase",
  "lbl_phase_name": "Nome fase",
  "lbl_phase_name_long_enough_to_wrap": "Nome fase abbastanza lungo da contenere",
  "lbl_phase_one": "Fase uno",
  "lbl_phase_status": "Stato fase",
  "lbl_phase_three": "Fase tre",
  "lbl_phase_two": "Fase due",
  "lbl_placeholder": "Placeholder",
  "lbl_placeholders": "Placeholder",
  "lbl_please_sign_in": "Accedi per continuare",
  "lbl_plus_allocation": "+ Allocazione",
  "lbl_portfolio_editor": "Editor del portafoglio",
  "lbl_portfolio_reporter": "Reporter del portafoglio",
  "lbl_portfolio_viewer": "Visualizzatore del portafoglio",
  "lbl_post": "Pubblica",
  "lbl_project": "Progetto",
  "lbl_project_access": "Accesso al progetto",
  "lbl_project_code": "Codice progetto",
  "lbl_project_dates": "Date progetto",
  "lbl_project_dates_colon": "Date progetto:",
  "lbl_project_description": "Descrizione progetto",
  "lbl_project_duration": "Durata progetto",
  "lbl_project_editor": "Editor progetto",
  "lbl_project_filters": "Filtri progetto",
  "lbl_project_members": "Membri progetto",
  "lbl_project_name": "Nome progetto",
  "lbl_project_owner": "Proprietario del progetto",
  "lbl_project_owners": "Proprietari del progetto",
  "lbl_project_portfolio": "Portafoglio di progetti",
  "lbl_project_properties": "Proprietà del progetto",
  "lbl_project_settings": "Impostazioni di progetto",
  "lbl_project_state": "Stato progetto",
  "lbl_project_tags": "Tag di progetto",
  "lbl_project_title": "Titolo progetto",
  "lbl_project_type": "Tipo di progetto",
  "lbl_projects": "Progetti",
  "lbl_purchase_more": "acquista altro",
  "lbl_recents": "Recenti",
  "lbl_recent_requests": "Richieste recenti",
  "lbl_refresh_status": "Stato aggiornamento",
  "lbl_refresh_type": "Tipo di aggiornamento",
  "lbl_refreshing_data": "Aggiornamento dei dati...",
  "lbl_reject": "Rifiuta",
  "lbl_rejected": "Rifiutato",
  "lbl_remaining": "Rimanente",
  "lbl_remove": "Elimina",
  "lbl_report_data_refresh_status_error": "Aggiornamento dati non riuscito il giorno {{date}} alle ore {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "Si è verificato un errore durante la creazione del report avanzato. Riprova. Se il problema persiste, contatta il team di assistenza.",
  "lbl_report_data_refresh_status_loading": "Aggiornamento del report avanzato",
  "lbl_report_data_refresh_status_success": "Ultimo aggiornamento il giorno {{date}} alle ore {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "Le modifiche verranno sincronizzate con il successivo aggiornamento dei dati",
  "lbl_report_data_refresh_status_warning_manual": "Aggiorna report avanzato per sincronizzare le modifiche",
  "lbl_report_name_colon": "Nome report:",
  "lbl_report_title_by_criteria": "{{report}} per {{criteria}}",
  "lbl_reports": "Report",
  "lbl_request_an_audit_log": "Richiedi un registro di audit",
  "lbl_request_audit_log": "Richiedi registro di audit",
  "lbl_requester": "Richiedente",
  "lbl_resend_invitation": "Reinvia l'invito",
  "lbl_reset": "Reimposta",
  "lbl_reset_filters": "Reimposta filtri",
  "lbl_reset_password": "Reimposta password",
  "lbl_reset_password_for": "Reimposta password per",
  "lbl_reset_report": "Reimposta report",
  "lbl_resource-request_test_page": "Pagina test richieste risorsa",
  "lbl_resource_management_by_smartsheet": "Resource Management di Smartsheet",
  "lbl_resourcing_admin": "Amministratore risorse",
  "lbl_retry": "Riprova",
  "lbl_role": "Ruolo",
  "lbl_saturday_abbr_uc": "S",
  "lbl_save": "Salva",
  "lbl_save_changes": "Salva le modifiche",
  "lbl_save_current_view": "Salva vista corrente",
  "lbl_save_this_report_to_your_personal_page": "Salva questo report nella pagina personale",
  "lbl_saving": "Salvataggio in corso",
  "lbl_schedule": "Programmazione",
  "lbl_scheduled": "Programmato",
  "lbl_search": "Cerca",
  "lbl_search_ellipsis": "Cerca...",
  "lbl_select_date_range_to_see_entries": "Seleziona un intervallo di date per vedere tutti gli inserimenti in quell'intervallo di tempo",
  "lbl_select_people_to_add_to_project": "Seleziona le persone che desideri aggiungere a questo progetto.",
  "lbl_select_phases": "Seleziona fase/i",
  "lbl_select_project": "Seleziona progetto",
  "lbl_select_project_or_phase": "Seleziona progetto o fase",
  "lbl_select_project_owner": "Seleziona proprietario progetto",
  "lbl_select_team_member": "Seleziona membro del team:",
  "lbl_select_the_entire_project": "Seleziona l'intero progetto",
  "lbl_send": "Invia",
  "lbl_send_invitation": "Invia l'invito",
  "lbl_september": "Settembre",
  "lbl_set_a_status": "Imposta uno stato",
  "lbl_set_allocation": "Imposta assegnazione:",
  "lbl_set_to_today": "Imposta su data odierna",
  "lbl_share": "Condividi",
  "lbl_shared_views": "Viste condivise",
  "lbl_show": "Mostra",
  "lbl_show_no_phases": "Mostra: nessuna fase",
  "lbl_sign_out_slo_target_url": "URL di destinazione di Esci (SLO)",
  "lbl_signin_sso_target_url": "URL di destinazione di Accedi (SSO)",
  "lbl_signup_password": "Password (+ di 8 caratteri)",
  "lbl_skipped": "Saltato",
  "lbl_skipped_colon": "Saltati: {{number}}",
  "lbl_small": "Piccolo",
  "lbl_something_went_wrong_ellipsis": "Si è verificato un problema.",
  "lbl_sort_by": "Ordina per",
  "lbl_sso_for_resource_management": "SSO per Resource Management",
  "lbl_start": "Inizio",
  "lbl_start_date": "Data di inizio",
  "lbl_start_lc_date": "Data di inizio",
  "lbl_starts_at": "Inizia alle",
  "lbl_status": "Stato",
  "lbl_status_cancelled": "ANNULLATO",
  "lbl_status_failed": "FALLITO",
  "lbl_status_message": "Messaggio di stato",
  "lbl_status_queued": "IN CODA",
  "lbl_status_running": "IN ESECUZIONE",
  "lbl_status_succeeded": "COMPLETATO",
  "lbl_submitted_time_for_approval": "Tempo inviato per approvazione",
  "lbl_sunday_abbr_uc": "D",
  "lbl_switch_to_another_account": "Passa a un altro account:",
  "lbl_tag": "Tag",
  "lbl_tags": "Tag",
  "lbl_target_utilization": "Utilizzo target",
  "lbl_tasks": "Attività",
  "lbl_team_member": "Membro del team",
  "lbl_team_member_type": "Tipo di membro del team",
  "lbl_tentative": "Provvisorio",
  "lbl_terms": "termini",
  "lbl_the_selected_project_was": "Il progetto selezionato era ",
  "lbl_then_group_by": "Quindi raggruppa per",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "Questi risultati includono solo i progetti che sei autorizzato a visualizzare.",
  "lbl_this_month": "Questo mese",
  "lbl_this_quarter": "Questo trimestre",
  "lbl_this_week": "Questa settimana",
  "lbl_this_year": "Questo anno",
  "lbl_thursday_abbr_uc": "G",
  "lbl_time": "Tempo",
  "lbl_time_and_expenses": "Tempo e spese",
  "lbl_time_and_fee_categories": "Categorie Tempo e costi",
  "lbl_time_and_fees": "Tempo e costi",
  "lbl_time_and_fees_report": "Report Tempo e costi",
  "lbl_time_approval_dashboard": "Dashboard di approvazione tempo",
  "lbl_time_budget_unit_of_measurement": "Unità di misura Budget di tempo",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "Tempo e costi: quantità e ore",
  "lbl_time_fees_amounts_display_name": "Tempo e costi: quantità",
  "lbl_time_fees_days_filter_display_name": "Tempo e costi: giorni",
  "lbl_time_frame": "Intervallo di tempo",
  "lbl_time_tracking": "Tracciamento dei tempi",
  "lbl_today": "Oggi",
  "lbl_tomorrow": "Domani",
  "lbl_total": "Totale",
  "lbl_tuesday_abbr_uc": "M",
  "lbl_type": "Tipo",
  "lbl_unarchive": "Annullamento archiviazione",
  "lbl_unarchive_project": "Annulla archiviazione progetto",
  "lbl_unassigned": "Non assegnato",
  "lbl_unconfirmed": "Non confermato",
  "lbl_unmanaged": "Non gestito",
  "lbl_unmanaged_resource": "Risorsa non gestita",
  "lbl_unmanaged_resources": "Risorse non gestite",
  "lbl_unlock": "Sblocca",
  "lbl_upcoming": "In scadenza",
  "lbl_update": "Aggiorna",
  "lbl_update_status": "Aggiorna stato: ",
  "lbl_updated": "Aggiornato",
  "lbl_updating": "Aggiornamento in corso",
  "lbl_utilization": "Utilizzo",
  "lbl_utilization_target": "Target di utilizzo",
  "lbl_vacation": "Ferie",
  "lbl_values": "Valori",
  "lbl_view": "Visualizza",
  "lbl_view_resourcing_schedule": "Visualizza Programmazione delle risorse",
  "lbl_wednesday_abbr_uc": "M",
  "lbl_week": "Settimana",
  "lbl_work": "Lavoro",
  "lbl_work_email": "E-mail di lavoro",
  "lbl_work_item": "Elemento di lavoro",
  "lbl_work_status": "Stato del lavoro",
  "lbl_working_from_home": "Lavoro da casa",
  "lbl_worklist": "Elenco di lavoro",
  "lbl_your_projects_includes_the_following_properties": "I tuoi progetti: include le seguenti proprietà",
  "msg_account_owner_change_attempt": "Cambia il proprietario dell'account in Impostazioni account prima di archiviare questo utente.",
  "msg_activated_smartsheet_auth": "Verrà attivata l'Autenticazione Smartsheet. Questo metodo di autenticazione ha effetto immediato e non può essere disattivato",
  "msg_add_assignment_to_project": "Aggiungi assegnazione al progetto",
  "msg_add_notes_placeholder": "Aggiungi note, dettagli, link, ecc.",
  "msg_add_phase_to_project": "Aggiungi fase al progetto",
  "msg_add_project_description": "Aggiungi descrizione del progetto",
  "msg_add_save_current_view": "Aggiungi Salva vista corrente",
  "msg_add_team_member_to_project": "Aggiungi membro del team al progetto",
  "msg_app_project_members_disabled_tooltip": "Sei il proprietario di questi progetti o sono assegnati a te.",
  "msg_app_project_members_enabled_tooltip": "Sei il proprietario di questi progetti, sei un membro o sono assegnati a te.",
  "msg_audit_log_info": "Importante: un registro di audit fornisce una panoramica accurata del momento esatto in cui viene richiesto e può rivelarsi obsoleto se vengono apportate modifiche in termini di tempi o di spese dopo la creazione del registro richiesto. Per informazioni più aggiornate, richiedi un nuovo registro qui sopra.",
  "msg_auto_provision_checkbox": "Quando il Provisioning automatico dell'utente è attivo, assegna automaticamente le licenze e il livello di autorizzazione selezionato agli utenti. In questo modo gli utenti possono anche evitare la procedura di invito via e-mail.",
  "msg_bracket_search_to_add_filters": "[Search to add filters]",
  "msg_cannot_archive_account_owner": "Non puoi archiviare il proprietario dell'account",
  "msg_cert_requirement": "Utilizza un file codificato PEM con estensione CER, CRT o PEM.",
  "msg_cert_upload_complete": "Caricamento del certificato completato",
  "msg_checkbox_list_aria": "Seleziona/deseleziona il segno di spunta per selezioni multiple o fai clic su un pulsante di selezione per sceglierne solo una",
  "msg_choose_permission_type": "Scegli il tipo di autorizzazione utente",
  "msg_choose_property_to_sort_by": "Scegli la proprietà su cui eseguire l'ordinamento.",
  "msg_close": "Chiudi",
  "msg_close_and_redirect_to_profile_page": "Chiudi e ritorna alla pagina del profilo",
  "msg_count_matches_found": "{{count}} corrispondenze trovate",
  "msg_copy_url_to_share_report": "Copia l'URL seguente per condividere questo report:",
  "msg_customize_report_prompt": "Personalizza il report con le opzioni a sinistra. Quando sei pronto, scegli una delle seguenti opzioni.",
  "msg_delete_adv_report_modal": "Questa azione interromperà l’aggiornamento dei dati del report, ma non eliminerà il report sottostante e il foglio di origine nel workspace di destinazione.",
  "msg_delete_assignment": "Elimina assegnazione",
  "msg_delete_saved_report": "Elimina report salvato",
  "msg_delete_saved_report_modal": "Questa azione non può essere annullata, ma è possibile creare un nuovo report con le stesse opzioni.",
  "msg_delete_view": "Elimina visualizzazione",
  "msg_delete_work_item_warning": "Eliminare in modo permanente questo elemento di lavoro? Tutte le attività e le assegnazioni associate verranno rimosse. Questa operazione non può essere annullata.",
  "msg_edit_allocation": "Modifica allocazione",
  "msg_edit_end_date": "Modifica data di fine",
  "msg_edit_start_date": "Modifica data di inizio",
  "msg_edit_status": "Modifica stato",
  "msg_edit_team_member": "Modifica membro del team",
  "msg_edit_work_item": "Modifica elemento di lavoro",
  "msg_enable_bulk_editing_requirements": "Per abilitare la modifica collettiva, verifica che il filtro Proprietario del progetto sia impostato su di te e che il filtro Stato progetto sia impostato su Attivo.",
  "msg_enable_module_for_existing_organizations": "Attiva il modulo per le organizzazioni esistenti",
  "msg_enable_module_for_new_organizations": "Attiva il modulo per le nuove organizzazioni",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "Questo filtro è bloccato per i progetti su cui possiedi autorizzazioni di report.",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "Questo filtro è bloccato per i progetti di tua proprietà o a te assegnati.",
  "msg_grid_view_aria": "Visualizzazione griglia: attiva per scegliere la visualizzazione griglia.",
  "msg_hold_on_loading_ellipsis": "Attendi il caricamento...",
  "msg_infinite_loader_aria_label": "Seleziona/deseleziona il segno di spunta per selezioni multiple o fai clic su un pulsante di selezione per sceglierne solo una",
  "msg_invalid_cert": "Spiacenti, il certificato selezionato non è un tipo di file valido.",
  "msg_list_view_and_project_field_selector": "Visualizzazione elenco e selettore campi progetto: attiva per scegliere la visualizzazione elenco e seleziona i campi del progetto che saranno disponibili.",
  "msg_loading_approval_status": "Caricamento stato di approvazione in corso…",
  "msg_loading_page": "Caricamento pagina",
  "msg_loading_project_timeline_ellipsis": "Caricamento timeline del progetto in corso...",
  "msg_loading_project_users_ellipsis": "Caricamento utenti del progetto in corso...",
  "msg_make_bulk_project_archive_warning": "L'archiviazione di questi progetti sta per essere completata e le assegnazioni future verranno rimosse. Questa operazione non può essere annullata.",
  "msg_make_bulk_project_edit_confirmation": "Apportare questa modifica?",
  "msg_make_bulk_project_edit_warning": "L'operazione potrebbe richiedere alcuni minuti e non può essere annullata.",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "Gestisci i membri e i livelli di accesso del progetto. I livelli di accesso sono esclusivi di questo progetto. Alcune persone possono apportare modifiche senza essere membri in base alle loro <1>autorizzazioni sull'account</1>.",
  "msg_need_a_minute_ellipsis": "Oops! Solo un attimo...",
  "msg_new_work_item_title": "Titolo nuovo elemento di lavoro",
  "msg_no_assignments": "Nessuna assegnazione",
  "msg_no_matches_found": "Nessuna corrispondenza trovata",
  "msg_no_people_assigned_to_project": "Nessuna persona assegnata a questo progetto.",
  "msg_no_phases_to_display": "Nessuna fase da visualizzare",
  "msg_no_projects_match_your_criteria": "Nessun progetto corrispondente ai criteri selezionati",
  "msg_no_propertyies_to_display": "Nessuna proprietà da visualizzare",
  "msg_no_recent_requests": "Nessuna richiesta recente",
  "msg_no_results_to_display": "Nessun risultato da visualizzare",
  "msg_no_work_for_this_project": "Nessun lavoro corrente per questo progetto.",
  "msg_not_available": "non disponibile",
  "msg_one_match_found": "1 corrispondenza trovata",
  "msg_owners_of_projects_permission_to_report_on": "I proprietari dei progetti su cui possiedi autorizzazioni di report.",
  "msg_people_field_selector": "Selettore campi persone: attiva per selezionare i campi dati che saranno disponibili.",
  "msg_people_settings_info": "Gli utenti con licenza possono tenere traccia delle ore e delle attività svolte su assegnazioni pianificate e possono essere eventualmente invitati a effettuare il login. Le risorse gestite possono essere programmate sui progetti, ma non possono tracciare le ore e non hanno accesso al login.",
  "msg_people_list_licensed_tooltip": "In base alle autorizzazioni, gli utenti con licenza possono monitorare e approvare il tempo, pianificare progetti, gestire il personale, modificare i profili utente e generare report.",
  "msg_people_list_managed_tooltip": "Esegui l’upgrade di Risorse gestite per le funzionalità di monitoraggio del tempo.",
  "msg_people_list_unmanaged_tooltip": "Esegui l’upgrade di Risorse non gestite per includerle nella visualizzazione delle capacità e nei report.",
  "msg_people_list_archived_tooltip": "Le assegnazioni per gli utenti archiviati rimangono nella pagina del progetto per essere riassegnate a un’altra persona o a un placeholder.",
  "msg_phase_picker_copy": "Copia in progetto o fase",
  "msg_print_brief_page": "Stampa pagina del riassunto",
  "msg_profile_banner": "Banner profilo",
  "msg_profile_information": "Informazioni sul profilo",
  "msg_profile_navigation": "Navigazione nel profilo",
  "msg_profile_navigation_menu": "Menu di navigazione nel profilo",
  "msg_project_members_tooltip": "Sei il proprietario di questi progetti, sei un membro o sono assegnati a te.",
  "msg_project_navigation": "Navigazione nel progetto",
  "msg_project_members_description": "L'appartenenza al progetto è esclusiva a questo progetto. Alcuni utenti possono accedere a questo progetto senza essere membri in base alle <0>autorizzazioni del loro account</0>.",
  "msg_reassign_search_placeholder": "Cerca nome, disciplina, ecc.",
  "msg_refine_search_to_see_more_users": "Affina la ricerca per vedere più utenti corrispondenti",
  "msg_rejection_reason_required": "Per il rifiuto è necessaria una motivazione.",
  "msg_remove_archived_project_state_to_enable_editing": "Rimuovi \"Archiviato\" dal filtro Stato progetto per abilitare la modifica.",
  "msg_remove_assignment_confirmation": "Rimuovere questa assegnazione?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "Non è stato possibile aggiornare i dati del tuo report perché contiene 3.000 celle in più rispetto al limite. Riduci le dimensioni del report modificando le opzioni nel pannello di sinistra. <1>Per saperne di più.</1>",
  "msg_report_data_refresh_err_detail_generic": "Si è verificato un errore durante l’aggiornamento dei dati del report avanzato. Crea un nuovo report avanzato.",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "Non è stato possibile aggiornare i dati del tuo report perché contiene 300 righe in più rispetto al limite. Riduci le dimensioni del report modificando le opzioni nel pannello di sinistra. <1>Per saperne di più.</1>",
  "msg_report_data_refresh_err_detail_sheet_access": "Si è verificato un errore durante l’aggiornamento dei dati del report avanzato. Aggiorna le autorizzazioni del foglio o crea un nuovo report avanzato.",
  "msg_report_is_too_big_to_load": "Spiacenti, il report è troppo grande per essere caricato",
  "msg_save_report_analytics_page": "Salva questo report nella pagina delle analisi",
  "msg_search_contacts": "Ricerca contatti",
  "msg_settings_people_manage_info": "Gestisci l'<1>accesso</1> e le <3>autorizzazioni</3> per gli utenti nel tuo account.<br /> <5>Contattaci qui </5>per modificare l'abbonamento.",
  "msg_share_report": "Copia l'URL con l'apposito pulsante riportato di seguito per condividere questo report:",
  "msg_sheet_collaborators": "I collaboratori del foglio sui progetti collegati non vengono mostrati qui. Per aggiornare la condivisione del foglio o aggiungere collaboratori al foglio, gestiscili in Smartsheet. <0>Per saperne di più</0>",
  "msg_signup_loading_transition": "Stiamo configurando il tuo account di prova gratuito.",
  "msg_sort_by_last_name": "Ordina per: cognome",
  "msg_specify_budget_in_unit": "Specifica questo budget in {{curUnit}}",
  "msg_sso_configuration_info": "La configurazione automatica consigliata semplifica la configurazione SSO e supporta più certificati per impostazione predefinita. La configurazione manuale supporta solo un certificato alla volta.",
  "msg_sso_enabled": "SSO è attualmente attivo per il tuo account",
  "msg_sso_info": "L'utilizzo del Single Sign-On (SSO) per la gestione delle password e il provisioning degli utenti consente un maggiore controllo aziendale sull'accesso a Resource Management, garantendo che le persone giuste accedano al servizio in modo sicuro.",
  "msg_sso_login_failure": "Spiacenti, non è stato possibile accedere a Resource Management di Smartsheet.",
  "msg_sso_not_configured": "Non è stato configurato l'SSO per il tuo account",
  "msg_sso_unconfigurable": "L'SSO non può essere configurato direttamente in Resource Management perché l'organizzazione sta attualmente utilizzando l'Autenticazione Smartsheet. Le impostazioni SSO possono essere gestite nel Centro dell'Amministratore Smartsheet.",
  "msg_terms": "Termini",
  "msg_thanks": "Grazie!",
  "msg_there_are_no_approvals_matching_your_current_filters": "Nessuna approvazione corrispondente ai tuoi attuali filtri.",
  "msg_to_inactive_project": "a un progetto inattivo",
  "msg_transfer_project_ownership_to_remove_user": "{{member.name}} è il proprietario del progetto e non può essere rimosso. Trasferisci la proprietà nella scheda Impostazioni progetto.",
  "msg_type_and_press_enter_to_add_task": "Digita l'attività e premi Invio per aggiungerla",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} è una Risorsa non gestita. Aggiornala nelle impostazioni dell'account per modificarla o rimuoverla da questo progetto.",
  "msg_cant_add_unmanaged_resource": "{{member.name}} è una Risorsa non gestita. Aggiornala nelle impostazioni dell'account per aggiungerla a questo progetto.",
  "msg_upload_failed": "Caricamento non riuscito",
  "msg_upload_succeeded": "Caricamento riuscito",
  "msg_user_default_login_tooltip": "Questa persona non ha ancora eseguito l'accesso con SSO. Per cambiare modalità di accesso, chiedile di uscire e riaccedere tramite SSO.",
  "msg_user_smar_login_tooltip": "Questa persona non ha ancora eseguito l'accesso con l'Autenticazione Smartsheet. Per cambiare modalità di accesso, chiedile di uscire e riaccedere tramite l'Autenticazione Smartsheet",
  "msg_wait_while_load_assignments": "Ti preghiamo di attendere il caricamento delle assegnazioni",
  "msg_welcome_to_resource_management_by_smartsheet": "Ti diamo il benvenuto in Resource Management di Smartsheet",
  "msg_work_item_description_placeholder": "Descrivi il lavoro, l'assegnazione, ecc.",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "Non sei il proprietario di questi progetti e non sono assegnati a te.",
  "msg_saved_form_already_exists": "esiste già. Sostituire?",
  "msg_you_do_not_have_admin_access": "Assicurati di disporre dell’autorizzazione di amministratore sul workspace che stai cercando. Puoi richiedere l’accesso o creare un nuovo workspace di destinazione in <0>Smartsheet</0>.",
  "msg_no_matching_results": "Nessun risultato corrispondente",
  "msg_report_filter_list": "Sei sull'elenco dei filtri del report. Scegli quali dati includere nel tuo report.",
  "msg_report_filter_show_group": "Sei sul gruppo di visualizzazione dei filtri del report. Scegli quali opzioni in ogni categoria visualizzare selezionando tutti o nessuno, selezionando/deselezionando le caselle di spunta per scelte multiple o attivando un pulsante di unione per selezionarne solo una.",
  "lbl_getting_information": "Recupero delle informazioni",
  "lbl_actions": "Azioni",
  "lbl_delete_phase": "Elimina fase",
  "lbl_chars_left": "rimasti",
  "lbl_set_to": "Imposta su",
  "msg_no_ppl_match": "Nessuna persona corrispondente ai criteri selezionati",
  "lbl_user_info_summary": "Riepilogo informazioni utente",
  "lbl_saved_reports": "Report salvati",
  "lbl_no_active_assignments": "Nessuna assegnazione attiva.",
  "msg_entries_not_shown": "Gli inserimenti di progetti senza proprietario non verranno visualizzati",
  "msg_provide_note": "Inserisci una nota su questa approvazione. Si tratta di un passaggio obbligatorio per il rifiuto",
  "msg_taking_longer_than_expected": "Il processo richiede più tempo del normale… Solo un momento",
  "msg_want_to_make_adjustments": "Desideri apportare modifiche o accelerare le cose? Puoi continuare a usare i filtri per affinare o ridurre l'ambito del report.",
  "msg_calculating_time_entries": "Calcolo di ~{{timeEntriesCount}} inserimenti di tempo.",
  "lbl_just_a_few_seconds": "solo qualche secondo",
  "lbl_less_than_a_min": "meno di un minuto",
  "lbl_about_a_min": "circa un minuto",
  "msg_add_users_intro": "Usa questa pagina per aggiungere persone nuove o aggiornare quelle esistenti nel tuo account.",
  "msg_add_projects_intro": "Usa questa pagina per aggiungere progetti nuovi o aggiornare quelli esistenti nel tuo account.",
  "msg_more_details_can_be_found": "Per ulteriori dettagli, consulta il nostro <0>argomento della guida</0>. Domande? <1>Contatta</1> il nostro team di assistenza.",
  "lbl_step_1_prepare_your_data": "1. Prepara i tuoi dati",
  "lbl_step_1_content_users": "Nota: non modificare la colonna ID utente perché viene usata per associare gli aggiornamenti alle persone esistenti.",
  "lbl_step_1_content_projects": "Nota: non modificare la colonna ID progetto perché viene usata per associare gli aggiornamenti ai progetti esistenti.",
  "lbl_step_1_contents_create_users": "Usa questo modello per aggiungere nuove persone al tuo account.",
  "lbl_step_1_contents_create_projects": "Usa questo modello per aggiungere nuovi progetti al tuo account.",
  "lbl_step_1_contents_edit_users": "Esporta un elenco di tutte le persone nella tua organizzazione per aggiornarne gli attributi.",
  "lbl_step_1_contents_edit_projects": "Esporta un elenco di tutti i progetti nella tua organizzazione per aggiornarne gli attributi.",
  "lbl_people_lc": "persone",
  "lbl_projects_lc": "progetti",
  "lbl_add_update_projects": "Aggiungi/Aggiorna progetti",
  "lbl_download_template": "Scarica modello",
  "lbl_update_existing": "Aggiorna esistenti",
  "lbl_export_project_list": "Esporta elenco progetti",
  "lbl_step_2_upload_data_users": "2. Carica l'elenco delle persone aggiornato",
  "lbl_step_2_upload_data_projects": "2. Carica l'elenco dei progetti aggiornato",
  "msg_select_csv_file": "Seleziona e carica il file CSV aggiornato per aggiungere o aggiornare {{importType}}. Completata l'operazione, verrà inviata una conferma via e-mail.",
  "lbl_send_invitation_new_licensed_users": "Invia un invito a nuovi utenti con licenza.",
  "lbl_upload": "Carica",
  "lbl_processing": "Elaborazione in corso...",
  "lbl_add_new": "Aggiungi nuovo {{importType}}",
  "msg_import_in_progress": "Importazione in corso",
  "msg_errors_during_import": "Si sono verificati degli errori durante l'importazione",
  "lbl_import_complete": "Importazione completata",
  "lbl_added_colon": "Aggiunti: {{number}}",
  "lbl_updated_colon": "Aggiornati: {{number}}",
  "lbl_failed_colon": "Non riusciti: {{number}}",
  "lbl_download_error_report_csv": "Scarica CSV del report errori",
  "msg_working_on_import_background": "Stiamo lavorando a questa importazione in background. Completata l'importazione, riceverai un’e-mail. Puoi trovare un link a questa pagina in fondo alla pagina <0>{{pageTitle}}</0>.",
  "msg_rows_with_errors": "Le righe con errori non sono state aggiunte o aggiornate. Puoi scaricare un report CSV con questi errori per correggerli e riprovare. Potrebbero essere presenti altri errori; verifica tutti i dati prima di ricaricarli. <0>Scopri di più</0> sull'importazione e l'aggiornamento di {{importType}}.",
  "msg_completed_processing": "Abbiamo completato l'elaborazione di {{importType}} che hai aggiunto di recente e aggiornato nella tua organizzazione.",
  "lbl_imports_last_30_days": "Importazioni degli ultimi 30 giorni",
  "lbl_characters": "caratteri ",
  "lbl_character": "carattere ",
  "lbl_remaining_lc": "rimanente",
  "msg_no_items_match_criteria": "Nessuna voce corrispondente ai criteri selezionati",
  "lbl_future_scheduled_lc": "programmazioni future",
  "lbl_loading_details": "Caricamento dettagli",
  "lbl_try_again": "Riprova",
  "err_must_not_be_blank": "Non può essere vuoto",
  "err_cannot_be_more_than_255_char": "Non può superare i 255 caratteri",
  "lbl_view_name": "Nome visualizzazione",
  "lbl_project_timelines": "Timeline progetto",
  "msg_shorten_time_frame": "Accorcia l'intervallo di tempo o riduci il filtro a un numero minore di persone o progetti, quindi prova a ricaricare il report. Oppure richiedi un'e-mail con un link a un file CSV di questo report.",
  "msg_underlying_data_requested": "Dati sottostanti richiesti. Una volta pronto per il download ti invieremo un'e-mail. Questa operazione potrebbe richiedere alcuni minuti.",
  "msg_no_report_values": "Nessun valore per il report indicato.",
  "msg_report_only_includes_data": "Questo report include solo i dati che sei autorizzato a visualizzare.",
  "msg_we_have_logged_error": "Abbiamo registrato l'errore e indagheremo. Nel frattempo, prova a ricominciare da capo:",
  "msg_budgets_report_includes_all": "Questo report include tutti i dati di budget e delle programmazioni sostenute e future per l'intera durata dei progetti che hanno date che si sovrappongono",
  "lbl_run_report": "Esegui report",
  "lbl_request_underlying_data": "Richiedi dati sottostanti",
  "lbl_selected": "Selezionato",
  "lbl_selected_lc": "selezionati",
  "lbl_project_lc": "progetto",
  "msg_change_client_name": "Questa operazione modificherà il cliente per",
  "msg_change_project_type": "Questa operazione modificherà il tipo del progetto per",
  "msg_change_project_owner": "Questa operazione modificherà il proprietario del progetto per",
  "msg_change_tags": "Questa operazione modificherà i tag per",
  "msg_change_custom_fields": "Questa operazione modificherà i campi personalizzati per",
  "msg_this_will_archive_the": "Questo archivierà",
  "msg_this_will_add_selected_tags": "Questo aggiungerà i tag selezionati",
  "msg_this_will_remove_selected_tags": "Questo rimuoverà i tag selezionati",
  "msg_updating_client": "Aggiornamento cliente per",
  "msg_updating_project_owner": "Aggiornamento proprietario del progetto per",
  "msg_describe_work_item_placeholder": "Descrivi il lavoro, il risultato o il deliverable",
  "msg_show_hide_phase_items": "Mostra/nascondi elementi di lavoro della fase {{phaseName}}",
  "msg_edit_phase": "Modifica fase {{phaseName}}",
  "msg_show_hide_project_items": "Mostra/nascondi elementi di lavoro del progetto {{projectName}}",
  "msg_edit_project": "Modifica progetto {{projectName}}",
  "lbl_hours_lc": "ore",
  "lbl_hours_per_day_abbr": "h/g",
  "lbl_new": "Nuovo",
  "lbl_add_team_member_to": "Aggiungi membro del team a {{parentLabel}}",
  "lbl_add_work_item_to": "Aggiungi elemento di lavoro a {{parentLabel}}",
  "lbl_available_hours_lc": "ore disponibili",
  "lbl_remaining_or_budget_forecast": "rimanenti o {{budgetForecastPercent}}",
  "lbl_forecasted_over_or_under_budget": "previsti {{overUnder}} budget o {{budgetForecastPercent}}",
  "lbl_under_lc": "sotto",
  "lbl_over_lc": "oltre",
  "lbl_expense_budget_lc": "budget di spesa",
  "lbl_project_budget_lc": "budget progetto",
  "lbl_expensed_lc": "speso",
  "lbl_incurred_lc": "sostenuto",
  "lbl_add_description": "Aggiungi descrizione",
  "lbl_automatic_configuration": "Configurazione automatica",
  "lbl_recommended_paren": "(Consigliato)",
  "lbl_manual_configuration": "Configurazione manuale",
  "lbl_manage_sheet_sharing": "Gestire la condivisione del foglio",
  "lbl_cert_must_be_PEM": "Il certificato deve essere codificato PEM (ad esempio .pem, .cert, .crt, .cer).",
  "msg_auto_provision_auth_users": "Utenti autenticati tramite provisioning automatico non presenti nell'account (creato solo con l'e-mail)",
  "lbl_selected_phases": "Usa le fasi selezionate",
  "lbl_no_display_props": "Nessuna proprietà da visualizzare",
  "msg_approval_audit_error": "Spiacenti, si è verificato un imprevisto. Se il problema persiste, contatta il team di assistenza con i dettagli in ",
  "msg_download_error": "Si è verificato un errore durante un tentativo di download del file: ",
  "lbl_audit_log_req": "Registro di audit richiesto",
  "msg_audit_log_req": "Nella tabella delle richieste recenti di seguito verrà visualizzato un link per il download. Questa operazione può richiedere alcuni minuti.",
  "lbl_audit_log_expense": "Registri di audit delle spese",
  "lbl_download": "Scarica",
  "lbl_proj_singular": "progetto era",
  "lbl_proj_plural": "progetti erano",
  "lbl_zero_proj": "0 progetti",
  "lbl_success_lc": "riuscito",
  "lbl_important": "<1>Importante:</1>",
  "lbl_edit_properties": "Modifica proprietà",
  "lbl_error": "Errore",
  "lbl_change": "Modifica",
  "lbl_select_new": "Seleziona nuovo",
  "lbl_approver_project": "Approvatore: ",
  "lbl_successfully": " con successo.",
  "msg_update": "Aggiornamento completo",
  "lbl_of": "di",
  "msg_update_failed": "Aggiornamenti non riusciti.",
  "msg_update_lc": "aggiornati con successo.",
  "lbl_edit_desc": "Modifica descrizione",
  "lbl_no_select": "nessun elemento selezionato",
  "lbl_current_filter_select": " : attualmente impostato su ",
  "msg_getting_ready": "In preparazione...",
  "msg_retry_update": "Clicca su Riprova per tentare di correggere gli aggiornamenti non riusciti.",
  "msg_error_support": "Se continui a visualizzare l'errore, contatta il team di assistenza. ",
  "lbl_curr_dropdown_val": "Il valore di selezione a discesa attuale è ",
  "msg_cancel_view": "Annulla e torna alla visualizzazione dell'elenco",
  "msg_updating_tags": "Aggiornamento tag per",
  "msg_updatings_proj_type": "Aggiornamento tipo di progetto per",
  "msg_start_your_trial_for_free": "Inizia la prova gratuita di {{trialDuration}} giorni",
  "msg_create_free_account": "Crea il mio account gratuito",
  "msg_I_agree_to_terms_and_conditions": "Accetto i <0>Termini</0> e acconsento al trattamento dei dati personali da parte di Resource Management di Smartsheet nel rispetto della <1>Politica sulla privacy</1>",
  "msg_something_went_wrong_signup": "Ops! Si è verificato un problema con la registrazione. Contattaci alla nostra <0>Pagina dell'assistenza</0>",
  "msg_edit_phase_settings": "Modifica impostazioni fase",
  "msg_edit_project_settings": "Modifica impostazioni di progetto",
  "msg_shift_phase_dates": "Sposta date di fase",
  "msg_shift_project_dates": "Sposta date di progetto",
  "lbl_utilization_lc": "utilizzo",
  "lbl_target_utilization_lc": "utilizzo target",
  "msg_organization_using_smar_auth_modifification_info": "La tua organizzazione sta attualmente utilizzando Autenticazione Smartsheet per accedere a Resource Management. Per modificare questa impostazione, <1>contatta il team di assistenza</1>",
  "lbl_user_auto_provisioning": "Provisioning automatico dell'utente",
  "lbl_edit": "Modifica",
  "lbl_permission_level": "Livello di autorizzazione",
  "msg_activate_smar_auth_info": "Attiva per consentire agli utenti di accedere con il loro account Smartsheet. <1>Per saperne di più</1>.",
  "lbl_activate_smar_auth": "Attiva Autenticazione Smartsheet",
  "msg_please_link_rm_account_with_smar": "Per consentire l'Autenticazione Smartsheet, <1>collega il tuo account Resource Management all'account Smartsheet.</1>",
  "lbl_setup_sso": "Configura SSO",
  "lbl_smartsheet_authentication": "Autenticazione Smartsheet",
  "lbl_edit_sso": "Modifica impostazioni SSO",
  "msg_disabled_filter_info": "Questa opzione non è disponibile per i report avanzati. Crea un <0>nuovo report</0> per accedere a questa opzione.",
  "msg_people_in_org_have_logged_in_using_sso": "Le persone nella tua organizzazione hanno già eseguito l'accesso usando l'SSO. Se si modificano in modo errato le impostazioni SSO gli utenti potrebbero non riuscire ad accedere. Per aggiornare in modo sicuro le impostazioni SSO, <1>contattaci</1> per ricevere assistenza.",
  "err_issue_enabling_sso": "Si è verificato un problema nell'abilitazione dell'Autenticazione SSO: {{error}}. Se questo problema persiste, contatta il team di assistenza.",
  "err_activating_smar_auth": "Errore durante l'attivazione dell'Autenticazione Smartsheet",
  "err_issue_updating_smar_auth": "Si è verificato un problema nell'aggiornamento dell'Autenticazione Smartsheet: {{error}}. Se questo problema persiste, contatta il team di assistenza.",
  "err_issue_enabling_smar_auth": "Si è verificato un problema nell'abilitazione dell'Autenticazione Smartsheet: {{error}}. Se questo problema persiste, contatta il team di assistenza.",
  "msg_report_unmanaged_resources_admin": "Per includere Risorse non gestite in questo report, esegui l'upgrade in <0>Impostazioni account</0>.",
  "msg_report_unmanaged_resources": "Per includere Risorse non gestite in questo report, chiedi all’amministratore di eseguire l'upgrade in Impostazioni account.",
  "lbl_adjust_refresh_settings": "Regola le impostazioni di aggiornamento dati",
  "lbl_view_advanced_report": "Visualizza report avanzato",
  "lbl_save_and_refresh_data": "Salva le modifiche e aggiorna i dati",
  "lbl_refresh_data": "Aggiorna dati",
  "lbl_delete_report": "Elimina report",
  "lbl_toggle_reports_control_menu": "Attiva/disattiva menu di controllo report",
  "lbl_advanced_report": "Report avanzato",
  "lbl_create_new_advanced_report": "Crea nuovo report avanzato",
  "msg_open_the_advanced_report_in_smartsheet": "Apri il report avanzato in Smartsheet",
  "lbl_advanced_report_edit_modal_title": "Impostazioni di aggiornamento dati",
  "lbl_advanced_report_modal_title": "Crea report avanzato",
  "msg_advanced_report_modal_description": "Invia i tuoi dati a un foglio di origine e a un report Smartsheet. Puoi aggiornare il report e modificare le impostazioni dalla pagina Analisi.",
  "lbl_advanced_report_modal_name_field": "Nome",
  "lbl_advanced_report_modal_destination_field": "Destinazione",
  "lbl_advanced_report_modal_data_refresh_field": "Impostazione di aggiornamento dati",
  "lbl_advanced_report_modal_data_refresh_auto_name": "Automatico",
  "lbl_advanced_report_modal_data_refresh_auto_description": "I dati del report avanzato verranno aggiornati ogni 24 ore",
  "lbl_advanced_report_modal_data_refresh_manual_name": "Manuale",
  "lbl_advanced_report_modal_data_refresh_manual_description": "I dati devono essere aggiornati manualmente dalla pagina Analisi",
  "lbl_advanced_report_modal_close_button": "Chiudi",
  "lbl_advanced_report_modal_create_button": "Crea",
  "msg_advanced_report_modal_need_lws": "Per usare questa funzione, chiedi all’amministratore delle risorse di attivare l’autenticazione Smartsheet.",
  "lbl_destination_dropdown_select_workspace": "Seleziona un workspace",
  "lbl_destination_dropdown_search_for_workspace": "Cerca un workspace",
  "msg_advanced_report_ready_title": "Il tuo report avanzato è pronto!",
  "msg_advanced_report_ready": "Puoi sempre accedere e visualizzare lo stato del tuo report avanzato dalla pagina Analisi",
  "lbl_advanced_report_view_btn": "Visualizza report avanzato",
  "msg_advanced_report_creating_title": "Creazione del report avanzato",
  "msg_advanced_report_creating": "Questa operazione richiederà alcuni minuti. Puoi visualizzare subito l’anteprima del report con dati di esempio, che saranno sostituiti dai dati effettivi quando il report sarà pronto.",
  "lbl_advanced_report_preview_btn": "Anteprima report...",
  "msg_advanced_report_creation_footer": "Puoi chiudere. Puoi tornare in seguito per trovarlo nei report salvati o nel workspace selezionato.",
  "lbl_advanced_report_creation_footer_link": "Esplora i suggerimenti sui report avanzati.",
  "lbl_create_advanced_report": "Crea report avanzato",
  "lbl_edit_settings": "Modifica impostazioni report",
  "err_create_report_failed_generic": "Si è verificato un errore durante la creazione del report. Riprova.",
  "err_edit_report_failed_generic": "Si è verificato un errore durante la modifica del report. Riprova.",
  "err_create_report_failed_invalid_name": "Il nome del report non deve contenere caratteri speciali o emoji",
  "msg_session_expired_title": "La tua sessione è scaduta",
  "msg_session_expired_description": "La sessione in questa finestra non è più valida. Clicca sul pulsante in basso per aggiornare la pagina. Potrebbe essere necessario effettuare nuovamente l’accesso.",
  "lbl_refresh_page": "Aggiorna pagina",
  "msg_refresh_advanced_report": "Aggiorna i dati dal menu per applicare i filtri al report avanzato. Anche il report RM salvato verrà aggiornato.",
  "msg_advanced_report_too_many_cells": "Il tuo report contiene troppe colonne o troppe righe. Riduci le dimensioni del report modificando le opzioni nel pannello di sinistra. <0>Per saperne di più</0>",
  "msg_advanced_report_too_many_columns": "Il tuo report contiene troppe colonne. Riduci le dimensioni del report modificando le opzioni nel pannello di sinistra. <0>Per saperne di più</0>",
  "msg_advanced_report_too_many_rows": "Il tuo report contiene troppe righe. Riduci le dimensioni del report modificando le opzioni nel pannello di sinistra. <0>Per saperne di più</0>"
}