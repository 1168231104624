{
  "err_activating_sso_auth": "Aktivierung der SSO-Authentifizierung fehlgeschlagen",
  "err_company_name_length": "Der Name des Unternehmens muss weniger als 255 Zeichen umfassen.",
  "err_duplicate_value": "Dieser Wert ist bereits vorhanden.",
  "err_email_length": "Die E-Mail-Adresse muss weniger als 255 Zeichen umfassen.",
  "err_failed_retrieve_initial_state_from_local_storage": "Abrufen des Ausgangsstatus aus localStorage fehlgeschlagen.",
  "err_fetching_filters": "Beim Abrufen dieser Filteroptionen ist ein Problem aufgetreten.",
  "err_first_name_contains_url": "Der Vorname darf keine URL enthalten.",
  "err_first_name_length": "Der Vorname muss weniger als 255 Zeichen umfassen.",
  "err_global_modules_invalid_input": "Ungültige Eingabe.  Stellen Sie sicher, dass ein Modulname angegeben und mindestens eine Aktion ausgewählt ist.",
  "err_invalid_email": "Geben Sie eine gültige E-Mail-Adresse ein.",
  "err_last_name_contains_url": "Der Nachname darf keine URL enthalten.",
  "err_last_name_length": "Der Nachname muss weniger als 255 Zeichen umfassen.",
  "err_no_status_for_status_panel": "Für Ihr Team sind keine Statusoptionen definiert. Wenn Sie den Status verwenden möchten, kann ein Administrator in den Kontoeinstellungen Statusoptionen hinzufügen.",
  "err_password_length_40_chars": "Muss zwischen 8 und 40 Zeichen umfassen.",
  "err_required": "Erforderlich",
  "err_unable_to_load_filter_list": "Filterliste konnte nicht geladen werden",
  "err_unknown_error": "Unbekannter Fehler",
  "err_updating_smartsheet_auth": "Aktualisieren der Smartsheet-Authentifizierung fehlgeschlagen",
  "err_whoops_something_did_not_work_as_expected": "Hoppla. Es ist ein unerwartetes Problem aufgetreten.",
  "lbl_about_fifteen_seconds": "etwa 15 Sekunden",
  "lbl_about_thirty_seconds": "etwa 30 Sekunden",
  "lbl_accept": "Akzeptieren",
  "lbl_account_switcher": "Kontoumschalter",
  "lbl_activate": "Aktivieren",
  "lbl_activated_auth_status": "Aktiviert (Konto nur mit E-Mail-Adresse erstellt)",
  "lbl_active": "Aktiv",
  "lbl_activity": "Aktivität",
  "lbl_actual_hours": "Tatsächliche Stunden",
  "lbl_add": "Hinzufügen",
  "lbl_add_group_label_filter": "Filter {{groupLabel}} hinzufügen",
  "lbl_assign_to_search_user": "‚{{searchInput}}‘ zuweisen",
  "lbl_add_lc_members": "Mitglieder hinzufügen",
  "lbl_add_members": "Mitglieder hinzufügen",
  "lbl_add_person": "Person hinzufügen",
  "lbl_add_tasklist": "Aufgabenliste hinzufügen",
  "lbl_add_team_member": "Teammitglied hinzufügen",
  "lbl_add_update_people": "Personen hinzufügen/aktualisieren",
  "lbl_add_work_item": "Arbeitselement hinzufügen",
  "lbl_added": "Hinzugefügt",
  "lbl_all": "Alle",
  "lbl_all_project_types": "Alle Projekttypen",
  "lbl_allocation_heatmap": "Zuordnungs-Heatmap",
  "lbl_allocation_percent": "Zuordnung in Prozent",
  "lbl_amounts": "Beträge",
  "lbl_amounts_and_days": "Beträge und Tage",
  "lbl_amounts_and_days_filter_display_name": "Zeit und Gebühren: Beträge und Tage",
  "lbl_amounts_and_hours": "Beträge und Stunden",
  "lbl_apply_changes": "Änderungen anwenden",
  "lbl_approval_access_id": "Genehmigungszugriffs-ID",
  "lbl_approval_status": "Genehmigungsstatus",
  "lbl_approvals": "Genehmigungen",
  "lbl_approvals_card_header_project_phase_client": "Projekt: Phase – Kunde",
  "lbl_approve_and_lock": "Genehmigen und sperren",
  "lbl_approved": "Genehmigt",
  "lbl_approved_and_locked": "Genehmigt und gesperrt",
  "lbl_approved_by": "Genehmigt von",
  "lbl_approver": "Genehmiger",
  "lbl_approver_view": "Genehmigeransicht",
  "lbl_approvers": "Genehmiger",
  "lbl_april": "April",
  "lbl_archive": "Archivieren",
  "lbl_archive_projects": "Projekte archivieren",
  "lbl_archived": "Archiviert",
  "lbl_archived_label": "Bezeichnung „Archiviert“",
  "lbl_archived_on_date": "Archiviert am {{archiveDate}}",
  "lbl_archiving": "Archivierung",
  "lbl_assign": "Zuweisen",
  "lbl_assign_group_label": "{{groupLabel}} zuweisen",
  "lbl_assign_team_member": "Teammitglied zuweisen",
  "lbl_assign_to": "Zuweisen zu",
  "lbl_assignment": "Zuweisung",
  "lbl_assignment_dates_colon": "Zuweisungsdaten:",
  "lbl_assignment_details": "Zuweisungsdetails",
  "lbl_assignment_name": "Name der Zuweisung",
  "lbl_assignment_status": "Zuweisungsstatus",
  "lbl_audit_log": "Audit-Protokoll",
  "lbl_august": "August",
  "lbl_authentication": "Authentifizierung",
  "lbl_author": "Autor",
  "lbl_automatic": "Automatisch",
  "lbl_automatic_capitalize": "Automatisch",
  "lbl_availability": "Verfügbarkeit",
  "lbl_availability_sort_options": "Sortieroptionen für die Verfügbarkeit",
  "lbl_available_hours": "Verfügbare Stunden",
  "lbl_back": "Zurück",
  "lbl_back_to_account_settings": "Zurück zu Kontoeinstellungen",
  "lbl_back_to_list_of_requests": "Zurück zur Anforderungsliste",
  "lbl_based_on": "Basierend auf",
  "lbl_bill_rate": "Rechnungsrate",
  "lbl_billable": "Verrechenbar",
  "lbl_bracket_lc_none": "[keine]",
  "lbl_bracket_leave": "[verlassen]",
  "lbl_bracket_no_approver_assigned": "[Kein Genehmiger zugewiesen]",
  "lbl_bracket_non_phase_specific": "[Nicht phasenspezifisch]",
  "lbl_bracket_none": "[Keine]",
  "lbl_brief": "Briefing",
  "lbl_budget": "Budget",
  "lbl_budget_amounts_filter_display_name": "Budgets: Beträge",
  "lbl_budget_days_filter_display_name": "Budgets: Tage",
  "lbl_budget_hours_filter_display_name": "Budgets: Stunden",
  "lbl_budget_report": "Budgetbericht",
  "lbl_budgets": "Budgets",
  "lbl_calculating": "Berechnung von",
  "lbl_cancel": "Abbrechen",
  "lbl_cancelled": "abgebrochen",
  "lbl_capacity": "Kapazität",
  "lbl_category": "Kategorie",
  "lbl_cell_phone": "Mobiltelefon",
  "lbl_certificate_details": "Zertifikatsdaten",
  "lbl_change_to_unmanaged_confirm": "Dieser Benutzer kann keine Zeit und Ausgaben mehr erfassen und genehmigen und wird nicht mehr in der Kapazitätsansicht und in Berichten angezeigt.",
  "lbl_change_to_managed": "Zu „Verwaltet“ ändern",
  "lbl_change_to_unmanaged": "Zu „Nicht verwaltet“ ändern",
  "lbl_choose_file": "Datei auswählen",
  "lbl_client": "Kunde",
  "lbl_client_name": "Name des Kunden",
  "lbl_close": "Schließen",
  "lbl_company_name": "Name des Unternehmens",
  "lbl_complete": "Abgeschlossen",
  "lbl_confirm": "Bestätigen",
  "lbl_confirmed": "Bestätigt",
  "lbl_contact_info": "Kontaktinformationen",
  "lbl_contact_support": "Support kontaktieren",
  "lbl_contractor": "Auftragnehmer",
  "lbl_copy": "Kopieren",
  "lbl_count_of_total": "{{count}} von {{total}}",
  "lbl_create": "Erstellen",
  "lbl_create_new_request": "* Neue Anforderung erstellen",
  "lbl_current": "Aktuell",
  "lbl_current_work": "Aktuelle Arbeit",
  "lbl_custom_date_range": "Benutzerdefinierter Datumsbereich",
  "lbl_custom_field": "Benutzerdefiniertes Feld",
  "lbl_date": "Datum",
  "lbl_date_string_at_time_string": "{{date}} um {{time}}",
  "lbl_date_string": "Datumszeichenfolge",
  "lbl_dates": "Daten",
  "lbl_dates_for_submitted_time": "Daten für eingereichte Zeit",
  "lbl_day_friday": "Freitag",
  "lbl_day_monday": "Montag",
  "lbl_day_saturday": "Samstag",
  "lbl_day_sunday": "Sonntag",
  "lbl_day_thursday": "Donnerstag",
  "lbl_day_tuesday": "Dienstag",
  "lbl_day_wednesday": "Mittwoch",
  "lbl_days": "Tage",
  "lbl_deactivated": "Deaktiviert",
  "lbl_december": "Dezember",
  "lbl_delete": "Löschen",
  "lbl_delete_adv_report_modal": "Bericht löschen?",
  "lbl_delete_saved_report_modal": "Gespeicherten Bericht löschen?",
  "lbl_description": "Beschreibung",
  "lbl_difference": "Differenz",
  "lbl_difference_from_past_scheduled": "Differenz zu „Zuletzt geplant“",
  "lbl_disabled": "deaktiviert",
  "lbl_discipline": "Disziplin",
  "lbl_display_colon": "Anzeigen:",
  "lbl_done": "Erledigt",
  "lbl_duration": "Dauer",
  "lbl_duration_working_days": "Dauer (in Arbeitstagen)",
  "lbl_edit_profile": "Profil bearbeiten",
  "lbl_edit_project_information": "Projektinformationen bearbeiten",
  "lbl_edit_projects": "Projekte bearbeiten",
  "lbl_email": "E-Mail",
  "lbl_email_must_be_less_than_255_characters.": "Die E-Mail-Adresse muss weniger als 255 Zeichen umfassen.",
  "lbl_employee_number": "Mitarbeiternummer",
  "lbl_enabled": "aktiviert",
  "lbl_end": "Ende",
  "lbl_end_date": "Enddatum",
  "lbl_end_lc_date": "Enddatum",
  "lbl_ends_at": "Endet am",
  "lbl_enter_a_name": "Namen eingeben",
  "lbl_enter_rejection_reason": "Grund für Ablehnung eingeben",
  "lbl_entry_type": "Eintragstyp",
  "lbl_everyone": "Alle",
  "lbl_everything": "Alles",
  "lbl_expense": "Ausgabe",
  "lbl_expenses": "Ausgaben",
  "lbl_export": "Exportieren",
  "lbl_export_people_list": "Personenliste exportieren",
  "lbl_export_report_to_csv": "Diesen Bericht als CSV-Datei exportieren",
  "lbl_export_time_entries_for_quickBooks": "Zeiteinträge für QuickBooks exportieren",
  "lbl_export_underlying_data_as_csv": "Zugrunde liegende Daten als CSV-Datei exportieren",
  "lbl_failed": "Fehlgeschlagen",
  "lbl_february": "Februar",
  "lbl_fees": "Gebühren",
  "lbl_filter": "Filter",
  "lbl_filtered": "Gefiltert",
  "lbl_filters": "Filter",
  "lbl_first_day_of_work": "Erster Arbeitstag",
  "lbl_first_group_by": "Zuerst gruppieren nach",
  "lbl_first_name": "Vorname",
  "lbl_first_name_lc": "Vorname",
  "lbl_friday_abbr_uc": "F",
  "lbl_future": "Zukunft",
  "lbl_future_scheduled": "Künftig geplant",
  "lbl_global_modules": "Globale Module",
  "lbl_grid_view": "Tabellenansicht",
  "lbl_hours": "Stunden",
  "lbl_hours_filter_display_name": "Zeit und Gebühren: Stunden",
  "lbl_hours_per_day": "Stunden pro Tag",
  "lbl_idp_metadata_url": "IdP-Metadaten-URL",
  "lbl_iframe_view_content_require": "Um diesen Inhalt anzuzeigen, ist eine Smartsheet Resource Management-Lizenz erforderlich.",
  "lbl_in_the_office": "Im Büro",
  "lbl_incurred": "Angefallen",
  "lbl_internal": "Intern",
  "lbl_invited": "Eingeladen",
  "lbl_january": "Januar",
  "lbl_july": "Juli",
  "lbl_june": "Juni",
  "lbl_just_now": "Gerade eben",
  "lbl_last_and_next_ninety_days": "Vergangene und kommende 90 Tage",
  "lbl_last_day_of_work": "Letzter Arbeitstag",
  "lbl_last_month": "Letzter Monat",
  "lbl_last_name": "Nachname",
  "lbl_last_name_lc": "Nachname",
  "lbl_last_quarter": "Letztes Quartal",
  "lbl_last_thirty_days": "Letzte 30 Tage",
  "lbl_last_week": "Letzte Woche",
  "lbl_learn_lc_more": "Weitere Informationen",
  "lbl_learn_more": "Weitere Informationen",
  "lbl_leave": "Freistellung",
  "lbl_leave_type": "Freistellungsart",
  "lbl_licensed": "Lizenziert",
  "lbl_licensed_users": "Lizenzierte Benutzer",
  "lbl_linked_smartsheet_project": "Verlinktes Smartsheet-Projekt",
  "lbl_loading": "Wird geladen",
  "lbl_loading_ellipsis": "Wird geladen …",
  "lbl_loading_project_ellipsis": "Projekt wird geladen ...",
  "lbl_location": "Speicherort",
  "lbl_log_requested": "Protokoll angefordert",
  "lbl_make_active": "Aktivieren",
  "lbl_make_licensed_user": "In lizenzierten Benutzer umwandeln",
  "lbl_manage_members": "Mitglieder verwalten",
  "lbl_managed_resource": "Verwaltete Ressourcen",
  "lbl_manual": "manuell",
  "lbl_manual_capitalize": "Manuell",
  "lbl_march": "März",
  "lbl_matching_people": "Übereinstimmende Personen",
  "lbl_matching_person": "übereinstimmende Person",
  "lbl_matching_placeholders": "Übereinstimmende Platzhalter",
  "lbl_matching_project": "übereinstimmendes Projekt",
  "lbl_matching_projects": "übereinstimmende Projekte",
  "lbl_may": "Mai",
  "lbl_medium": "Mittel",
  "lbl_members": "Mitglieder",
  "lbl_mobile": "Mobil",
  "lbl_module": "Modul:",
  "lbl_module_name": "Modulname",
  "lbl_monday_abbr_uc": "M",
  "lbl_month": "Monat",
  "lbl_more_assignments": "weitere Zuweisungen",
  "lbl_must_be_between_8–40_characters.": "Muss zwischen 8 und 40 Zeichen umfassen.",
  "lbl_my_expenses": "Meine Ausgaben",
  "lbl_my_projects": "Meine Projekte",
  "lbl_my_time": "Meine Zeit",
  "lbl_my_time_and_expenses": "Meine Zeit und Ausgaben",
  "lbl_my_views": "Meine Ansichten",
  "lbl_new_project": "Neues Projekt",
  "lbl_next_ninety_days": "Nächste 90 Tage",
  "lbl_next_sixty_days": "Nächste 60 Tage",
  "lbl_next_thirty_days": "Nächste 30 Tage",
  "lbl_next_week": "Nächste Woche",
  "lbl_no_assignments": "Keine Zuweisungen",
  "lbl_no_description_provided": "Keine Beschreibung vorhanden.",
  "lbl_no_imports_found": "Keine Importe gefunden",
  "lbl_non_billable": "Nicht verrechenbar",
  "lbl_none": "Keine",
  "lbl_non_matching": "Nicht übereinstimmend",
  "lbl_not_using_smar": "Ohne Smartsheet-Authentifizierung",
  "lbl_not_using_sso": "Ohne SSO",
  "lbl_not_yet_invited": "Noch nicht eingeladen",
  "lbl_notes": "Hinweise",
  "lbl_november": "November",
  "lbl_october": "Oktober",
  "lbl_office": "Büro",
  "lbl_office_phone": "Bürotelefon",
  "lbl_office_status": "Bürostatus",
  "lbl_on_the_road": "Unterwegs",
  "lbl_or_select_a_specific_phase": "Oder eine bestimmte Phase auswählen",
  "lbl_or_select_phases": "Oder Phase(n) auswählen",
  "lbl_or_select_specific_phases": "Oder bestimmte Phase(n) auswählen",
  "lbl_other_projects": "Andere Projekte",
  "lbl_out_of_office": "Abwesend",
  "lbl_out_sick": "Krank",
  "lbl_past": "Vergangenheit",
  "lbl_pending": "Ausstehend",
  "lbl_pending_approval": "Genehmigung ausstehend",
  "lbl_people": "Personen",
  "lbl_people_filters": "Filter für Personen",
  "lbl_people_scheduler": "Personalplaner",
  "lbl_people_tags": "Tags für Personen",
  "lbl_percentage": "Prozentsatz",
  "lbl_persistent_navigation": "Beständige Navigation",
  "lbl_person": "Person",
  "lbl_phase": "Phase",
  "lbl_phase_dates": "Phasendaten",
  "lbl_phase_dates_colon": "Phasendaten:",
  "lbl_phase_four": "Phase Vier",
  "lbl_phase_lc_name": "Phasenname",
  "lbl_phase_name": "Phasenname",
  "lbl_phase_name_long_enough_to_wrap": "Phasenname mit Zeilenumbruch",
  "lbl_phase_one": "Phase Eins",
  "lbl_phase_status": "Phasenstatus",
  "lbl_phase_three": "Phase Drei",
  "lbl_phase_two": "Phase Zwei",
  "lbl_placeholder": "Platzhalter",
  "lbl_placeholders": "Platzhalter",
  "lbl_please_sign_in": "Anmelden, um fortzufahren",
  "lbl_plus_allocation": "+ Zuordnung",
  "lbl_portfolio_editor": "Portfoliobearbeiter",
  "lbl_portfolio_reporter": "Portfolioreporter",
  "lbl_portfolio_viewer": "Portfoliobetrachter",
  "lbl_post": "Post",
  "lbl_project": "Projekt",
  "lbl_project_access": "Projektzugriff",
  "lbl_project_code": "Projektcode",
  "lbl_project_dates": "Projektdaten",
  "lbl_project_dates_colon": "Projektdaten:",
  "lbl_project_description": "Projektbeschreibung",
  "lbl_project_duration": "Projektdauer",
  "lbl_project_editor": "Projektbearbeiter",
  "lbl_project_filters": "Projektfilter",
  "lbl_project_members": "Projektmitglieder",
  "lbl_project_name": "Projektname",
  "lbl_project_owner": "Projektinhaber",
  "lbl_project_owners": "Projektinhaber",
  "lbl_project_portfolio": "Projektportfolio",
  "lbl_project_properties": "Projekteigenschaften",
  "lbl_project_settings": "Projekteinstellungen",
  "lbl_project_state": "Projektstatus",
  "lbl_project_tags": "Projekt-Tags",
  "lbl_project_title": "Projekttitel",
  "lbl_project_type": "Projekttyp",
  "lbl_projects": "Projekte",
  "lbl_purchase_more": "Mehr kaufen",
  "lbl_recents": "Kürzlich verwendet",
  "lbl_recent_requests": "Letzte Anforderungen",
  "lbl_refresh_status": "Status aktualisieren",
  "lbl_refresh_type": "Typ aktualisieren",
  "lbl_refreshing_data": "Daten werden aktualisiert...",
  "lbl_reject": "Ablehnen",
  "lbl_rejected": "Abgelehnt",
  "lbl_remaining": "Verbleibend",
  "lbl_remove": "Entfernen",
  "lbl_report_data_refresh_status_error": "Datenaktualisierung fehlgeschlagen am {{date}} um {{time}}",
  "lbl_report_data_refresh_status_error_please_try_again": "Beim Erstellen Ihres erweiterten Berichts ist ein Fehler aufgetreten. Versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie den Support.",
  "lbl_report_data_refresh_status_loading": "Ihr erweiterter Bericht wird aktualisiert",
  "lbl_report_data_refresh_status_success": "Zuletzt aktualisiert am {{date}} um {{time}}",
  "lbl_report_data_refresh_status_warning_auto": "Änderungen werden mit der nächsten Datenaktualisierung synchronisiert",
  "lbl_report_data_refresh_status_warning_manual": "Erweiterten Bericht aktualisieren, um Änderungen zu synchronisieren",
  "lbl_report_name_colon": "Berichtsname:",
  "lbl_report_title_by_criteria": "{{report}} nach {{criteria}}",
  "lbl_reports": "Berichte",
  "lbl_request_an_audit_log": "Ein Audit-Protokoll anfordern",
  "lbl_request_audit_log": "Audit-Protokoll anfordern",
  "lbl_requester": "Anfordernder",
  "lbl_resend_invitation": "Einladung erneut senden",
  "lbl_reset": "Zurücksetzen",
  "lbl_reset_filters": "Filter zurücksetzen",
  "lbl_reset_password": "Kennwort zurücksetzen",
  "lbl_reset_password_for": "Kennwort zurücksetzen für",
  "lbl_reset_report": "Bericht zurücksetzen",
  "lbl_resource-request_test_page": "Testseite für Ressourcenanforderungen",
  "lbl_resource_management_by_smartsheet": "Smartsheet Resource Management ",
  "lbl_resourcing_admin": "RM-Administrator",
  "lbl_retry": "Wiederholen",
  "lbl_role": "Rolle",
  "lbl_saturday_abbr_uc": "S",
  "lbl_save": "Speichern",
  "lbl_save_changes": "Änderungen speichern",
  "lbl_save_current_view": "Aktuelle Ansicht speichern",
  "lbl_save_this_report_to_your_personal_page": "Diesen Bericht auf Ihrer persönlichen Seite speichern",
  "lbl_saving": "Wird gespeichert",
  "lbl_schedule": "Zeitplan",
  "lbl_scheduled": "Geplant",
  "lbl_search": "Suchen",
  "lbl_search_ellipsis": "Suche ...",
  "lbl_select_date_range_to_see_entries": "Einen Datumsbereich auswählen, um alle Einträge innerhalb dieses Zeitraums anzuzeigen",
  "lbl_select_people_to_add_to_project": "Wählen Sie die Personen aus, die Sie diesem Projekt hinzufügen möchten.",
  "lbl_select_phases": "Phase(n) auswählen",
  "lbl_select_project": "Projekt auswählen",
  "lbl_select_project_or_phase": "Projekt oder Phase auswählen",
  "lbl_select_project_owner": "Projektinhaber auswählen",
  "lbl_select_team_member": "Teammitglied auswählen:",
  "lbl_select_the_entire_project": "Das gesamte Projekt auswählen",
  "lbl_send": "Senden",
  "lbl_send_invitation": "Einladung senden",
  "lbl_september": "September",
  "lbl_set_a_status": "Einen Status festlegen",
  "lbl_set_allocation": "Zuordnung festlegen:",
  "lbl_set_to_today": "Festlegen auf Heute",
  "lbl_share": "Freigeben",
  "lbl_shared_views": "Freigegebene Ansichten",
  "lbl_show": "Anzeigen",
  "lbl_show_no_phases": "Anzeigen: Keine Phasen",
  "lbl_sign_out_slo_target_url": "Ziel-URL für Abmeldung (SLO)",
  "lbl_signin_sso_target_url": "Ziel-URL für Anmeldung (SSO)",
  "lbl_signup_password": "Kennwort (mind. 8 Zeichen)",
  "lbl_skipped": "Übersprungen",
  "lbl_skipped_colon": "Übersprungen: {{number}}",
  "lbl_small": "Klein",
  "lbl_something_went_wrong_ellipsis": "Es ist ein Problem aufgetreten.",
  "lbl_sort_by": "Sortieren nach",
  "lbl_sso_for_resource_management": "SSO für Resource Management",
  "lbl_start": "Anfang",
  "lbl_start_date": "Startdatum",
  "lbl_start_lc_date": "Startdatum",
  "lbl_starts_at": "Startet am",
  "lbl_status": "Status",
  "lbl_status_cancelled": "ABGEBROCHEN",
  "lbl_status_failed": "FEHLGESCHLAGEN",
  "lbl_status_message": "Statusmeldung",
  "lbl_status_queued": "IN DER WARTESCHLANGE",
  "lbl_status_running": "WIRD AUSGEFÜHRT",
  "lbl_status_succeeded": "ERFOLGREICH AUSGEFÜHRT",
  "lbl_submitted_time_for_approval": "Zur Genehmigung eingereichte Zeit",
  "lbl_sunday_abbr_uc": "S",
  "lbl_switch_to_another_account": "Zu anderem Konto wechseln:",
  "lbl_tag": "Tag",
  "lbl_tags": "Tags",
  "lbl_target_utilization": "Zielauslastung",
  "lbl_tasks": "Aufgaben",
  "lbl_team_member": "Teammitglied",
  "lbl_team_member_type": "Teammitgliedstyp",
  "lbl_tentative": "Vorläufig",
  "lbl_terms": "Bedingungen",
  "lbl_the_selected_project_was": "Das ausgewählte Projekt war ",
  "lbl_then_group_by": "Dann gruppieren nach",
  "lbl_these_results_only_include_projects_that_you_have_permission_to_see": "Diese Ergebnisse umfassen nur Projekte, für die Sie zum Anzeigen berechtigt sind.",
  "lbl_this_month": "Dieser Monat",
  "lbl_this_quarter": "Dieses Quartal",
  "lbl_this_week": "Diese Woche",
  "lbl_this_year": "Dieses Jahr",
  "lbl_thursday_abbr_uc": "D",
  "lbl_time": "Zeit",
  "lbl_time_and_expenses": "Zeit und Ausgaben",
  "lbl_time_and_fee_categories": "Zeit- und Gebührenkategorien",
  "lbl_time_and_fees": "Zeit und Gebühren",
  "lbl_time_and_fees_report": "Bericht zu Zeit und Gebühren",
  "lbl_time_approval_dashboard": "Zeitgenehmigungs-Dashboard",
  "lbl_time_budget_unit_of_measurement": "Maßeinheit für Zeitbudget",
  "lbl_time_fees_amounts_and_hours_filter_display_name": "Zeit und Gebühren: Beträge und Stunden",
  "lbl_time_fees_amounts_display_name": "Zeit und Gebühren: Beträge",
  "lbl_time_fees_days_filter_display_name": "Zeit und Gebühren: Tage",
  "lbl_time_frame": "Zeitrahmen",
  "lbl_time_tracking": "Zeiterfassung",
  "lbl_today": "Heute",
  "lbl_tomorrow": "Morgen",
  "lbl_total": "Gesamt",
  "lbl_tuesday_abbr_uc": "D",
  "lbl_type": "Typ",
  "lbl_unarchive": "Archivierung aufheben",
  "lbl_unarchive_project": "Archivierung des Projekts aufheben",
  "lbl_unassigned": "Nicht zugewiesen",
  "lbl_unconfirmed": "Nicht bestätigt",
  "lbl_unmanaged": "Nicht verwaltet",
  "lbl_unmanaged_resource": "Nicht verwaltete Ressource",
  "lbl_unmanaged_resources": "Nicht verwaltete Ressourcen",
  "lbl_unlock": "Entsperren",
  "lbl_upcoming": "Anstehend",
  "lbl_update": "Aktualisieren",
  "lbl_update_status": "Status aktualisieren: ",
  "lbl_updated": "Aktualisiert",
  "lbl_updating": "Wird aktualisiert",
  "lbl_utilization": "Auslastung",
  "lbl_utilization_target": "Auslastungsziel",
  "lbl_vacation": "Urlaub",
  "lbl_values": "Werte",
  "lbl_view": "Anzeigen",
  "lbl_view_resourcing_schedule": "Ressourcenplan anzeigen",
  "lbl_wednesday_abbr_uc": "M",
  "lbl_week": "Woche",
  "lbl_work": "Arbeit",
  "lbl_work_email": "Geschäftliche E-Mail-Adresse",
  "lbl_work_item": "Arbeitselement",
  "lbl_work_status": "Arbeitsstatus",
  "lbl_working_from_home": "Von Zuhause aus arbeiten",
  "lbl_worklist": "Arbeitsliste",
  "lbl_your_projects_includes_the_following_properties": "Ihre Projekte: Umfasst die folgenden Eigenschaften",
  "msg_account_owner_change_attempt": "Ändern Sie vor dem Archivieren dieses Benutzers den Kontoinhaber in den Kontoeinstellungen.",
  "msg_activated_smartsheet_auth": "Die Smartsheet-Authentifizierung wird aktiviert. Diese Authentifizierungsmethode ist sofort wirksam und kann nicht deaktiviert werden.",
  "msg_add_assignment_to_project": "Zuweisung zu Projekt hinzufügen",
  "msg_add_notes_placeholder": "Notizen, Details, Links usw. hinzufügen",
  "msg_add_phase_to_project": "Phase zu Projekt hinzufügen",
  "msg_add_project_description": "Projektbeschreibung hinzufügen",
  "msg_add_save_current_view": "„Aktuelle Ansicht speichern“ hinzufügen",
  "msg_add_team_member_to_project": "Teammitglied zu Projekt hinzufügen",
  "msg_app_project_members_disabled_tooltip": "Sie sind Inhaber dieser Projekte oder sie wurden Ihnen zugewiesen.",
  "msg_app_project_members_enabled_tooltip": "Sie sind Inhaber oder ein Mitglied dieser Projekte, oder sie wurden Ihnen zugewiesen.",
  "msg_audit_log_info": "Wichtig: Ein Audit-Protokoll ist zum Zeitpunkt der Anforderung eine präzise Momentaufnahme, ist aber nicht mehr aktuell, wenn nach der Erstellung des Protokolls Änderungen an Zeit- oder Ausgabeneinträgen vorgenommen werden. Um die neuesten Informationen zu erhalten, fordern Sie oben ein neues Protokoll an.",
  "msg_auto_provision_checkbox": "Wenn das automatisierte Benutzer-Provisioning aktiv ist, weist es Benutzern automatisch Lizenzen und die ausgewählte Berechtigungsstufe zu. Dies ermöglicht Benutzern auch, den E-Mail-Einladungsprozess zu umgehen.",
  "msg_bracket_search_to_add_filters": "[Suchen, um Filter hinzuzufügen]",
  "msg_cannot_archive_account_owner": "Sie können den Kontoinhaber nicht archivieren.",
  "msg_cert_requirement": "Verwenden Sie eine PEM-kodierte Datei mit CER-, CRT- oder PEM-Dateierweiterung.",
  "msg_cert_upload_complete": "Zertifikat wurde hochgeladen",
  "msg_checkbox_list_aria": "Aktivieren oder deaktivieren Sie die Kontrollkästchen für eine Mehrfachauswahl oder klicken Sie auf eine Auswahlschaltfläche, um nur eine Option auszuwählen.",
  "msg_choose_permission_type": "Benutzerberechtigungstyp auswählen",
  "msg_choose_property_to_sort_by": "Wählen Sie aus, nach welcher Eigenschaft die Sortierung erfolgen soll.",
  "msg_close": "Schließen",
  "msg_close_and_redirect_to_profile_page": "Schließen und zu Profilseite weiterleiten",
  "msg_count_matches_found": "{{count}} Treffer gefunden",
  "msg_copy_url_to_share_report": "Kopieren Sie die untenstehende URL, um diesen Bericht freizugeben:",
  "msg_customize_report_prompt": "Passen Sie Ihren Bericht mit den Optionen auf der linken Seite an. Wenn Sie fertig sind, wählen Sie eine der untenstehenden Optionen aus.",
  "msg_delete_adv_report_modal": "Durch diese Aktion wird die Aktualisierung der Berichtsdaten beendet, der zugrunde liegende Bericht und das Quellsheet im Zielarbeitsbereich werden jedoch nicht gelöscht.",
  "msg_delete_assignment": "Zuweisung löschen",
  "msg_delete_saved_report": "Gespeicherten Bericht löschen",
  "msg_delete_saved_report_modal": "Diese Aktion kann nicht rückgängig gemacht werden, aber Sie können einen neuen Bericht mit denselben Optionen erstellen.",
  "msg_delete_view": "Ansicht löschen",
  "msg_delete_work_item_warning": "Möchten Sie dieses Arbeitselement wirklich dauerhaft löschen? Alle zugehörigen Aufgaben und Zuweisungen werden entfernt. Dieser Vorgang kann nicht rückgängig gemacht werden.",
  "msg_edit_allocation": "Zuordnung bearbeiten",
  "msg_edit_end_date": "Enddatum bearbeiten",
  "msg_edit_start_date": "Startdatum bearbeiten",
  "msg_edit_status": "Status bearbeiten",
  "msg_edit_team_member": "Teammitglied bearbeiten",
  "msg_edit_work_item": "Arbeitselement bearbeiten",
  "msg_enable_bulk_editing_requirements": "Um die massenhafte Bearbeitung zu aktivieren, muss der Projektinhaberfilter auf Sie und der Projektstatusfilter auf „Aktiv“ eingestellt sein.",
  "msg_enable_module_for_existing_organizations": "Modul für vorhandene Organisationen aktivieren",
  "msg_enable_module_for_new_organizations": "Modul für neue Organisationen aktivieren",
  "msg_filter_is_locked_to_the_projects_you_have_permission_to_report_on": "Dieser Filter ist auf die Projekte beschränkt, für die Sie Reporting-Berechtigungen haben.",
  "msg_filter_is_locked_to_the_projects_you_own_or_are_assigned_to": "Dieser Filter ist auf die Projekte beschränkt, deren Inhaber Sie sind oder die Ihnen zugewiesen sind.",
  "msg_grid_view_aria": "Tabellenansicht: Aktivieren Sie diese Option, um die Tabellenansicht zu verwenden.",
  "msg_hold_on_loading_ellipsis": "Der Ladevorgang läuft. Warten Sie einen Moment.",
  "msg_infinite_loader_aria_label": "Aktivieren oder deaktivieren Sie die Kontrollkästchen für eine Mehrfachauswahl oder klicken Sie auf eine Auswahlschaltfläche, um nur eine Option auszuwählen.",
  "msg_invalid_cert": "Der Dateityp des ausgewählten Zertifikats ist ungültig.",
  "msg_list_view_and_project_field_selector": "Listenansicht und Projektfeldauswahl: Aktivieren Sie diese Option, um die Listenansicht anzuwenden und auszuwählen, welche Projektfelder verfügbar gemacht werden.",
  "msg_loading_approval_status": "Genehmigungsstatus wird geladen …",
  "msg_loading_page": "Seite wird geladen",
  "msg_loading_project_timeline_ellipsis": "Projektzeitplan wird geladen ...",
  "msg_loading_project_users_ellipsis": "Projektbenutzer werden geladen ...",
  "msg_make_bulk_project_archive_warning": "Die Archivierung dieser Projekte beendet laufende und entfernt alle zukünftigen Zuweisungen. Dieser Vorgang kann nicht rückgängig gemacht werden.",
  "msg_make_bulk_project_edit_confirmation": "Sind Sie sicher, dass Sie diese Änderung vornehmen möchten?",
  "msg_make_bulk_project_edit_warning": "Dies kann mehrere Minuten dauern und kann nicht rückgängig gemacht werden.",
  "msg_manage_project_membership_and_project_access_levels_based_on_their_account_permissions": "Verwalten Sie die Projektmitgliedschaft und die Projektzugriffsebenen. Zugriffsebenen beziehen sich nur auf dieses Projekt. Manche Personen können je nach <1>Kontoberechtigungen</1> ohne eine Mitgliedschaft Änderungen vornehmen.",
  "msg_need_a_minute_ellipsis": "Hoppla. Wir brauchen noch einen Moment ...",
  "msg_new_work_item_title": "Neuer Arbeitselementtitel",
  "msg_no_assignments": "Keine Zuweisungen",
  "msg_no_matches_found": "Keine Treffer gefunden",
  "msg_no_people_assigned_to_project": "Diesem Projekt sind keine Personen zugewiesen.",
  "msg_no_phases_to_display": "Keine Phasen verfügbar",
  "msg_no_projects_match_your_criteria": "Kein Projekt stimmt mit Ihren Kriterien überein.",
  "msg_no_propertyies_to_display": "Keine Eigenschaften verfügbar",
  "msg_no_recent_requests": "Keine letzten Anforderungen",
  "msg_no_results_to_display": "Keine Ergebnisse",
  "msg_no_work_for_this_project": "Für dieses Projekt gibt es derzeit keine Arbeit.",
  "msg_not_available": "nicht verfügbar",
  "msg_one_match_found": "1 Treffer gefunden",
  "msg_owners_of_projects_permission_to_report_on": "Die Inhaber der Projekte, für die Sie Reporting-Berechtigungen haben.",
  "msg_people_field_selector": "Personenfeldauswahl: Aktivieren Sie diese Option, um auszuwählen, welche Datenfelder verfügbar gemacht werden.",
  "msg_people_settings_info": "Lizenzierte Benutzer können Zeit und Aufgaben geplanter Zuweisungen erfassen und optional eingeladen werden, sich anzumelden. Verwaltete Ressourcen können für Projekte eingeplant werden, aber können keine Zeit erfassen und haben keinen Anmeldezugriff.",
  "msg_people_list_licensed_tooltip": "Lizenzierte Benutzer können basierend auf Berechtigungen Zeit erfassen und genehmigen, Projekte planen, Personal verwalten, Benutzerprofile bearbeiten und Berichte erstellen.",
  "msg_people_list_managed_tooltip": "Führen Sie ein Upgrade der verwalteten Ressourcen durch, um die Zeiterfassungsfunktionen zu verwenden.",
  "msg_people_list_unmanaged_tooltip": "Führen Sie ein Upgrade der nicht verwalteten Ressourcen durch, um sie in die Kapazitätsansicht und in Berichte aufzunehmen.",
  "msg_people_list_archived_tooltip": "Zuweisungen der archivierten Benutzer verbleiben auf der Projektseite und können dann einer anderen Person oder einem Platzhalter zugewiesen werden.",
  "msg_phase_picker_copy": "In Projekt oder Phase kopieren",
  "msg_print_brief_page": "Briefingseite ausdrucken",
  "msg_profile_banner": "Profilbanner",
  "msg_profile_information": "Profilinformationen",
  "msg_profile_navigation": "Profilnavigation",
  "msg_profile_navigation_menu": "Profilnavigationsmenü",
  "msg_project_members_tooltip": "Sie sind Inhaber oder ein Mitglied dieser Projekte, oder sie wurden Ihnen zugewiesen.",
  "msg_project_navigation": "Projektnavigation",
  "msg_project_members_description": "Die Projektmitgliedschaft gilt nur für dieses Projekt. Einige Benutzer können, basierend auf ihren <0>Kontoberechtigungen</0>, auf dieses Projekt zugreifen, ohne Mitglied zu sein.",
  "msg_reassign_search_placeholder": "Nach Namen, Disziplin usw. suchen",
  "msg_refine_search_to_see_more_users": "Suche verfeinern, um mehr übereinstimmende Benutzer anzuzeigen",
  "msg_rejection_reason_required": "Ein Ablehnungsgrund ist erforderlich.",
  "msg_remove_archived_project_state_to_enable_editing": "Entfernen Sie „Archiviert“ aus dem Projektstatusfilter, um die Bearbeitung zu aktivieren.",
  "msg_remove_assignment_confirmation": "Sind Sie sicher, dass Sie diese Zuweisung entfernen möchten?",
  "msg_report_data_refresh_err_detail_cell_limit_exceeded": "Wir konnten Ihre Berichtsdaten nicht aktualisieren, da der Bericht 3.000 Zellen über dem Grenzwert liegt. Reduzieren Sie die Größe Ihres Berichts, indem Sie die Optionen im linken Bereich anpassen. <1>Weitere Informationen</1>",
  "msg_report_data_refresh_err_detail_generic": "Beim Aktualisieren Ihrer erweiterten Berichtsdaten ist ein Fehler aufgetreten. Erstellen Sie einen neuen erweiterten Bericht.",
  "msg_report_data_refresh_err_detail_row_limit_exceeded": "Wir konnten Ihre Berichtsdaten nicht aktualisieren, da der Bericht 300 Zeilen über dem Grenzwert liegt. Reduzieren Sie die Größe Ihres Berichts, indem Sie die Optionen im linken Bereich anpassen. <1>Weitere Informationen</1>",
  "msg_report_data_refresh_err_detail_sheet_access": "Beim Aktualisieren Ihrer erweiterten Berichtsdaten ist ein Fehler aufgetreten. Aktualisieren Sie die Sheetberechtigungen oder erstellen Sie einen neuen erweiterten Bericht.",
  "msg_report_is_too_big_to_load": "Dieser Bericht ist zu groß, um geladen zu werden.",
  "msg_save_report_analytics_page": "Diesen Bericht auf der Seite „Analysen“ speichern.",
  "msg_search_contacts": "Kontakte suchen",
  "msg_settings_people_manage_info": "Verwalten Sie den <1>Anmeldezugriff</1> und die <3>Berechtigungen</3> für Personen in Ihrem Konto.<br /><5>Kontaktieren Sie uns</5>, um Änderungen an Ihrem Abonnement vorzunehmen.",
  "msg_share_report": "Kopieren Sie die URL mithilfe der untenstehenden Schaltfläche „Kopieren“, um diesen Bericht freizugeben:",
  "msg_sheet_collaborators": "Sheetkollaborateure in verknüpften Projekten werden hier nicht angezeigt. Um die Sheetfreigabe zu aktualisieren oder Sheetkollaborateure hinzuzufügen, verwalten Sie diese in Smartsheet. <0>Weitere Informationen</0>",
  "msg_signup_loading_transition": "Ihr kostenloses Testkonto wird eingerichtet.",
  "msg_sort_by_last_name": "Sortieren nach: Nachname",
  "msg_specify_budget_in_unit": "Budget in {{curUnit}} festlegen",
  "msg_sso_configuration_info": "Die empfohlene automatische Konfiguration vereinfacht die SSO-Konfiguration und unterstützt standardmäßig mehrere Zertifikate. Die manuelle Konfiguration unterstützt nur ein Zertifikat gleichzeitig.",
  "msg_sso_enabled": "SSO ist derzeit für Ihr Konto aktiviert.",
  "msg_sso_info": "Die Verwendung von Single Sign-On (SSO) für die Kennwortverwaltung und das Benutzer-Provisioning ermöglicht Unternehmen bessere Kontrolle über den Zugriff auf Resource Management, um sicherzustellen, dass sich die richtigen Personen sicher beim Dienst anmelden können.",
  "msg_sso_login_failure": "Sie konnten nicht in Smartsheet Resource Management angemeldet werden.",
  "msg_sso_not_configured": "SSO ist nicht für Ihr Konto konfiguriert.",
  "msg_sso_unconfigurable": "SSO kann nicht direkt in Resource Management konfiguriert werden, da Ihre Organisation zurzeit die Smartsheet-Authentifizierung verwendet. SSO-Einstellungen können im Smartsheet Admin Center verwaltet werden.",
  "msg_terms": "Bedingungen",
  "msg_thanks": "Vielen Dank!",
  "msg_there_are_no_approvals_matching_your_current_filters": "Es gibt keine Genehmigungen, die mit Ihren aktuellen Filtern übereinstimmen.",
  "msg_to_inactive_project": "zu inaktivem Projekt",
  "msg_transfer_project_ownership_to_remove_user": "{{member.name}} ist der Projektinhaber und kann nicht entfernt werden. Übertragen Sie die Inhaberschaft über den Tab „Projekteinstellungen“.",
  "msg_type_and_press_enter_to_add_task": "Tippen und drücken Sie die Eingabetaste, um eine Aufgabe hinzuzufügen.",
  "msg_cant_edit_remove_unmanaged_resource": "{{member.name}} ist eine nicht verwaltete Ressource. Führen Sie in den Kontoeinstellungen ein Upgrade für den Benutzer durch, um ihn zu bearbeiten oder aus diesem Projekt zu entfernen.",
  "msg_cant_add_unmanaged_resource": "{{member.name}} ist eine nicht verwaltete Ressource. Führen Sie in den Kontoeinstellungen ein Upgrade für den Benutzer durch, um ihn diesem Projekt hinzuzufügen.",
  "msg_upload_failed": "Hochladen fehlgeschlagen",
  "msg_upload_succeeded": "Hochladen abgeschlossen",
  "msg_user_default_login_tooltip": "Diese Person hat sich noch nicht über SSO angemeldet. Um zu wechseln, bitten Sie die Person, sich abzumelden und über SSO erneut anzumelden.",
  "msg_user_smar_login_tooltip": "Diese Person hat sich noch nicht über die Smartsheet-Authentifizierung angemeldet. Um zu wechseln, bitten Sie die Person, sich abzumelden und über die Smartsheet-Authentifizierung erneut anzumelden.",
  "msg_wait_while_load_assignments": "Warten Sie, bis die Zuweisungen geladen werden.",
  "msg_welcome_to_resource_management_by_smartsheet": "Willkommen bei Smartsheet Resource Management",
  "msg_work_item_description_placeholder": "Die Arbeit, Zuweisung usw. beschreiben",
  "msg_you_dont_own_these_projects_and_theyre_not_assigned_to_you": "Sie sind nicht Inhaber dieser Projekte und sie wurden Ihnen nicht zugewiesen.",
  "msg_saved_form_already_exists": "ist bereits vorhanden. Ersetzen?",
  "msg_you_do_not_have_admin_access": "Stellen Sie sicher, dass Sie über Administratorberechtigungen für den gesuchten Arbeitsbereich verfügen. Sie können in <0>Smartsheet</0> Zugriff anfordern oder einen neuen Zielarbeitsbereich erstellen.",
  "msg_no_matching_results": "Keine Treffer",
  "msg_report_filter_list": "Sie befinden sich in der Liste der Berichtsfilter. Wählen Sie aus, welche Daten Ihr Bericht umfassen soll.",
  "msg_report_filter_show_group": "Sie befinden sich in der Anzeigegruppe „Berichtsfilter“. Sie können auswählen, welche Optionen in jeder Kategorie angezeigt werden sollen. Wählen Sie dazu alle oder keine aus, markieren Sie die Kontrollkästchen für die Mehrfachauswahl, entfernen Sie die Markierung oder aktivieren Sie eine benachbarte Schaltfläche, um nur eine Option auszuwählen.",
  "lbl_getting_information": "Informationen werden abgerufen",
  "lbl_actions": "Aktionen",
  "lbl_delete_phase": "Phase löschen",
  "lbl_chars_left": "verbleibend",
  "lbl_set_to": "Festlegen auf",
  "msg_no_ppl_match": "Keine Person stimmt mit Ihren Kriterien überein.",
  "lbl_user_info_summary": "Zusammenfassung der Benutzerinformationen",
  "lbl_saved_reports": "Gespeicherte Berichte",
  "lbl_no_active_assignments": "Keine aktiven Zuweisungen.",
  "msg_entries_not_shown": "Es werden keine Einträge für Projekte anderer Inhaber angezeigt.",
  "msg_provide_note": "Fügen Sie einen Hinweis zu dieser Genehmigung hinzu. Dies ist zum Ablehnen erforderlich.",
  "msg_taking_longer_than_expected": "Das dauert länger als gedacht. Einen Moment bitte",
  "msg_want_to_make_adjustments": "Möchten Sie Änderungen vornehmen oder schneller arbeiten? Sie können die Filter weiterhin benutzen, um den Bericht zu verfeinern oder seinen Umfang einzugrenzen.",
  "msg_calculating_time_entries": "~{{timeEntriesCount}} Zeiteinträge werden berechnet.",
  "lbl_just_a_few_seconds": "nur wenige Sekunden",
  "lbl_less_than_a_min": "weniger als eine Minute",
  "lbl_about_a_min": "etwa eine Minute",
  "msg_add_users_intro": "Verwenden Sie diese Seite, um in Ihrem Konto neue Personen hinzuzufügen oder bestehende Personen zu aktualisieren.",
  "msg_add_projects_intro": "Verwenden Sie diese Seite, um in Ihrem Konto neue Projekte hinzuzufügen oder bestehende Projekte zu aktualisieren.",
  "msg_more_details_can_be_found": "Weitere Informationen finden Sie in unserem <0>Hilfeartikel</0>. Haben Sie Fragen? Dann <1>kontaktieren</1> Sie unser Supportteam.",
  "lbl_step_1_prepare_your_data": "1. Daten vorbereiten",
  "lbl_step_1_content_users": "Hinweis: Bearbeiten Sie nicht die Spalte „Benutzer-ID“. Sie wird verwendet, um bestehenden Personen Aktualisierungen zuzuweisen.",
  "lbl_step_1_content_projects": "Hinweis: Bearbeiten Sie nicht die Spalte „Projekt-ID“. Sie wird verwendet, um bestehenden Projekten Aktualisierungen zuzuweisen.",
  "lbl_step_1_contents_create_users": "Verwenden Sie diese Vorlage, um Ihrem Konto neue Personen hinzuzufügen.",
  "lbl_step_1_contents_create_projects": "Verwenden Sie diese Vorlage, um Ihrem Konto neue Projekte hinzuzufügen.",
  "lbl_step_1_contents_edit_users": "Exportieren Sie eine Liste aller Personen in Ihrem Unternehmen, um ihre Attribute zu aktualisieren.",
  "lbl_step_1_contents_edit_projects": "Exportieren Sie eine Liste aller Projekte in Ihrem Unternehmen, um ihre Attribute zu aktualisieren.",
  "lbl_people_lc": "Personen",
  "lbl_projects_lc": "Projekte",
  "lbl_add_update_projects": "Projekte hinzufügen/aktualisieren",
  "lbl_download_template": "Vorlage herunterladen",
  "lbl_update_existing": "Aktualisieren bestehender",
  "lbl_export_project_list": "Projektliste exportieren",
  "lbl_step_2_upload_data_users": "2. Aktualisierte Personenliste hochladen",
  "lbl_step_2_upload_data_projects": "2. Aktualisierte Projektliste hochladen",
  "msg_select_csv_file": "Wählen Sie Ihre aktualisierte CSV-Datei aus und laden Sie sie hoch, um {{importType}} hinzuzufügen oder zu aktualisieren. Sie erhalten nach Abschluss eine Bestätigung per E-Mail.",
  "lbl_send_invitation_new_licensed_users": "Senden Sie neuen lizenzierten Benutzern eine Einladung.",
  "lbl_upload": "Hochladen",
  "lbl_processing": "Wird verarbeitet ...",
  "lbl_add_new": "Neuen {{importType}} hinzufügen",
  "msg_import_in_progress": "Import in Bearbeitung",
  "msg_errors_during_import": "Beim Importieren wurden Fehler gefunden.",
  "lbl_import_complete": "Import abgeschlossen",
  "lbl_added_colon": "Hinzugefügt: {{number}}",
  "lbl_updated_colon": "Aktualisiert: {{number}}",
  "lbl_failed_colon": "Fehlgeschlagen: {{number}}",
  "lbl_download_error_report_csv": "Fehlerbericht als CSV herunterladen",
  "msg_working_on_import_background": "Wir arbeiten im Hintergrund an diesem Import. Sie erhalten eine E-Mail, sobald der Import abgeschlossen ist. Einen Link zu dieser Seite finden Sie unten auf der Seite <0>{{pageTitle}}</0>.",
  "msg_rows_with_errors": "Fehlerhafte Zeilen wurden nicht hinzugefügt oder aktualisiert. Sie können einen Bericht dieser Fehler im CSV-Format herunterladen, um sie zu beheben und es erneut zu versuchen. Möglicherweise liegen weitere Fehler vor. Überprüfen Sie vor einem erneuten Upload alle Daten. <0>Weitere Informationen</0> zum Importieren und Aktualisieren von {{importType}}.",
  "msg_completed_processing": "Wir haben die Bearbeitung des kürzlich hinzugefügten {{importType}} abgeschlossen und ihn in Ihrem Unternehmen aktualisiert.",
  "lbl_imports_last_30_days": "Importe der letzten 30 Tage",
  "lbl_characters": "Zeichen ",
  "lbl_character": "Zeichen ",
  "lbl_remaining_lc": "verbleibend",
  "msg_no_items_match_criteria": "Kein Element stimmt mit Ihren Kriterien überein.",
  "lbl_future_scheduled_lc": "künftig geplante",
  "lbl_loading_details": "Details werden geladen",
  "lbl_try_again": "Erneut versuchen",
  "err_must_not_be_blank": "Darf nicht leer sein",
  "err_cannot_be_more_than_255_char": "Kann maximal 255 Zeichen umfassen",
  "lbl_view_name": "Name der Ansicht",
  "lbl_project_timelines": "Projektzeitpläne",
  "msg_shorten_time_frame": "Kürzen Sie den Zeitrahmen oder schränken Sie den Filter auf weniger Personen oder Projekte ein und versuchen Sie anschließend, den Bericht erneut zu laden. Sie können auch eine E-Mail mit einem Link zu einer CSV-Datei dieses Berichts anfordern.",
  "msg_underlying_data_requested": "Zugrunde liegende Daten angefordert. Wir werden Sie per E-Mail benachrichtigen, wenn sie zum Download bereitstehen. Dies kann eine Weile dauern.",
  "msg_no_report_values": "Keine Werte für diesen Bericht.",
  "msg_report_only_includes_data": "Dieser Bericht umfasst nur Daten, für die Sie zum Anzeigen berechtigt sind.",
  "msg_we_have_logged_error": "Wir haben den Fehler protokolliert und werden ihn untersuchen. Versuchen Sie doch in der Zwischenzeit einen Neustart:",
  "msg_budgets_report_includes_all": "Dieser Bericht umfasst alle angefallenen, künftig geplanten sowie Budgetdaten für die Gesamtdauer von Projekten, deren Daten sich mit den folgenden überschneiden:",
  "lbl_run_report": "Bericht ausführen",
  "lbl_request_underlying_data": "Zugrunde liegende Daten anfordern",
  "lbl_selected": "Ausgewählt",
  "lbl_selected_lc": "ausgewählt",
  "lbl_project_lc": "Projekt",
  "msg_change_client_name": "Diese Aktion ändert den Kunden für",
  "msg_change_project_type": "Diese Aktion ändert den Projekttyp für",
  "msg_change_project_owner": "Diese Aktion ändert den Projektinhaber für",
  "msg_change_tags": "Diese Aktion ändert die Tags für",
  "msg_change_custom_fields": "Diese Aktion ändert die benutzerdefinierten Felder für",
  "msg_this_will_archive_the": "Diese Aktion archiviert das",
  "msg_this_will_add_selected_tags": "Diese Aktion fügt die ausgewählten Tags hinzu",
  "msg_this_will_remove_selected_tags": "Diese Aktion entfernt die ausgewählten Tags",
  "msg_updating_client": "Kunde wird aktualisiert für",
  "msg_updating_project_owner": "Projektinhaber wird aktualisiert für",
  "msg_describe_work_item_placeholder": "Beschreiben Sie die Arbeit, das Ergebnis oder die Leistung",
  "msg_show_hide_phase_items": "Arbeitselemente der Phase {{phaseName}} anzeigen/ausblenden",
  "msg_edit_phase": "Phase {{phaseName}} bearbeiten",
  "msg_show_hide_project_items": "Arbeitselemente des Projekts {{projectName}} anzeigen/ausblenden",
  "msg_edit_project": "Projekt {{projectName}} bearbeiten",
  "lbl_hours_lc": "Stunden",
  "lbl_hours_per_day_abbr": "Std./T.",
  "lbl_new": "Neu",
  "lbl_add_team_member_to": "Teammitglied zu {{parentLabel}} hinzufügen",
  "lbl_add_work_item_to": "Arbeitselement zu {{parentLabel}} hinzufügen",
  "lbl_available_hours_lc": "verfügbare Stunden",
  "lbl_remaining_or_budget_forecast": "verbleibend oder {{budgetForecastPercent}}",
  "lbl_forecasted_over_or_under_budget": "{{overUnder}} dem Budget prognostiziert oder {{budgetForecastPercent}}",
  "lbl_under_lc": "unter",
  "lbl_over_lc": "über",
  "lbl_expense_budget_lc": "Ausgabenbudget",
  "lbl_project_budget_lc": "Projektbudget",
  "lbl_expensed_lc": "ausgegeben",
  "lbl_incurred_lc": "angefallen",
  "lbl_add_description": "Beschreibung hinzufügen",
  "lbl_automatic_configuration": "Automatische Konfiguration",
  "lbl_recommended_paren": "(Empfohlen)",
  "lbl_manual_configuration": "Manuelle Konfiguration",
  "lbl_manage_sheet_sharing": "Sheetfreigabe verwalten",
  "lbl_cert_must_be_PEM": "Zertifikat muss PEM-kodiert sein (d. h. .pem-, .cert-, .crt-, .cer-Dateien)",
  "msg_auto_provision_auth_users": "Automatisiertes Benutzer-Provisioning für authentifizierte Benutzer durchführen, die nicht im Konto vorhanden sind (nur mit E-Mail-Adresse erstellt)",
  "lbl_selected_phases": "Ausgewählte Phasen verwenden",
  "lbl_no_display_props": "Keine Eigenschaften verfügbar",
  "msg_approval_audit_error": "Leider ist ein unerwartetes Problem aufgetreten. Wenn dieses Problem weiterhin auftritt, wenden Sie sich mit den Details an den Support unter ",
  "msg_download_error": "Beim Versuch, die folgende Datei herunterzuladen, ist ein Fehler aufgetreten: ",
  "lbl_audit_log_req": "Audit-Protokoll angefordert",
  "msg_audit_log_req": "Unten in der Tabelle „Letzte Anforderungen“ wird ein Link zum Herunterladen angezeigt. Dies kann ein paar Minuten dauern.",
  "lbl_audit_log_expense": "Audit-Protokolle für Ausgaben",
  "lbl_download": "Herunterladen",
  "lbl_proj_singular": "Projekt wurde",
  "lbl_proj_plural": "Projekte wurden",
  "lbl_zero_proj": "0 Projekte",
  "lbl_success_lc": "Erfolg",
  "lbl_important": "<1>Wichtig:</1>",
  "lbl_edit_properties": "Eigenschaften bearbeiten",
  "lbl_error": "Fehler",
  "lbl_change": "Ändern",
  "lbl_select_new": "Auswahl: neuer",
  "lbl_approver_project": "Genehmiger: ",
  "lbl_successfully": " erfolgreich.",
  "msg_update": "Aktualisierung abgeschlossen",
  "lbl_of": "von",
  "msg_update_failed": "Aktualisierungen fehlgeschlagen.",
  "msg_update_lc": "erfolgreich aktualisiert.",
  "lbl_edit_desc": "Beschreibung bearbeiten",
  "lbl_no_select": "keine Auswahl getroffen",
  "lbl_current_filter_select": " : Aktuell festgelegt auf ",
  "msg_getting_ready": "Wird vorbereitet …",
  "msg_retry_update": "Um zu versuchen, fehlgeschlagene Aktualisierungen zu beheben, klicken Sie auf „Wiederholen“.",
  "msg_error_support": "Wenn dieser Fehler weiterhin auftritt, kontaktieren Sie den Support. ",
  "lbl_curr_dropdown_val": "Aktueller Dropdown-Wert ist ",
  "msg_cancel_view": "Abbrechen und zur Anzeigeliste zurückkehren",
  "msg_updating_tags": "Tags werden aktualisiert für",
  "msg_updatings_proj_type": "Projekttyp wird aktualisiert für",
  "msg_start_your_trial_for_free": "Starten Sie Ihre kostenlose {{trialDuration}}-tägige Testphase",
  "msg_create_free_account": "Mein kostenloses Konto erstellen",
  "msg_I_agree_to_terms_and_conditions": "Ich stimme den <0>Bedingungen</0> zu und bestätige die Verarbeitung meiner personenbezogenen Daten durch Smartsheet Resource Management gemäß der <1>Datenschutzrichtlinie</1>.",
  "msg_something_went_wrong_signup": "Hoppla! Bei Ihrer Registrierung ist ein Problem aufgetreten. Kontaktieren Sie uns über unsere <0>Supportseite</0>.",
  "msg_edit_phase_settings": "Phaseneinstellungen bearbeiten",
  "msg_edit_project_settings": "Projekteinstellungen bearbeiten",
  "msg_shift_phase_dates": "Phasendaten verschieben",
  "msg_shift_project_dates": "Projektdaten verschieben",
  "lbl_utilization_lc": "Auslastung",
  "lbl_target_utilization_lc": "Zielauslastung",
  "msg_organization_using_smar_auth_modifification_info": "Ihre Organisation verwendet zurzeit die Smartsheet-Authentifizierung zur Anmeldung bei Resource Management. <1>Kontaktieren Sie den Support</1>, wenn Sie diese Einstellung ändern möchten.",
  "lbl_user_auto_provisioning": "Automatisiertes Benutzer-Provisioning",
  "lbl_edit": "Bearbeiten",
  "lbl_permission_level": "Berechtigungsstufe",
  "msg_activate_smar_auth_info": "Aktivieren Sie diese Option, damit sich Benutzer mit ihrem Smartsheet-Konto anmelden können. <1>Weitere Informationen</1>",
  "lbl_activate_smar_auth": "Smartsheet-Authentifizierung aktivieren",
  "msg_please_link_rm_account_with_smar": "Um die Smartsheet-Authentifizierung zu ermöglichen, <1>verknüpfen Sie bitte Ihr Resource Management-Konto mit Ihrem Smartsheet-Konto.</1>",
  "lbl_setup_sso": "SSO einrichten",
  "lbl_smartsheet_authentication": "Smartsheet-Authentifizierung",
  "lbl_edit_sso": "SSO-Einstellungen bearbeiten",
  "msg_disabled_filter_info": "Diese Option ist für erweiterte Berichte nicht verfügbar. Erstellen Sie einen <0>neuen Bericht</0>, um diese Option zu nutzen.",
  "msg_people_in_org_have_logged_in_using_sso": "Personen in Ihrer Organisation haben sich bereits über SSO angemeldet. Eine fehlerhafte Bearbeitung Ihrer SSO-Einstellungen kann dazu führen, dass sich Benutzer nicht anmelden können. Wenden Sie sich zum sicheren Aktualisieren Ihrer SSO-Einstellungen <1>an uns</1>.",
  "err_issue_enabling_sso": "Es gab ein Problem bei der Aktivierung der SSO-Authentifizierung: {{error}}. Falls das Problem fortbesteht, wenden Sie sich bitte an den Support.",
  "err_activating_smar_auth": "Aktivieren der Smartsheet-Authentifizierung fehlgeschlagen",
  "err_issue_updating_smar_auth": "Es gab ein Problem bei der Aktualisierung der Smartsheet-Authentifizierung: {{error}}. Falls das Problem fortbesteht, wenden Sie sich bitte an den Support.",
  "err_issue_enabling_smar_auth": "Es gab ein Problem bei der Aktivierung der Smartsheet-Authentifizierung: {{error}}. Falls das Problem fortbesteht, wenden Sie sich bitte an den Support.",
  "msg_report_unmanaged_resources_admin": "Um nicht verwaltete Ressourcen in diesen Bericht aufzunehmen, führen Sie unter <0>Kontoeinstellungen</0> ein Upgrade dieser durch.",
  "msg_report_unmanaged_resources": "Um nicht verwaltete Ressourcen in diesen Bericht aufzunehmen, bitten Sie Ihren Administrator, in den Kontoeinstellungen ein Upgrade dieser durchzuführen.",
  "lbl_adjust_refresh_settings": "Einstellungen für die Datenaktualisierung anpassen",
  "lbl_view_advanced_report": "Erweiterten Bericht anzeigen",
  "lbl_save_and_refresh_data": "Änderungen speichern und Daten aktualisieren",
  "lbl_refresh_data": "Daten aktualisieren",
  "lbl_delete_report": "Bericht löschen",
  "lbl_toggle_reports_control_menu": "Steuerungsmenü für Berichte umschalten",
  "lbl_advanced_report": "Erweiterter Bericht",
  "lbl_create_new_advanced_report": "Neuen erweiterten Bericht erstellen",
  "msg_open_the_advanced_report_in_smartsheet": "Erweiterten Bericht in Smartsheet öffnen",
  "lbl_advanced_report_edit_modal_title": "Einstellungen für die Datenaktualisierung",
  "lbl_advanced_report_modal_title": "Erweiterten Bericht erstellen",
  "msg_advanced_report_modal_description": "Senden Sie Ihre Daten an ein Quellsheet und an einen Smartsheet-Bericht. Sie können den Bericht aktualisieren und die Einstellungen auf der Seite „Analyse“ anpassen.",
  "lbl_advanced_report_modal_name_field": "Name",
  "lbl_advanced_report_modal_destination_field": "Ziel",
  "lbl_advanced_report_modal_data_refresh_field": "Einstellung für die Datenaktualisierung",
  "lbl_advanced_report_modal_data_refresh_auto_name": "Automatisch",
  "lbl_advanced_report_modal_data_refresh_auto_description": "Erweiterte Berichtsdaten werden alle 24 Stunden aktualisiert.",
  "lbl_advanced_report_modal_data_refresh_manual_name": "Manuell",
  "lbl_advanced_report_modal_data_refresh_manual_description": "Die Daten müssen auf der Seite „Analyse“ manuell aktualisiert werden.",
  "lbl_advanced_report_modal_close_button": "Schließen",
  "lbl_advanced_report_modal_create_button": "Erstellen",
  "msg_advanced_report_modal_need_lws": "Bitten Sie Ihren RM-Administrator, die Smartsheet-Authentifizierung zu aktivieren, um diese Funktion verwenden zu können.",
  "lbl_destination_dropdown_select_workspace": "Arbeitsbereich auswählen",
  "lbl_destination_dropdown_search_for_workspace": "Nach einem Arbeitsbereich suchen",
  "msg_advanced_report_ready_title": "Ihr erweiterter Bericht ist fertig!",
  "msg_advanced_report_ready": "Sie können jederzeit über die Seite „Analyse“ auf den Status Ihres erweiterten Berichts zugreifen und ihn anzeigen.",
  "lbl_advanced_report_view_btn": "Erweiterten Bericht anzeigen",
  "msg_advanced_report_creating_title": "Erstellen Ihres erweiterten Berichts",
  "msg_advanced_report_creating": "Dies kann ein paar Minuten dauern. Sie können den Bericht mit Beispieldaten jetzt in der Vorschau anzeigen. Sobald der Bericht fertig ist, werden Ihre tatsächlichen Daten eingefügt.",
  "lbl_advanced_report_preview_btn": "Berichtvorschau ...",
  "msg_advanced_report_creation_footer": "Sie können den Dialog schließen. Sie können später zurückkehren und finden den Bericht dann in Ihren gespeicherten Berichten oder im ausgewählten Arbeitsbereich.",
  "lbl_advanced_report_creation_footer_link": "Sehen Sie sich Tipps zu erweiterten Berichten an.",
  "lbl_create_advanced_report": "Erweiterten Bericht erstellen",
  "lbl_edit_settings": "Berichtseinstellungen bearbeiten",
  "err_create_report_failed_generic": "Bei der Erstellung Ihres Berichts ist ein Fehler aufgetreten. Versuchen Sie es erneut.",
  "err_edit_report_failed_generic": "Beim Bearbeiten Ihres Berichts ist ein Fehler aufgetreten. Versuchen Sie es erneut.",
  "err_create_report_failed_invalid_name": "Der Name des Berichts darf keine Sonderzeichen oder Emojis enthalten.",
  "msg_session_expired_title": "Ihre Sitzung ist abgelaufen",
  "msg_session_expired_description": "Ihre Sitzung in diesem Fenster ist nicht mehr gültig. Klicken Sie auf die Schaltfläche unten, um die Seite zu aktualisieren. Möglicherweise müssen Sie sich erneut anmelden.",
  "lbl_refresh_page": "Seite aktualisieren",
  "msg_refresh_advanced_report": "Aktualisieren Sie die Daten über das Menü, um Ihre Filter auf den erweiterten Bericht anzuwenden. Ihr gespeicherter RM-Bericht wird ebenfalls aktualisiert.",
  "msg_advanced_report_too_many_cells": "Ihr Bericht enthält zu viele Spalten oder zu viele Zeilen. Reduzieren Sie die Größe Ihres Berichts, indem Sie die Optionen im linken Bereich anpassen. <0>Weitere Informationen</0>",
  "msg_advanced_report_too_many_columns": "Ihr Bericht enthält zu viele Spalten. Reduzieren Sie die Größe Ihres Berichts, indem Sie die Optionen im linken Bereich anpassen. <0>Weitere Informationen</0>",
  "msg_advanced_report_too_many_rows": "Ihr Bericht enthält zu viele Zeilen. Reduzieren Sie die Größe Ihres Berichts, indem Sie die Optionen im linken Bereich anpassen. <0>Weitere Informationen</0>"
}