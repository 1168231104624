import React from 'react';

const AddIcon = () => (
  <svg viewBox="0 0 14 14">
    <path
      d="M10,2.67H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1v-7A1,1,0,0,0,10,2.67Zm-1,5H7v2H6v-2H4v-1H6v-2H7v2H9Z" // eslint-disable-line max-len
    />
  </svg>
);

export default AddIcon;
