import React from 'react';

export const SplitIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      d="M14,9h5a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2H14a0,0,0,0,1,0,0V9A0,0,0,0,1,14,9Z"
    />
    <path
      d="M3,9H8a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2H3a0,0,0,0,1,0,0V9A0,0,0,0,1,3,9Z"
      transform="translate(13 24) rotate(-180)"
    />
    <rect x="11" y="5" width="2" height="14" />
  </svg>
);

export default SplitIcon;
