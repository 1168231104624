import React from 'react';
import PropTypes from 'prop-types';

export const OutSickIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <title>Sick_Small</title>
    <rect width="24" height="24" fill="#e81a56" />
    <path d="M4.22,14.42a4.27,4.27,0,0,0,0,6.06l.3.3a4.27,4.27,0,0,0,6.06,0l1.21-1.21L5.43,13.21Z" fill="#fff" />
    <rect x="8" y="8" width="9" height="9" transform="translate(-5.18 12.5) rotate(-45)" fill="#fff" />
    <path d="M14.42,4.22,13.21,5.43l6.36,6.36,1.21-1.21a4.27,4.27,0,0,0,0-6.06l-.3-.3A4.27,4.27,0,0,0,14.42,4.22Z" fill="#fff" />
    <rect x="8" y="12" width="1" height="1" fill="#e81a56" />
    <rect x="10" y="10" width="1" height="1" fill="#e81a56" />
    <rect x="12" y="8" width="1" height="1" fill="#e81a56" />
    <rect x="14" y="10" width="1" height="1" fill="#e81a56" />
    <rect x="12" y="12" width="1" height="1" fill="#e81a56" />
    <rect x="10" y="14" width="1" height="1" fill="#e81a56" />
    <rect x="12" y="16" width="1" height="1" fill="#e81a56" />
    <rect x="14" y="14" width="1" height="1" fill="#e81a56" />
    <rect x="16" y="12" width="1" height="1" fill="#e81a56" />
  </svg>
);

OutSickIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default OutSickIcon;
