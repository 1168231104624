import invert from 'lodash/invert';
import { FilterId } from '@smartsheet/rm-components';
import { defaultHeaders } from './utils';

export type InstantType = 'week' | 'month';

// These values are used in the API call to capacity view
export type GroupType = 'discipline' | 'resource type';

export interface CapacityDataRow {
  groupName?: string;
  capacities?: {
    [instant: string]: {
      count: number;
      hours: number;
      filtered_hours: number;
    };
  };
  workloads?: {
    [instant: string]: {
      count: number;
      hours: number;
      filtered_hours: number;
    };
  };
  placeholder_workloads?: {
    [instant: string]: {
      count: number;
      hours: number;
    };
  };
}

export interface CapacityDataGroups {
  [id: string]: CapacityDataRow;
}

export interface CapacityData {
  instant_type: InstantType;
  group_type: GroupType;
  groups: CapacityDataGroups;
}

export interface AssignableFilters {
  filters: {
    project_id?: Filter;
    project_type?: Filter;
  };
}

export interface Filter {
  operation: 'inclusion' | 'exclusion';
  values: FilterId[];
}

export interface PeopleFilters {
  filters: {
    // TODO: Define people filters data format
  };
}

export interface CapacityFilterPayload {
  assignable_filters?: AssignableFilters;
  people_filters?: PeopleFilters;
}

interface CapacityRequestBody {
  instant_type: string;
  instants: number[];
  group_type: string;
  group_ids: string[];
  view: 'capacity' | 'workload' | 'all';
  assignable_filters?: AssignableFilters;
  people_filters?: PeopleFilters;
}

/**
 * Calls the Capacity View API to retrieve the capacity data
 */
export async function getCapacityData(
  instantType: InstantType,
  instants: number[],
  groups: string[],
  groupType: string,
  view: 'capacity' | 'workload' | 'all',
  filterPayload?: CapacityFilterPayload
) {
  const requestBody: CapacityRequestBody = {
    instant_type: instantType,
    instants,
    group_type: groupType,
    group_ids: groups,
    view,
  };
  if (filterPayload?.assignable_filters) {
    requestBody.assignable_filters = filterPayload.assignable_filters;
  }
  if (filterPayload?.people_filters) {
    requestBody.people_filters = filterPayload.people_filters;
  }
  // TODO: fix this implementation with error handling, auth and missing parameters (filters)
  const response = await window.fetch('api/v1/capacity_view', {
    method: 'post',
    headers: {
      ...defaultHeaders,
    },
    body: JSON.stringify(requestBody),
  });

  return response.json() as Promise<CapacityData>;
}

const fieldToType: { [key: string]: GroupType } = {
  discipline: 'discipline',
  role: 'resource type',
};

export const fieldToTranslationKey: { [key: string]: string } = {
  discipline: 'lbl_discipline',
  'resource type': 'lbl_role',
};

export const mapGroupFieldToType = (field: string) => {
  return fieldToType[field];
};

export const mapGroupTypeToField = (type: GroupType) => {
  return invert(fieldToType)[type];
};

export const mapFieldToTranslationKey = (field: string) => {
  return fieldToTranslationKey[field];
};
