import { getCsrfToken, getXsrfToken } from '@smartsheet/api.utils';

export const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-CSRF-Token': getCsrfToken(),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  'x-smar-xsrf': getXsrfToken(),
} as RequestInit['headers'];

export const MAX_PER_PAGE = 9999;
