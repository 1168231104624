import PropTypes from 'prop-types';
import React from 'react';

import iconStyle from './style.scss';

const ProjectTypeIcon = ({ type, style }) => (
  <div className={iconStyle[type]} style={style} />
);

ProjectTypeIcon.propTypes = {
  type: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ProjectTypeIcon;
