import { defaultHeaders } from './utils';

export async function validateSession() {
  const resp = await window.fetch(
    `${window.APP_ENDPOINT || ''}/api/v1/sessions/validate_session`,
    {
      method: 'POST',
      headers: {
        ...defaultHeaders,
      },
    }
  );

  return resp.status !== 401;
}
