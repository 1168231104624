import PropTypes from 'prop-types';
import React from 'react';

import style from './style.scss';

const getPopoverStyle = (topStyle, popoverStyle, topStyleLeft) => {
  if (topStyleLeft) return { ...popoverStyle, ...topStyleLeft };
  if (topStyle) return { ...popoverStyle, ...topStyle };
  return popoverStyle;
};

const OverlayPopover = ({
  placement,
  style: popoverStyle,
  children,
  arrowOffsetTop,
  arrowOffsetLeft,
  topStyle,
  topStyleLeft,
  refSetter,
  customArrowOffsetLeft
}) => (
  <div
    className={topStyleLeft ? style['top-left'] : style[placement]}
    style={getPopoverStyle(topStyle, popoverStyle, topStyleLeft)}
    ref={refSetter}
  >
    {children}
    <div
      className={style.arrow}
      style={{
        top: arrowOffsetTop,
        left: customArrowOffsetLeft || arrowOffsetLeft
      }}
    />
  </div>
);

OverlayPopover.propTypes = {
  placement: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  arrowOffsetTop: PropTypes.string,
  arrowOffsetLeft: PropTypes.string,
  customArrowOffsetLeft: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  topStyle: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  topStyleLeft: PropTypes.shape({
    top: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  refSetter: PropTypes.func,
};

export default OverlayPopover;
