import React from 'react';
import PropTypes from 'prop-types';

const defaultStyle = { fill: 'gray', height: 14, width: 14 };

const CloseIcon = ({ style = defaultStyle }) => (
  <svg style={style} viewBox="0 0 14 14">
    <path
      d="M13,14a1,1,0,0,1-.71-.29l-12-12A1,1,0,0,1,1.71.29l12,12a1,1,0,0,1,0,1.42A1,1,0,0,1,13,14Z"
    />
    <path
      d="M0,13a1,1,0,0,1,.29-.71l12-12a1,1,0,1,1,1.42,1.42l-12,12a1,1,0,0,1-1.42,0A1,1,0,0,1,0,13Z"
    />
  </svg>
);

CloseIcon.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
    /* NOTE possibly other CSS declarations */
  }),
};

export default CloseIcon;
