import translationDE_DE from './de_DE/translation.de_DE.json';
import translationEN_US from './en_US/translation.en_US.json';
import translationES_LA from './es_LA/translation.es_LA.json';
import translationFR_FR from './fr_FR/translation.fr_FR.json';
import translationIT_IT from './it_IT/translation.it_IT.json';
import translationJA_JP from './ja_JP/translation.ja_JP.json';
import translationPT_BR from './pt_BR/translation.pt_BR.json';
import translationRU_RU from './ru_RU/translation.ru_RU.json';

export const resources = {
  'de-DE': { translation: translationDE_DE },
  'en-US': { translation: translationEN_US },
  'es-LA': { translation: translationES_LA },
  'fr-FR': { translation: translationFR_FR },
  'it-IT': { translation: translationIT_IT },
  'ja-JP': { translation: translationJA_JP },
  'pt-BR': { translation: translationPT_BR },
  'ru-RU': { translation: translationRU_RU },
};
