import React from 'react';
import PropTypes from 'prop-types';

export const OnVacationIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <rect width="24" height="24" fill="#ff7e00" />
    <path d="M17.45,5.55l.48-.61a.92.92,0,0,0-1.41-1.13L16,4.43A9,9,0,0,0,4.19,6.36l.08.06a3.51,3.51,0,0,1,3.66,0A3.52,3.52,0,0,1,8.8,10l.16.13a3.22,3.22,0,0,1,2.84-.38L4,19.65a.87.87,0,0,0,.24,1.14.89.89,0,0,0,1.17,0l7.85-9.88a3.23,3.23,0,0,1,.27,2.84l.17.14a3.52,3.52,0,0,1,3.65,0,3.52,3.52,0,0,1,.87,3.55l.08.07A9,9,0,0,0,17.45,5.55Z" fill="#fff" />
  </svg>
);

OnVacationIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default OnVacationIcon;
