import React from 'react';

const AllocationIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      // eslint-disable-next-line max-len
      d="M11.47,9.18a2.73,2.73,0,0,1-2.86,2.88A2.74,2.74,0,0,1,5.74,9.18,2.73,2.73,0,0,1,8.61,6.32a2.73,2.73,0,0,1,2.86,2.86Zm-2,1a1.51,1.51,0,0,0,.32-1,1.49,1.49,0,0,0-.32-1,1.08,1.08,0,0,0-.89-.38,1,1,0,0,0-.87.38,1.6,1.6,0,0,0-.3,1,1.62,1.62,0,0,0,.3,1,1.07,1.07,0,0,0,.87.38A1.11,1.11,0,0,0,9.5,10.18Zm5.4-4L16,6.8,9.12,17.86,8,17.2Zm2.56,6.6a2.81,2.81,0,0,1,.8,2.08,2.73,2.73,0,0,1-2.87,2.86,2.73,2.73,0,0,1-2.86-2.86,2.77,2.77,0,0,1,.8-2.08A2.83,2.83,0,0,1,15.39,12,2.87,2.87,0,0,1,17.46,12.74Zm-1.18,3a1.52,1.52,0,0,0,.31-1,1.53,1.53,0,0,0-.31-1,1.09,1.09,0,0,0-.89-.37,1.07,1.07,0,0,0-.87.37,1.53,1.53,0,0,0-.31,1,1.52,1.52,0,0,0,.31,1,1.06,1.06,0,0,0,.87.39A1.07,1.07,0,0,0,16.28,15.79Z"
    />
  </svg>
);

export default AllocationIcon;
