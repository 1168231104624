import React from 'react';
import PropTypes from 'prop-types';

// TODO get this helper moved into a better spot for re-use
import getStyle from '../../Panel/components/StatusPanel/helpers';

const StatusIcon = ({ color }) => (<div className={getStyle(color)} />);

StatusIcon.propTypes = {
  color: PropTypes.string
};

export default StatusIcon;
