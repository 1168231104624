import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from '../Icon/components/InfoIcon';

import checkboxStyle from '../../stylesheets/checkbox.scss';
import checkboxWithLabelStyle from './style.scss';

const CheckedSVG = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
  >
    <rect width='14' height='14' rx='2' fill='#3576BA' />
    <path
      d='M9.29484 3.45568C9.56361 2.99812 10.1503 2.86136 10.5862 3.15468C10.9978 3.43174 11.1225 3.99195 10.8686 4.42414L7.22104 10.6345C6.96745 11.0663 6.37941 11.1268 6.04688 10.7554L3.24332 7.62396C2.90866 7.25015 2.92063 6.67296 3.2705 6.31403C3.64325 5.93162 4.25238 5.94488 4.60891 6.34316L6.41419 8.35982L9.29484 3.45568Z'
      fill='white'
    />
  </svg>
);

const UnCheckedSVG = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
  >
    <rect
      x='0.5'
      y='0.5'
      width='13'
      height='13'
      rx='1.5'
      fill='white'
      stroke='#979797'
    />
  </svg>
);

const Checkbox = ({
  disabled = false,
  label,
  style,
  selected = true,
  onSelect,
  onMultiSelect,
  tooltipText = null,
  doubleLine = false,
}) => {
  const { t } = useTranslation();
  label = t(label);
  return (
    <div style={style}>
      <div
        className={
          doubleLine ? checkboxStyle.doubleWrapper : checkboxStyle.wrapper
        }
      >
        <button
          id={label}
          aria-label={label}
          disabled={disabled}
          type='checkbox'
          role='checkbox'
          checked={selected}
          aria-checked={selected}
          onClick={onMultiSelect}
          className={checkboxStyle.checkbox}
        >
          {selected ? (
            <CheckedSVG label={label} />
          ) : (
            <UnCheckedSVG label={label} />
          )}
        </button>
        <div className={checkboxStyle.divider} />
        <button
          onClick={onSelect}
          disabled={disabled}
          className={`${
            tooltipText ? checkboxStyle.labelWithInfoIcon : checkboxStyle.label
          } ${doubleLine ? checkboxStyle.doubleLine : ''}`}
          title={label}
        >
          {label}
        </button>
        {tooltipText && (
          <div className={checkboxStyle.info}>
            <InfoIcon
              tooltipText={tooltipText}
              iconStyle={checkboxWithLabelStyle.infoIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  doubleLine: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
    position: PropTypes.string,
  }),
  selected: PropTypes.bool, // NOTE aka checked?
  onSelect: PropTypes.func,
  onMultiSelect: PropTypes.func,
  tooltipText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Checkbox;
