import React from 'react';

const EditIcon = () => (
  <svg viewBox="0 0 24 24">
    <path d="M8.32,18.47s-3,1.3-3.5.7.7-3.5.7-3.5l1.4-1.4,2.8,2.8Z" />
    <rect
      x="7.83"
      y="10.08"
      width="8"
      height="4"
      transform="translate(-5.07 11.91) rotate(-45.02)"
    />
    <rect x="14.93" y="5.47" width="3" height="4" transform="translate(-0.47 13.8) rotate(-45)" />
  </svg>
);

export default EditIcon;
