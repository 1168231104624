import React from 'react';
import PropTypes from 'prop-types';

const MyTimeIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.02 18" className={iconStyle}>
    <g >
      <rect fill="none" width="18" height="18" />
      <path d="M20.88,23.08a2.69,2.69,0,0,0,2.1,1A2.75,2.75,0,0,0,25,22.92c.14-.19.28-.39.4-.59.78-1.47,2.45-7.55-2.55-7.65-4.9.1-3.24,6.08-2.55,7.65A6.46,6.46,0,0,0,20.88,23.08Z" transform="translate(-15.13 -14.68)" />
      <polygon points="13.63 10.25 12.38 10.25 12.38 13.51 14.38 14.83 15.07 13.78 13.63 12.84 13.63 10.25" />
      <path d="M28.15,22.68a5,5,0,0,0-4.65,3.21,3,3,0,0,1-.42.05h-.2a4.44,4.44,0,0,1-3-1.36c-1,.21-2.65.42-3.14.84-.69.62-1.57,3.87-1.57,3.87h8.31a5,5,0,1,0,4.71-6.61Zm0,8.75a3.74,3.74,0,0,1-3.37-2.14,3.63,3.63,0,0,1-.38-1.61,3.8,3.8,0,0,1,3.75-3.75,3.75,3.75,0,0,1,0,7.5Z" transform="translate(-15.13 -14.68)" />
    </g>
  </svg>
);

MyTimeIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default MyTimeIcon;
