/* eslint-disable no-console */

import React from 'react';
import { Overlay } from 'react-overlays';
import { InfiniteLoader } from 'react-virtualized';
import { withTranslation } from 'react-i18next';

import {
  getAssociatedUsersAndOrgs,
  updateSession,
  ssoRedirect,
  smarSessionSignIn,
  buildPayload,
  getMultiUserErrorPage,
} from '../../../client/utils/api';
import SelectList from '../../../client/components/SelectList';
import OverlayPopover from '../../../client/components/OverlayPopover';
import ArrowIcon from '../../../client/components/Icon/components/ArrowIcon';
import Avatar from '../../../client/components/Avatar';
import usersByOrgs from './helpers';
import style from './style.scss';
import { beginAuthenticatedPingLoop } from '../../../../../../lib/authenticatedPing/src/index';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.toggleFlyout = this.toggleFlyout.bind(this);
    this.fetchAssociatedUsersAndOrgs =
      this.fetchAssociatedUsersAndOrgs.bind(this);
    this.updateUserSession = this.updateUserSession.bind(this);
    this.redirectToNewUser = this.redirectToNewUser.bind(this);
    this.getSelectListItems = this.getSelectListItems.bind(this);
    this.state = {
      ssoEnabled: false,
      smarEnabled: false,
      accountList: [],
      listOpen: false,
      pagenumber: 1,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchAssociatedUsersAndOrgs();
  }

  getSelectListItems(list) {
    const listItems = [];
    list.forEach((org) => {
      listItems.push({
        type: 'custom',
        // eslint-disable-next-line
        item: (
          <div className={style.optionLabel}>
            <div className={style.organizationName}>
              {org[0].organization_name}
            </div>
          </div>
        ),
      });
      org.forEach((user) => {
        const currentAccountSso =
          this.state.ssoEnabled &&
          user.organization_id === window.whoami.organization_id;
        const currentAccountLogin = user.id === window.whoami.id;
        const row = { type: 'custom', item: null };
        row.item = (
          <button
            className={
              currentAccountSso || currentAccountLogin
                ? style.optionCurrent
                : style.option
            }
            onClick={() => this.redirectToNewUser(user)}
          >
            <div className={style.thumbnail}>
              {
                <Avatar
                  user={{
                    first_name: user.first_name,
                    thumbnail: user.thumbnail,
                  }}
                  size='ExtraSmall'
                />
              }
            </div>
            <div className={style.userName}>
              {`${user.first_name} ${user.last_name}`}
            </div>
          </button>
        );
        listItems.push(row);
      });
      listItems.push({
        type: 'custom',
        item: <div />,
        height: 10,
      });
    });
    return listItems;
  }

  redirectToNewUser(user) {
    if (this.state.ssoEnabled) {
      this.ssoRedirect(user.organization_guid);
    } else if (this.state.smarEnabled) {
      this.smarSessionSignIn(user.id, user.organization_id);
    } else {
      this.updateUserSession(user.id);
    }
  }

  fetchAssociatedUsersAndOrgs() {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    fetch(getAssociatedUsersAndOrgs(window.whoami.id))
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          accountList: data.data,
          ssoEnabled: data.sso_enabled,
          smarEnabled: data.smar_enabled,
          pagenumber: this.state.pagenumber + 1,
          isLoading: false,
        });

        if (data.smar_enabled) {
          beginAuthenticatedPingLoop();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  toggleFlyout() {
    this.setState({ listOpen: !this.state.listOpen });
  }

  // eslint-disable-next-line class-methods-use-this
  updateUserSession(userId) {
    fetch(
      updateSession(userId),
      buildPayload({
        method: 'PUT',
        payload: { user_id: userId },
      })
    )
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
      })
      .then(() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      })
      .catch((error) => console.error(error));
  }

  // eslint-disable-next-line class-methods-use-this
  ssoRedirect(guid) {
    window.location.assign(ssoRedirect(guid));
  }

  // eslint-disable-next-line class-methods-use-this
  smarSessionSignIn(multiUserId, orgId) {
    const MULTI_USER_ERROR = 'multi user error';
    fetch(
      smarSessionSignIn(multiUserId, orgId),
      buildPayload({
        method: 'POST',
        payload: { multiuserid: multiUserId, organization_id: orgId },
      })
    )
      .then((res) => {
        if (!res.ok) {
          if (res.status === 422) {
            throw new Error(MULTI_USER_ERROR);
          } else {
            throw new Error(res.statusText);
          }
        }
      })
      .then(() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === MULTI_USER_ERROR) {
          this.redirectToMultiUserError();
        } else {
          console.error(error);
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  redirectToMultiUserError() {
    window.location.assign(getMultiUserErrorPage());
  }

  render() {
    const { t } = this.props;
    const accountList = usersByOrgs(this.state.accountList);
    const listItems = this.getSelectListItems(accountList);

    const listHeight = () => {
      const rows = listItems.length;
      const height = rows * 30;
      return height;
    };
    const computedListHeight = listHeight();
    const dropdownOverlayPopoverStyle = {
      marginTop: 10,
      marginLeft: 12,
      padding: 0,
      height: computedListHeight > 335 ? 388 : computedListHeight + 53,
      width: 250,
    };

    const isRowLoaded = ({ index }) => listItems.length < index;

    const loadMoreRows = () => {
      const perPage = 20;
      if (this.state.isLoading) return Promise.resolve();
      this.setState({ isLoading: true });
      return fetch(
        getAssociatedUsersAndOrgs(
          window.whoami.id,
          perPage,
          this.state.pagenumber
        )
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            accountList: this.state.accountList.concat(data.data),
            pagenumber: this.state.pagenumber + 1,
            isLoading: false,
          });
        });
    };

    const accountSwitcherText = t('lbl_switch_to_another_account');

    return (
      <div>
        {this.state.accountList.length > 1 && (
          // eslint-disable-next-line no-return-assign
          <button
            onClick={this.toggleFlyout}
            ref={(c) => (this.caretBtn = c)}
            aria-label={t('lbl_account_switcher')}
            title=''
          >
            <ArrowIcon className={style.icon} />
          </button>
        )}
        <Overlay
          show={this.state.listOpen}
          onHide={() => this.toggleFlyout()}
          rootClose
          placement='bottom'
          target={this.caretBtn}
        >
          <OverlayPopover
            style={dropdownOverlayPopoverStyle}
            customArrowOffsetLeft='29px'
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className={style.fixedLabel}>{accountSwitcherText}</div>
              <div className={style.divider} />
              <div className={style.listWrapper}>
                <InfiniteLoader
                  isRowLoaded={isRowLoaded}
                  loadMoreRows={loadMoreRows}
                  rowCount={this.state.accountList.length}
                >
                  {({ onRowsRendered }) => (
                    <SelectList
                      list={listItems}
                      onSelect={() => {}}
                      onRowsRendered={onRowsRendered}
                    />
                  )}
                </InfiniteLoader>
              </div>
            </div>
          </OverlayPopover>
        </Overlay>
      </div>
    );
  }
}

export { App };
export default withTranslation()(App);
