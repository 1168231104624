declare global {
  interface Window {
    isIframeView: boolean;
    clientState?: {
      sessionKey: string;
    };
  }
}

export enum ModalEvent {
  Opened = 'RM:modal-opened',
  Closed = 'RM:modal-closed',
  OverlayClick = 'RM:overlay-click',
};

export const getCsrfToken = () => {
  const csrfTokenTag = document.getElementsByName('csrf-token')[0] as HTMLMetaElement;
  return csrfTokenTag?.content;
};

export const getXsrfToken = (): string | undefined => {
  if (window.isIframeView) {
    return window.clientState?.sessionKey;
  }
}

export const sendModalEvent = (type: ModalEvent, opacity?: string) => {
  if (window.isIframeView) {
    window.parent.postMessage({ type, opacity }, location.origin);
  }
}
