import React from 'react';
import PropTypes from 'prop-types';

export const NoteIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <path d="M14,4H5V20H19V10Zm2.4,6H14V7.12Zm.6,8H7V6h5v6h5Z" fill="gray" />
  </svg>
);

NoteIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default NoteIcon;
