import keys from 'lodash/keys';

export interface SearchResultsMinimal {
  id: number;
  uid: string;
  name: string;
}

/**
 * A similar interface, SearchChangeOpts, exists in the Components package. This is to decrease the number of dependencies the API package relies on.
 * @param searchString - A string that reflects a potential name for a record being queried.
 * @param modelName - A string referencing a server-side model
 * @param jsonKey - A string referencing an enum type for models such as Tags
 * @param id - An optional param to narrow down a specific record e.g. custom-tags
 */
export type GetSearchResultsParams = {
  searchString: string;
  modelName: string;
  jsonKey: string;
  id?: number | string;
};

export type SearchResultsResponseBody = {
  data: SearchResultsMinimal[];
};

/**
 * Borrowed from legacy client with minor modifications.
 *
 * @param model - String references the class name to query
 * @returns a sub-string mapped to the correct server-side endpoint
 */
export const getSearchPathFor = (model: string): string => {
  const mapping: { [key: string]: string } = {
    categories: 'budget_items/categories',
    values: 'custom_field_values',
    client: 'tags',
    approvees: 'relationships/approvees',
    'project owners': 'users',
    assignable: 'assignables',
  };
  return mapping[model] ? `${mapping[model]}/search` : `${model}/search`;
};

/**
 * Borrowed from legacy client with minor modifications.
 *
 * @param jsonKey - enum type for specific class columns
 * @param id - an optional row id to query
 * @returns a URL encoded sub-string to concat for server-side endpoint for more complex queries
 */
export const getExtraParams = (
  jsonKey: string,
  id?: number | string
): string => {
  if (id !== undefined) {
    const mapping: { [key: string]: string } = {
      custom_field: `&custom_field_id=${id}`,
      project_only: `&with_phases=true&project_id=${id}`,
    };
    if (keys(mapping).includes(jsonKey)) return mapping[jsonKey];
  }
  const mapping: { [key: string]: string } = {
    approver_id: '&with_unmanaged_resources=false',
    owner_id: '&with_unmanaged_resources=false',
    project_id: '&with_archived=true',
    user_id: '&include_placeholders=true&with_archived=true',
    client: '&namespace=client',
    discipline: '&namespace=discipline',
    role: '&namespace=resource%20type',
    location: '&namespace=location',
    project_tags: '&namespace=assignables',
    people_tags: '&namespace=users',
    phase_name: '&only_phases=true&with_archived=true',
    placeholders: '&include_placeholders=true',
    users: '&include_placeholders=true',
    potential_owners: '&potential_owners=true',
    with_role: '&with_unmanaged_resources=false&with_role=true',
    with_discipline: '&with_unmanaged_resources=false&with_discipline=true',
  };
  return mapping[jsonKey] || '';
};

export const getSearchResults = async (params: GetSearchResultsParams) => {
  const { modelName, searchString, jsonKey, id } = params;
  const searchPath = getSearchPathFor(modelName);
  const extraParams = getExtraParams(jsonKey, id);
  const searchURL = `api/v1/${searchPath}?query=${searchString}${extraParams}&minimal_response=true`;
  const resp = await window.fetch(searchURL);
  return resp.json() as Promise<SearchResultsResponseBody>;
};
