import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import style from '../stylesheets/allOrNone.scss';

const AllOrNone = ({ onAll, onNone }) => {
  const { t } = useTranslation();
  return (
    <div className={style.wrap}>
      <button id='filterAllButton' className={style.button} onClick={onAll}>
        {t('lbl_all')}
      </button>
      <div aria-hidden='true' className={style.sep} />
      <button className={style.button} onClick={onNone}>
        {t('lbl_none')}
      </button>
    </div>
  );
};

AllOrNone.propTypes = {
  onAll: PropTypes.func.isRequired,
  onNone: PropTypes.func.isRequired,
};

export default AllOrNone;
