import React from 'react';
import PropTypes from 'prop-types';

export const WorkingFromHomeIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <rect width="24" height="24" fill="#24a2eb" />
    <polygon points="19.05 9.55 19.05 3.82 15.77 3.82 15.77 6.27 12.5 3 3.5 12 5.14 12 5.14 21 10.05 21 10.05 13.64 14.95 13.64 14.95 21 19.86 21 19.86 12 21.5 12 19.05 9.55" fill="#fff" />
  </svg>
);

WorkingFromHomeIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default WorkingFromHomeIcon;
