/* eslint-disable @typescript-eslint/no-non-null-assertion */
import snakeCase from 'lodash/snakeCase';

export const serializeParams = (
  params: Record<string, string | number | boolean | undefined>
) =>
  Object.keys(params)
    .filter((p) => params[p] !== undefined) // let's be explicit and keep 0
    .map((p) => {
      return `${snakeCase(p)}=${encodeURIComponent(params[p].toString())}`;
    })
    .join('&');
