import React from 'react';
import PropTypes from 'prop-types';

export const InTheOfficeIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <rect width="24" height="24" fill="#24a2eb" />
    <path d="M18,2H3V22H6V16h4v6H21V2Zm0,2V8H14V4Zm0,6v4H14V10ZM10,4V8H6V4ZM6,14V10h4v4Zm8,6V16h4v4Z" fill="#fff" />
  </svg>
);

InTheOfficeIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default InTheOfficeIcon;
