import React from 'react';

const ResourceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      // eslint-disable-next-line max-len
      d="M18.4,15.32a9.62,9.62,0,0,0-3.19-.83h0a4.94,4.94,0,0,1-3.15,1.28h-.12A5,5,0,0,1,8.79,14.5c-1,.17-2.7.4-3.18.83C5,15.92,4,19,4,19H20S19.06,15.91,18.4,15.32Z"
    />
    <path
      // eslint-disable-next-line max-len
      d="M9.89,12.65v0A2.94,2.94,0,0,0,12,13.79a3,3,0,0,0,2.13-1.17h0a3.75,3.75,0,0,0,.43-.64C15.31,10.45,17,4.28,12,4.21S8.67,10.45,9.43,12A2.87,2.87,0,0,0,9.89,12.65Z"
    />
  </svg>
);

export default ResourceIcon;
