import PropTypes from 'prop-types';
import React from 'react';

const ListIcon = ({ cssClass }) => (
  <svg focusable="false" className={cssClass} width="28px" height="17px" viewBox="0 0 28 17">
    <svg focusable="false">
      <rect x="0" y="0" width="20" height="2" />
      <rect x="0" y="3" width="20" height="2" />
      <rect x="0" y="6" width="20" height="2" />
      <rect x="0" y="9" width="20" height="2" />
      <rect x="0" y="12" width="20" height="2" />
      <rect x="0" y="15" width="20" height="2" />
    </svg>
    <svg focusable="false">
      <rect x="23" y="0" width="5" height="2" />
      <rect x="23" y="3" width="5" height="2" />
      <rect x="23" y="6" width="5" height="2" />
      <rect x="23" y="9" width="5" height="2" />
      <rect x="23" y="12" width="5" height="2" />
      <rect x="23" y="15" width="5" height="2" />
    </svg>
  </svg>
);

ListIcon.propTypes = {
  cssClass: PropTypes.string,
};

export default ListIcon;
