import React from 'react';

const copyStyles = {
  fill: 'none',
  width: '24px',
  height: '24px',
  borderRadius: '3px',
  transition: 'all 0.3s',
  cursor: 'pointer',
  stroke: '#808080',
  marginTop: '4px',
  marginLeft: '4px',
};

export const CopyIcon = () => (
  <div style={copyStyles}>
    <svg>
      <rect x="4" y="3" width="10" height="8" />
      <polyline points="4,5 2,5 2,13 12,13 12,11" />
    </svg>
  </div>
);

export default CopyIcon;
