import React, { Component } from 'react';
import PropTypes from 'prop-types';

import style from './style.scss';

import getGuid from '../../../../utils/getGuid';

// TODO refactor component into function component that uses ref forwarding?
// NOTE disabling because this component is used with refs
// eslint-disable-next-line react/prefer-stateless-function
class BaseButton extends Component {
  render() {
    const {
      type,
      children,
      className: propsClassName,
      onClick,
      disabled,
      btnId,
      btnRef,
      title,
      'aria-label': ariaLabel,
      customStyle,
    } = this.props;

    const className = type ? style[type] : style.button;
    const id = btnId || `button${getGuid()}`;

    return (
      <button
        aria-label={ariaLabel}
        ref={btnRef}
        id={id}
        className={className + (propsClassName ? ` ${propsClassName}` : '')}
        onClick={onClick}
        {...(disabled ? { disabled } : {})}
        {...(this.props['data-client-id']
          ? { 'data-client-id': this.props['data-client-id'] }
          : {})}
        title={title}
        style={customStyle}
      >
        {children}
      </button>
    );
  }
}

BaseButton.propTypes = {
  type: PropTypes.oneOf([
    'default',
    'next',
    'cancel',
    'danger',
    'success',
    'transparent',
    'link',
    'ghostDisabled',
    'ghostDisabledDestructive',
    'ghostDisabledPrimary',
    'unadorned',
    'unadornedCurrent',
    'load',
    'queue',
    'upload',
    'contactSupportButton',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  btnId: PropTypes.string,
  'data-client-id': PropTypes.string,
  title: PropTypes.string,
  'aria-label': PropTypes.string,
};

export default BaseButton;
