import React from 'react';

const ReportIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
    <rect fill='#ffffff' x='6' y='5' width='12' height='14' />
    <rect fill='#24a2eb' x='7' y='6' width='10' height='2' />
    <rect fill='#24a2eb' x='7' y='11' width='5' height='2' />
    <rect fill='#24a2eb' x='7' y='16' width='7' height='2' />
    <path fill='#d8d8d8' d='M18,5V19H6V5H18m1-1H5V20H19V4Z' />
    <rect fill='#d8d8d8' x='5' y='9' width='14' height='1' />
    <rect fill='#d8d8d8' x='5' y='14' width='14' height='1' />
  </svg>
);

export default ReportIcon;
