import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import style from './style.scss';

const ButtonGroup = ({
  onCancel,
  onCommit,
  commitButtonText,
  disableCommit,
  cancelButtonText,
  commitStyleDanger,
  btnGrpId,
  vertical,
  cancelRef,
  commitRef,
}) => {
  const saveButtonClass = commitStyleDanger ? style.danger : style.commit;
  const id = btnGrpId || 'button-group';
  const { t } = useTranslation();
  const classNames = [style.container];
  if (vertical) classNames.push(style.vertical);

  return (
    <div id={id} className={classNames.join(' ')}>
      {onCancel && (
        <button ref={cancelRef} className={style.cancel} onClick={onCancel}>
          {cancelButtonText || t('lbl_cancel')}
        </button>
      )}
      {onCommit && (
        <button
          ref={commitRef}
          className={saveButtonClass}
          onClick={onCommit}
          disabled={disableCommit}
        >
          {commitButtonText}
        </button>
      )}
    </div>
  );
};

ButtonGroup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCommit: PropTypes.func,
  commitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disableCommit: PropTypes.bool,
  commitStyleDanger: PropTypes.bool,
  btnGrpId: PropTypes.string,
  vertical: PropTypes.bool,
};

export default ButtonGroup;
