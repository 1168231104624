import React from 'react';
import PropTypes from 'prop-types';

const ArrowIcon = ({ className }) => (
  <svg viewBox="0 0 12 12" className={className}>
    <polygon points="6 9 12 3 0 3 6 9" />
  </svg>
);

ArrowIcon.propTypes = {
  className: PropTypes.string,
};

export default ArrowIcon;
