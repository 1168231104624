import React from 'react';
import PropTypes from 'prop-types';

export const OutOfOfficeIcon = ({ iconStyle }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={iconStyle}>
    <rect width="24" height="24" fill="#ff7e00" />
    <path d="M18,2H3V13L7,9v1h3v3h3v6H10v3H21V2ZM10,8H6V4h4Zm8,12H14V16h4Zm0-6H14V10h4Zm0-6H14V4h4Z" fill="#fff" />
    <polygon points="1 16 7 22 7 18 12 18 12 14 7 14 7 10 1 16" fill="#fff" />
  </svg>
);

OutOfOfficeIcon.propTypes = {
  iconStyle: PropTypes.string,
};

export default OutOfOfficeIcon;
