import React from 'react';

export const RejectIcon = () => (
  <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L9.99951 9.99973" stroke="#CC2E3B" strokeWidth="3" />
    <path d="M2.00109 9.99975L10.0006 2.00001" stroke="#CC2E3B" strokeWidth="3" />
  </svg>
);

export default RejectIcon;
