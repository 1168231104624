import React from 'react';

const style = { fill: '#003059' };

export const SmartsheetIcon = () => (
  <svg width="10" height="13" viewBox="0 0 10 13">
    <path
      d="M408.133,45.417a1.856,1.856,0,0,1,1.208-.75c1.042,0,1.917,1.875,2.042,2.125A19.972,19.972,0,0,1,416.05,40.5h-9.625V51.292A5.845,5.845,0,0,1,406.3,52.5a19.165,19.165,0,0,0,3.667-.917,12.135,12.135,0,0,0,4.083-2.167l.083-.083.042.083a7.537,7.537,0,0,0,.708,1.25c.417.458.625.542.75.5.167-.042.292-.292.292-.75.042-.417.083-1.417.083-2.625V41.375a40.8,40.8,0,0,0-4.833,8.708S409.758,45.375,408.133,45.417Z"
      transform="translate(-406.3 -40.5)"
      style={style}
    />
  </svg>
);

export default SmartsheetIcon;
