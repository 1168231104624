import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import style from './style.scss';

import { handleImageError } from '../../utils/handleImageError';

const ANON_IMAGE_PATH = `${window.STATIC_IMAGE_URL}/anon.jpg`;

const Avatar = ({ user, size = 'Small', actsAsLink, round = false }) => {
  const displayName = user.display_name || user.first_name;
  const thumbnail = user.thumbnail || ANON_IMAGE_PATH;

  let className = style[`avatar${size}`];
  if (round) {
    className += ` ${style.round}`;
  }

  const markup = (
    <div className={className} title={displayName}>
      <img
        className={style.image}
        src={thumbnail}
        alt={displayName}
        onError={(event) => handleImageError(event, ANON_IMAGE_PATH)}
      />
    </div>
  );
  if (actsAsLink) {
    return <Link to={`/users/${user.id}`}>{markup}</Link>;
  }
  return markup;
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['ExtraSmall', 'MediumSmall', 'Small', 'Medium']),
  user: PropTypes.shape({
    first_name: PropTypes.string,
    display_name: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
  actsAsLink: PropTypes.bool,
  round: PropTypes.bool,
};

export default Avatar;
