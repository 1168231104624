import React from 'react';

const DropdownIcon = () => (
  <svg height="45px" width="275px" viewBox="0 0 275 45">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#f9f9f9" />
        <stop offset="1" stopColor="#e2e2e2" />
      </linearGradient>
    </defs>
    <g transform="translate(-117 -188)">
      <g transform="translate(117 188)" stroke="#e4e4e4" strokeWidth="1" fill="url(#linear-gradient)">
        <rect width="268" height="30" rx="2" stroke="none" />
        <rect x="0.5" y="0.5" width="267" height="29" rx="1.5" fill="none" />
      </g>
      <path d="M3810.226,8995.242h8l-4.059,4Z" transform="translate(-3444.227 -8795.242)" fill="#1c1c1c" />
    </g>
  </svg>
);

export default DropdownIcon;
